<template>
  <div class="aigc-main">
    <navpage class="nav"></navpage>

    <div class="banner-page">
      <div class="image">
        <img
          :src="require('./../assets/xyfwImg/bannerImage2.png')"
          class="image1"
          alt="Image Asset 3"
        />
      </div>
    </div>

    <div class="kecheng-page">

      <div class="kecheng-content">
        <div class="kecheng0-page">
          <div class="wrap">
            <div class="container">
              <div class="content"></div>
              <div class="content1"></div>
              <div class="content2">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image7.png')"
                  class="image"
                  alt="Image Asset 7"
                />
                <p class="text">双模式课堂</p>
                <p class="text1">直播+录播课程模式</p>
              </div>
            </div>
            <div class="container1">
              <div class="content3"></div>
              <div class="content4"></div>
              <div class="content5">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image14.png')"
                  class="image1"
                  alt="Image Asset 14"
                />
                <p class="text2">线上自习室</p>
                <p class="text3">腾讯会议线上自习室</p>
              </div>
            </div>
            <div class="container2">
              <div class="content6"></div>
              <div class="content7"></div>
              <div class="content8">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image21.png')"
                  class="image2"
                  alt="Image Asset 21"
                />
                <p class="text4">线下自习室</p>
                <p class="text5">近屿智能上海总部提供线下自习室</p>
              </div>
            </div>
            <div class="container3">
              <div class="content9"></div>
              <div class="content10"></div>
              <div class="content11">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image28.png')"
                  class="image3"
                  alt="Image Asset 28"
                />
                <p class="text6">实战检测机制</p>
                <p class="text7">每个学习阶段都匹配对应实战作业</p>
              </div>
            </div>
          </div>
          <div class="wrap1">
            <div class="container4">
              <div class="content12"></div>
              <div class="content13"></div>
              <div class="content14">
                <div class="main">
                  <img
                    :src="require('./../assets/xyfwImg/kc0-Image37.png')"
                    class="image4"
                    alt="Image Asset 37"
                  />
                </div>
                <p class="text8">全程伴学服务</p>
                <p class="text9">从入学到学籍期结束提供全程学习辅助及答疑服务</p>
              </div>
            </div>
            <div class="container5">
              <div class="content15"></div>
              <div class="content16"></div>
              <div class="content17">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image44.png')"
                  class="image5"
                  alt="Image Asset 44"
                />
                <p class="text10">专业技术学管<br/>多对一技术答疑</p>
                <p class="text11">
                  多对一专属学习群，专属学管技术答疑帮助，针对性答疑更有效
                </p>
              </div>
            </div>
            <div class="container6">
              <div class="content18"></div>
              <div class="content19"></div>
              <div class="content20">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image51.png')"
                  class="image6"
                  alt="Image Asset 51"
                />
                <p class="text12">多样化积分奖励机制</p>
                <p class="text13">通过学习和积极性行为获取积分，学习激励更有效</p>
              </div>
            </div>
            <div class="container7">
              <div class="content21"></div>
              <div class="content22"></div>
              <div class="content23">
                <img
                  :src="require('./../assets/xyfwImg/kc0-Image58.png')"
                  class="image7"
                  alt="Image Asset 58"
                />
                <p class="text14">就业面试保障</p>
                <p class="text15">
                  帮助学员拿到至少5次岗位面试机会，全程匹配简历优化及面试指导服务，从此就业不再难
                </p>
              </div>
            </div>
          </div>

        </div>
        <div class="kecheng1-page">
          <p class="text">学习<span class="blue">效果检测</span>体系</p>
          <div class="wrap">
            <div class="container">
              <div class="content">
                <div class="main">
                  <p class="text1">课后作业检测</p>
                  <p class="text2">检验特定板块学习情况</p>
                </div>
                <img
                  :src="require('./../assets/xyfwImg/kc1-Image9.png')"
                  class="image"
                  alt="Image Asset 9"
                />
              </div>
              <div class="content1">
                <img
                  :src="require('./../assets/xyfwImg/kc1-Image11.png')"
                  class="image1"
                  alt="Image Asset 11"
                />
                <p class="text3">实战作业检测</p>
                <p class="text4">通过实战案例作业全面检测<br />阶段学习掌握情况</p>
              </div>
            </div>
            <img
              :src="require('./../assets/xyfwImg/kc1-Image14.png')"
              class="image2"
              alt="Image Asset 14"
            />
          </div>
        </div>
        <div class="kecheng2-page">
          <p class="text"><span class="blue">督导</span>学习</p>
          <div class="wrap">
            <div class="container" @mouseover="subSection = 1" :style="subSection == 1?'flex:2':'flex:1'">
              <div class="image">
                
              </div>
              <div class="content">
                <p class="text1">直播课督导</p>
                <p class="text2" v-show="subSection == 1">计划排期教学，潜在督学，让学习更有计划性</p>
              </div>
            </div>
            <div class="container1" @mouseover="subSection = 2" :style="subSection == 2?'flex:2':'flex:1'">
              <div class="image1">
                <div class="main">
                  <p class="text3">学管督导</p>
                  <p class="text4" v-show="subSection == 2">1V1学管跟进学习进展，及时督促学员学习并完成作业</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="kecheng3-page">
          <p class="text"><span class="blue">就业</span>指导</p>
          <div class="wrap">
            <div class="container">
              <div class="content">
                <p class="text1">AI模拟面试：</p>
                <p class="text2">算法面试指导：</p>
              </div>
              <div class="content1">
                <img
                  :src="require('./../assets/xyfwImg/kc3-Image9.png')"
                  class="image"
                  alt="Image Asset 9"
                />
                <p class="text3">简历与面试准备</p>
              </div>
              <div class="content2">
                <p class="text4">简历检测与修改：</p>
                <p class="text5">
                  针对学员初始版简历，指导并辅助学员优化简历并制作成品。
                </p>
              </div>
              <p class="text6">
                提供多次AI模拟面试，丰富学员面试经验并提升面试表达能力。
              </p>
              <p class="text7">课程提供2次算法面试指导直播课程。</p>
            </div>
            <div class="container1">
              <div class="content3"><p class="text8">制定个性化就业方案：</p></div>
              <div class="content4">
                <img
                  :src="require('./../assets/xyfwImg/kc3-Image20.png')"
                  class="image1"
                  alt="Image Asset 20"
                />
                <p class="text9">就业准备阶段</p>
              </div>
              <div class="content5">
                <p class="text10">确认学员基本信息：</p>
                <p class="text11">了解学员的基础信息及就业意向和<br />具体要求。</p>
              </div>
              <p class="text12">针对学员个性需求，为学员规划求职<br />方向。</p>
            </div>
            <div class="container2">
              <div class="content6">
                <p class="text13">面试指导：</p>
                <p class="text14">面试技巧：</p>
                <p class="text15">面试复盘：</p>
              </div>
              <div class="content7">
                <img
                  :src="require('./../assets/xyfwImg/kc3-Image32.png')"
                  class="image2"
                  alt="Image Asset 32"
                />
                <p class="text16">面试能力提升</p>
              </div>
              <p class="text17">
                可针对需面试岗位提供一对一视频面试指导，包括技术能力沟通反馈。
              </p>
              <p class="text18">提供面试礼仪与人事沟通技巧。</p>
              <p class="text19">
                分析面试表现，并将在下一次面试指导中老师针对性给学员进行提升优化。
              </p>
            </div>
            <div class="container3">
              <div class="content8"><p class="text20">面试题共享学习：</p></div>
              <div class="content9">
                <img
                  :src="require('./../assets/xyfwImg/kc3-Image41.png')"
                  class="image3"
                  alt="Image Asset 41"
                />
                <p class="text21">先行者面试题共享</p>
              </div>
              <p class="text22">提供学长学姐整理的相关真实面试题<br />与资料。</p>
            </div>
            <img
              :src="require('./../assets/xyfwImg/kc3-Image44.png')"
              class="image4"
              alt="Image Asset 44"
            /><img
              :src="require('./../assets/xyfwImg/kc3-Image45.png')"
              class="image5"
              alt="Image Asset 45"
            /><img
              :src="require('./../assets/xyfwImg/kc3-Image46.png')"
              class="image6"
              alt="Image Asset 46"
            />
          </div>


        </div>
        <div class="kecheng4-page">
          <p class="text28">OJAC标准会员<span class="blue">权益</span></p>
          <p class="text29">未来更多精彩技术讲座，敬请期待。</p>
          <div class="wrap">
            <div class="container">
              <div class="content">
                <img
                  :src="require('./../assets/xyfwImg/kc4-Image7.png')"
                  class="image"
                  alt="Image Asset 7"
                />
                <div class="image1"><p class="text">工作机会信息速递</p></div>
              </div>
              <div class="content1">
                <img
                  :src="require('./../assets/xyfwImg/kc4-Image11.png')"
                  class="image2"
                  alt="Image Asset 11"
                />
                <div class="image3"><p class="text1">每月前沿技术更新讲座</p></div>
              </div>
            </div>
            <div class="container1">
              <div class="content2">
                <p class="text2">每月前沿技术更新讲座</p>
                <p class="text3">
                  系列讲座每月为您呈现一场关于AI和前沿技术的深度探讨。我们精心邀请了一流的科技领袖、业界翘楚以及学术界的杰出思想家，他们将带来最新的研究进展、行业动态、技术革新以及实践案例。
                </p>
              </div>
              <div class="content3">
                <div class="main">
                  <div class="section">
                    <div class="subSection">
                      <p class="text2">Deepseek&nbsp;R1：开启智能新时代的钥匙</p>
                    </div>
                    <div class="subSection1">
                      <p class="text3">显卡革命：驱动AI未来的图形处理单元</p>
                    </div>
                    <div class="subSection2">
                      <p class="text4">12&nbsp;Days&nbsp;of&nbsp;OpenAI</p>
                    </div>
                    <div class="subSection3">
                      <p class="text5">构建可控与一致的未来：AI驱动的视频生成技术</p>
                    </div>
                  </div>
                </div>
                <div class="main1">
                  <div class="section1">
                    <div class="subSection4">
                      <p class="text6">诺贝尔奖的光环下：AI与物理学的奇妙交织</p>
                    </div>
                    <div class="subSection5">
                      <p class="text7">智能无界：o1大模型中的推理与思考</p>
                    </div>
                    <div class="subSection6">
                      <p class="text8">
                        Voice&nbsp;Agent&nbsp;-&nbsp;人机交互新时代涌现
                      </p>
                    </div>
                    <div class="subSection7">
                      <p class="text9">告别幻觉：Graph&nbsp;Rag引领LLM新时代</p>
                    </div>
                  </div>
                </div>
                <div class="main2">
                  <div class="section2">
                    <div class="subSection8">
                      <p class="text10">Stable&nbsp;Diffusion&nbsp;3</p>
                    </div>
                    <div class="subSection9">
                      <p class="text11">全能模型GPT-4o</p>
                    </div>
                    <div class="subSection10">
                      <p class="text12">深度学习的最新进展</p>
                    </div>
                    <div class="subSection11">
                      <p class="text13">人工智能与创意产业</p>
                    </div>
                  </div>
                </div>
                <div class="main3">
                  <div class="section3">
                    <div class="subSection12">
                      <p class="text14">AI在健康医疗领域的应用</p>
                    </div>
                    <div class="subSection13">
                      <p class="text15">人机协作的未来工作场景</p>
                    </div>
                    <div class="subSection14">
                      <p class="text16">机器人自主导航的突破</p>
                    </div>
                    <div class="subSection15">
                      <p class="text17">AI在智慧城市建设中的应用</p>
                    </div>
                  </div>
                </div>
                <div class="main4">
                  <div class="section4">
                    <div class="subSection16">
                      <p class="text18">量子计算与AI的交叉前景</p>
                    </div>
                    <div class="subSection17">
                      <p class="text19">机器学习在网络安全中的应用</p>
                    </div>
                    <div class="subSection18">
                      <p class="text20">自然语言处理的新时代</p>
                    </div>
                    <div class="subSection19">
                      <p class="text21">人工智能在教育领域的创新</p>
                    </div>
                  </div>
                </div>
                <div class="main5">
                  <div class="section5">
                    <div class="subSection20">
                      <p class="text22">人工智能在环境保护中的角色</p>
                    </div>
                    <div class="subSection21">
                      <p class="text23">AI在航空航天的应用</p>
                    </div>
                    <div class="subSection22">
                      <p class="text24">虚拟助手的未来发展趋势</p>
                    </div>
                    <div class="subSection23">
                      <p class="text25">边缘计算在AI中的作用</p>
                    </div>
                  </div>
                </div>
                <div class="main6">
                  <div class="section6">
                    <div class="subSection24">
                      <p class="text26">大数据在AI训练中的应用</p>
                    </div>
                    <div class="subSection25">
                      <p class="text27">AI驱动的个性化消费体验</p>
                    </div>
                    <div class="subSection26">
                      <p class="text28">增强现实与AI的融合创新</p>
                    </div>
                    <div class="subSection27">
                      <p class="text29">人工智能在农业领域的应用</p>
                    </div>
                  </div>
                </div>
                <div class="main7">
                  <div class="section7">
                    <div class="subSection28">
                      <p class="text30">AI在金融科技中的颠覆性影响</p>
                    </div>
                    <div class="subSection29">
                      <p class="text31">人工智能与可持续能源</p>
                    </div>
                    <div class="subSection30">
                      <p class="text32">无人驾驶汽车的技术挑战</p>
                    </div>
                    <div class="subSection31">
                      <p class="text33">智能制造与工业4.0</p>
                    </div>
                  </div>
                </div>
                <div class="main8">
                  <div class="section8">
                    <div class="subSection32">
                      <p class="text34">AI伦理：责任与规制</p>
                    </div>
                    <div class="subSection33">
                      <p class="text35">AI与新市场：机遇与挑战</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import navpage from './../components/nav.vue'

export default {
  components: { navpage },
  data() {
    return {
      subSection: 1,


    }
  }
}
</script>
<style lang="less" scoped>
.blue{
  color: #1F6AFB;
}
.aigc-main {
  min-width: 1280px;
  max-width: 100%;
  margin: auto;
  .banner-page {
    // width: 100%;
    // height: 560px;
    // padding-top: 80px;
    overflow: hidden;
    box-sizing: border-box;
    background: rgba(244,247,252,1);
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      max-width: 1920px;
      height: 480px;
      box-sizing: border-box;
      background-image: url('./../assets/xyfwImg/bannerImage.png');
      background-size: cover;
      background-repeat: no-repeat;
      .image1 {
        width: 252px;
        height: 59px;
        margin: 240px auto 0;
      }
      .text {
        margin: 45.96px auto;
        // margin-left: 606px;
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(51,51,51,1);
        font-weight: 500;
        white-space: pre-line;
      }
      .wrap {
        width: 179px;
        height: 48px;
        border-radius: 61px;
        box-sizing: border-box;
        background: rgba(31,106,251,1);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 50px auto 0;
        .image2 {
          width: 105.01px;
          height: 16.63px;
          margin-right: 8px;
        }
        .image3 {
          width: 16px;
          height: 16px;
          overflow: hidden;
          box-sizing: border-box;
        }
      }
    }
  }
  .kecheng-page{
    margin-top: -40px;
    margin-bottom: 60px;
		// height: 148px;
		border-radius: 16px;
		box-sizing: border-box;
		background: rgba(255,255,255,1);
    .kecheng-title {
      margin: 0 auto 20px;
      width: 1280px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 1280px;
      height: 148px;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;
      background: rgba(255,255,255,1);


      p {
        margin-left: 39.53px;
        margin-bottom: 39.26px;
        
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(0,0,0,1);
        line-height: 33.60px;
        font-weight: 500;
      }
    }
    .kecheng-content{
      display: flex;
      flex-direction: column;
      width: 100%;
      // background-image: url('./../assets/aigcImg/kechengBg1.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      >img{
        margin: 20px auto;
        width: 1280px;
      }
    }
    .kecheng0-page {
      // width: 1280px;
      height: 504px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .wrap {
        // width: 1280px;
        height: 242px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .container {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          position: relative;
          .content {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(25,116,245,1) 0%,rgba(50,155,246,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content1 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(25,116,245,0.25) 0%,rgba(50,155,246,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content2 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text1 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              white-space: nowrap;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
        .container1 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          margin-left: 20px;
          position: relative;
          .content3 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(33,180,230,1) 0%,rgba(77,217,239,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content4 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(33,180,230,0.25) 0%,rgba(77,217,239,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content5 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image1 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text2 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text3 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              white-space: nowrap;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
        .container2 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          margin-left: 20px;
          position: relative;
          .content6 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(38,217,138,1) 0%,rgba(59,230,174,1) 0.01%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content7 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(38,217,138,0.25) 0%,rgba(59,230,174,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content8 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image2 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text4 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text5 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              white-space: nowrap;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
        .container3 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          margin-left: 20px;
          position: relative;
          .content9 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(69,95,126,1) 0%,rgba(94,125,163,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content10 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(69,95,126,0.25) 0%,rgba(94,125,163,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content11 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image3 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text6 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text7 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              white-space: nowrap;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
      }
      .wrap1 {
        width: 1280px;
        height: 242px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .container4 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          position: relative;
          .content12 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(203,213,243,1) 0%,rgba(234,239,255,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content13 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(203,213,243,0.25) 0%,rgba(234,239,255,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content14 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .main {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .image4 {
                width: 24px;
                height: 20.38px;
              }
            }
            .text8 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text9 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
        .container5 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          margin-left: 20px;
          position: relative;
          .content15 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(253,221,167,1) 0%,rgba(247,235,213,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content16 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(253,221,167,0.25) 0%,rgba(247,235,213,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content17 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image5 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text10 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              color: rgba(0,0,0,1);
              font-weight: 500;
              white-space: pre-line;
            }
            .text11 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
        .container6 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          margin-left: 20px;
          position: relative;
          .content18 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(130,102,239,1) 0%,rgba(173,152,255,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content19 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(130,102,239,0.25) 0%,rgba(173,152,255,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content20 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image6 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text12 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text13 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
        .container7 {
          width: 305px;
          height: 242px;
          overflow: hidden;
          border-radius: 8px;
          box-sizing: border-box;
          background: rgba(255,255,255,1);
          margin-left: 20px;
          position: relative;
          .content21 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(90deg, rgba(246,116,119,1) 0%,rgba(255,158,160,1) 100%);
            min-height: 56px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .content22 {
            width: 305px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(246,116,119,0.25) 0%,rgba(255,158,160,0) 100%);
            min-height: 56px;
            position: absolute;
            top: 56px;
            left: 0px;
          }
          .content23 {
            width: 265px;
            height: 178px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.05);
            background: rgba(255,255,255,1);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 32px;
            left: 20px;
            .image7 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-bottom: 8px;
            }
            .text14 {
              margin-bottom: 8px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0,0,0,1);
              line-height: 32px;
              font-weight: 500;
            }
            .text15 {
              width: 225px;
              font-family: 'PingFang SC';
              font-size: 14px;
              text-align: center;
              color: rgba(102,102,102,1);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .kecheng1-page {
      // width: 1280px;
      // height: 410px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .text {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0,0,0,1);
        line-height: 50.40px;
        font-weight: 500;
        margin: 60px 0 40px;

      }
      .wrap {
        // width: 1280px;
        height: 320px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .container {
          width: 413px;
          height: 320px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .content {
            width: 413px;
            height: 150px;
            overflow: hidden;
            border-radius: 16px;
            box-sizing: border-box;
            background: linear-gradient(126deg, rgba(78,112,239,1) 39.64%,rgba(2,51,236,1) 100%);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .main {
              width: 144px;
              height: 70px;
              margin-top: 40px;
              margin-left: 40px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              .text1 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                color: rgba(255,255,255,1);
                line-height: 33.60px;
                font-weight: 500;
              }
              .text2 {
                opacity: 0.8500000238418579;
                font-family: 'PingFang SC';
                font-size: 14px;
                white-space: nowrap;
                color: rgba(255,255,255,1);
                line-height: 19.60px;
                font-weight: 400;
              }
            }
            .image {
              width: 153px;
              height: 122px;
              overflow: hidden;
              box-sizing: border-box;
              margin-top: 28px;
              margin-left: 42px;
            }
          }
          .content1 {
            width: 413px;
            height: 150px;
            overflow: hidden;
            border-radius: 16px;
            box-sizing: border-box;
            background: linear-gradient(126deg, rgba(253,161,147,1) 39.64%,rgba(255,83,57,1) 100%);
            position: relative;
            .image1 {
              width: 205px;
              height: 128px;
              box-sizing: border-box;
              position: absolute;
              top: 22px;
              left: 196px;
            }
            .text3 {
              position: absolute;
              top: 30px;
              left: 40px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(255,255,255,1);
              line-height: 33.60px;
              font-weight: 500;
            }
            .text4 {
              position: absolute;
              top: 80px;
              left: 40px;
              opacity: 0.8500000238418579;
              font-family: 'PingFang SC';
              font-size: 14px;
              color: rgba(255,255,255,1);
              font-weight: 400;
              white-space: pre-line;
            }
          }
        }
        .image2 {
          width: 847px;
          height: 320px;
          margin-left: 20px;
        }
      }
    }
    .kecheng2-page {
      // width: 1280px;
      // height: 490px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .text {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        line-height: 50.40px;
        font-weight: 500;
        margin: 80px 0 40px;
      }
      .wrap {
        width: 1280px;
        height: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .container {
          // width: 800px;
          height: 400px;
          flex: 2;
          // overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(135deg, rgba(78,112,239,1) 33.33%,rgba(2,51,236,1) 100%);
          position: relative;
          display: flex;
          align-items: center;
          .image {
            width: 100%;
            height: 335.87px;
            background-image: url('./../assets/xyfwImg/kc2-Image5.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
          .content {
            border-radius: 16px;
            width: 100%;
            height: 400px;
            box-sizing: border-box;
            background: linear-gradient(270deg, rgba(0,25,252,0.15) 0%,rgba(0,25,252,0.85) 100%);
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .text1 {
              margin-top: 249.52px;
              margin-left: 40px;
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(255,255,255,1);
              line-height: 33.60px;
              font-weight: 500;
            }
            .text2 {
              margin-top: 16px;
              margin-left: 40px;
              opacity: 0.8500000238418579;
              font-family: 'PingFang SC';
              font-size: 14px;
              white-space: nowrap;
              color: rgba(255,255,255,1);
              line-height: 19.60px;
              font-weight: 400;
            }
          }
        }
        .container1 {
          flex: 1;
          margin-left: 16px;
          overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(150deg, rgba(78,112,239,1) 19.900000000000002%,rgba(2,51,236,1) 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          .image1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 400px;
            background-image: url('./../assets/xyfwImg/kc2-Image101.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            .main {
              width: 100%;
              height: 399.52px;
              box-sizing: border-box;
              background: linear-gradient(270deg, rgba(60,23,221,0.15) 0%,rgba(60,23,221,0.85) 100%);
              .text3 {
                margin-top: 250px;
                margin-left: 40px;
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                color: rgba(255,255,255,1);
                line-height: 33.60px;
                font-weight: 500;
              }
              .text4 {
                margin-top: 16px;
                margin-left: 40px;
                margin-bottom: 80px;
                opacity: 0.8500000238418579;
                font-family: 'PingFang SC';
                font-size: 14px;
                white-space: nowrap;
                color: rgba(255,255,255,1);
                line-height: 19.60px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .kecheng3-page {
			width: 100%;
			// height: 546px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.text {
				font-family: 'PingFang SC';
				font-size: 36px;
				white-space: nowrap;
				text-align: center;
				line-height: 50.40px;
				font-weight: 500;
        margin: 80px 0 40px;

			}
			.wrap {
				width: 1280px;
				height: 456px;
				overflow: hidden;
				border-radius: 16px;
				box-sizing: border-box;
				background: rgba(255,255,255,1);
				position: relative;
				.container {
					width: 1280px;
					height: 114px;
					box-sizing: border-box;
					background: rgba(255,255,255,1);
					position: absolute;
					top: 114px;
					left: 0px;
					.content {
						width: 1280px;
						height: 57px;
						box-sizing: border-box;
						background: linear-gradient(180deg, rgba(203,240,248,1) 0%,rgba(255,255,255,0) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						.text1 {
							margin-top: 25px;
							margin-left: 640px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
						.text2 {
							margin-top: 25px;
							margin-left: 40px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
					}
					.content1 {
						width: 300px;
						height: 114px;
						box-sizing: border-box;
						background: linear-gradient(270deg, rgba(255,255,255,0) 0%,rgba(203,240,248,1) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						.image {
							width: 73.38px;
							height: 50.75px;
							position: absolute;
							top: 32.28px;
							left: 23.33px;
						}
						.text3 {
							position: absolute;
							top: 40px;
							left: 40px;
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							color: rgba(22,196,235,1);
							line-height: 33.60px;
							font-weight: 500;
						}
					}
					.content2 {
						position: absolute;
						top: 25px;
						left: 340px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: center;
						width: 260px;
						height: 64px;
						.text4 {
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
						.text5 {
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							color: rgba(102,102,102,1);
							line-height: 19.60px;
							font-weight: 400;
						}
					}
					.text6 {
						position: absolute;
						top: 49px;
						left: 640px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text7 {
						position: absolute;
						top: 49px;
						left: 940px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
				}
				.container1 {
					width: 1280px;
					height: 114px;
					overflow: hidden;
					border-radius: NaNpx;
					box-sizing: border-box;
					background: rgba(255,255,255,1);
					position: absolute;
					top: 0px;
					left: 0px;
					.content3 {
						width: 1280px;
						height: 57px;
						box-sizing: border-box;
						background: linear-gradient(180deg, rgba(230,225,250,1) 0%,rgba(255,255,255,0) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						display: flex;
						justify-content: flex-end;
						align-items: flex-end;
						.text8 {
							margin-bottom: 12px;
							margin-right: 380px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
					}
					.content4 {
						width: 300px;
						height: 114px;
						box-sizing: border-box;
						background: linear-gradient(270deg, rgba(255,255,255,0) 0%,rgba(230,225,250,1) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						.image1 {
							width: 61.32px;
							height: 50.75px;
							position: absolute;
							top: 32.28px;
							left: 23.33px;
						}
						.text9 {
							position: absolute;
							top: 40px;
							left: 40px;
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							color: rgba(129,98,255,1);
							line-height: 33.60px;
							font-weight: 500;
						}
					}
					.content5 {
						position: absolute;
						top: 25px;
						left: 340px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: center;
						width: 260px;
						height: 64px;
						.text10 {
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
						.text11 {
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							color: rgba(102,102,102,1);
							font-weight: 400;
							white-space: pre-line;
						}
					}
					.text12 {
						position: absolute;
						top: 49px;
						left: 640px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						color: rgba(102,102,102,1);
						font-weight: 400;
						white-space: pre-line;
					}
				}
				.container2 {
					width: 1280px;
					height: 114px;
					overflow: hidden;
					box-sizing: border-box;
					background: rgba(255,255,255,1);
					position: absolute;
					top: 228px;
					left: 0px;
					.content6 {
						width: 1280px;
						height: 57px;
						box-sizing: border-box;
						background: linear-gradient(180deg, rgba(210,228,255,1) 0%,rgba(255,255,255,0) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						.text13 {
							margin-top: 25px;
							margin-left: 340px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
						.text14 {
							margin-top: 25px;
							margin-left: 40px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
						.text15 {
							margin-top: 25px;
							margin-left: 40px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
					}
					.content7 {
						width: 300px;
						height: 114px;
						box-sizing: border-box;
						background: linear-gradient(90deg, rgba(210,228,255,1) 0%,rgba(255,255,255,0) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						.image2 {
							width: 74.09px;
							height: 50.75px;
							position: absolute;
							top: 32.28px;
							left: 23.33px;
						}
						.text16 {
							position: absolute;
							top: 40px;
							left: 40px;
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							color: rgba(51,130,255,1);
							line-height: 33.60px;
							font-weight: 500;
						}
					}
					.text17 {
						position: absolute;
						top: 49px;
						left: 340px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text18 {
						position: absolute;
						top: 49px;
						left: 640px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text19 {
						position: absolute;
						top: 49px;
						left: 940px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
				}
				.container3 {
					width: 1280px;
					height: 114px;
					overflow: hidden;
					border-radius: NaNpx;
					box-sizing: border-box;
					background: rgba(255,255,255,1);
					position: absolute;
					top: 342px;
					left: 0px;
					.content8 {
						width: 1280px;
						height: 57px;
						box-sizing: border-box;
						background: linear-gradient(180deg, rgba(250,236,225,1) 0%,rgba(255,255,255,0) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-end;
						.text20 {
							margin-left: 340px;
							margin-bottom: 12px;
							width: 260px;
							opacity: 0.8500000238418579;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(0,0,0,1);
							line-height: 19.60px;
							font-weight: 500;
						}
					}
					.content9 {
						width: 300px;
						height: 114px;
						box-sizing: border-box;
						background: linear-gradient(90deg, rgba(250,236,225,1) 0%,rgba(255,255,255,0) 100%);
						position: absolute;
						top: 0px;
						left: 0px;
						.image3 {
							width: 77.64px;
							height: 50.75px;
							position: absolute;
							top: 32.28px;
							left: 23.33px;
						}
						.text21 {
							position: absolute;
							top: 40px;
							left: 40px;
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							color: rgba(255,143,56,1);
							line-height: 33.60px;
							font-weight: 500;
						}
					}
					.text22 {
						position: absolute;
						top: 49px;
						left: 340px;
						width: 260px;
						opacity: 0.8500000238418579;
						font-family: 'PingFang SC';
						font-size: 14px;
						color: rgba(102,102,102,1);
						font-weight: 400;
						white-space: pre-line;
					}
				}
				.image4 {
					width: 48px;
					height: 48px;
					position: absolute;
					top: 91px;
					left: 40px;
				}
				.image5 {
					width: 48px;
					height: 48px;
					position: absolute;
					top: 205px;
					left: 40px;
				}
				.image6 {
					width: 48px;
					height: 48px;
					position: absolute;
					top: 319px;
					left: 40px;
				}
			}
		}
		.kecheng4-page {
      margin-top: 80px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.text28 {
				font-family: 'PingFang SC';
				font-size: 36px;
				white-space: nowrap;
				text-align: center;
				color: rgba(0,0,0,1);
				line-height: 50.40px;
				font-weight: 500;
			}
			.text29 {
				margin-top: 20px;
				font-family: 'PingFang SC';
				font-size: 16px;
				white-space: nowrap;
				text-align: center;
				color: rgba(102,102,102,1);
				line-height: 22.40px;
				font-weight: 400;
			}
			.wrap {
				width: 1280px;
				border-radius: 16px;
				box-sizing: border-box;
				background: rgba(255,255,255,1);
				margin-top: 40px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.container {
					
					height: 80px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.content {
						width: 600px;
						height: 80px;
						overflow: hidden;
						border-radius: 16px;
						box-sizing: border-box;
						position: relative;
						.image {
							width: 594.89px;
							height: 80px;
							position: absolute;
							top: 0px;
							left: 0px;
						}
						.image1 {
							position: absolute;
							top: 0px;
							left: 0px;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 600px;
							height: 80px;
							background-image: url('./../assets/xyfwImg/kc4-Image8.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.text {
								font-family: 'PingFang SC';
								font-size: 24px;
								white-space: nowrap;
								text-align: center;
								color: rgba(255,255,255,1);
								line-height: 33.60px;
								font-weight: 500;
							}
						}
					}
					.content1 {
						width: 600px;
						height: 80px;
						overflow: hidden;
						border-radius: 16px;
						box-sizing: border-box;
						position: relative;
						.image2 {
							width: 594.89px;
							height: 80px;
							position: absolute;
							top: 0px;
							left: 5.11px;
						}
						.image3 {
							position: absolute;
							top: 0px;
							left: 0px;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 600px;
							height: 80px;
							background-image: url('./../assets/xyfwImg/kc4-Image12.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.text1 {
								font-family: 'PingFang SC';
								font-size: 24px;
								white-space: nowrap;
								text-align: center;
								color: rgba(255,255,255,1);
								line-height: 33.60px;
								font-weight: 500;
							}
						}
					}
				}
				.container1 {
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					justify-content: space-between;
					align-items: center;
					gap: 40px;
					margin-top: 40px;
					.content2 {
						
						height: 94px;
						box-sizing: border-box;
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						justify-content: space-between;
						align-items: center;
						
						.text2 {
							
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							text-align: center;
							color: rgba(0,0,0,1);
							line-height: 33.60px;
							font-weight: 500;
						}
						.text3 {
							width: 800px;
							font-family: 'PingFang SC';
							font-size: 14px;
							text-align: center;
							color: rgba(102,102,102,1);
							line-height: 19.60px;
							font-weight: 400;
						}
					}
					.content3 {
            width: 1200px;
            height: 736px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .main {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              .section {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text2 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection1 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text3 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection2 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text4 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection3 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text5 {
                    font-family: 'PingFang SC';
                    font-size: 12px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 16.80px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main1 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section1 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection4 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text6 {
                    font-family: 'PingFang SC';
                    font-size: 12px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 16.80px;
                    font-weight: 400;
                  }
                }
                .subSection5 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text7 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection6 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text8 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection7 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text9 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main2 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section2 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection8 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text10 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection9 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text11 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection10 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text12 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection11 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text13 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main3 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section3 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection12 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text14 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection13 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text15 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection14 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text16 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection15 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text17 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main4 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section4 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection16 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text18 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection17 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text19 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection18 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text20 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection19 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text21 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main5 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section5 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection20 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text22 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection21 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text23 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection22 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text24 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection23 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text25 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main6 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section6 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection24 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text26 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection25 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text27 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection26 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text28 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection27 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text29 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main7 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section7 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .subSection28 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text30 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection29 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text31 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection30 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text32 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection31 {
                  width: 285px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-left: 20px;
                  .text33 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
            .main8 {
              width: 1200px;
              height: 64px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              .section8 {
                width: 1200px;
                height: 64px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .subSection32 {
                  width: 590px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text34 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .subSection33 {
                  width: 590px;
                  height: 64px;
                  border-radius: 16px;
                  box-sizing: border-box;
                  background: rgba(242,245,252,1);
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  .text35 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
				}
			}
		}
  }
}
</style>