<template>
  <div class="aigc-main">
    <navpage class="nav"></navpage>

    <div class="banner-page">
      <div class="image">
        <img
          :src="require('./../assets/aboutus/bannerImage2.png')"
          class="image1"
          alt="Image Asset 3"
        />
      </div>
    </div>

    <div class="kecheng-page">

      <div class="kecheng-content">
        <div class="kecheng0-page">
          <div class="image">
            <p class="text">上海近屿智能科技有限公司</p>
            <p class="text1">
              上海近屿智能科技有限公司成立于2017年5月，她是一家以AI+RPA+BI技术为核心的人力资源方向的产品和解决方案提供商，拥有基于先进的篇章级语义识别算法和多模态算法构建的人力资源行业超脑-近屿超脑。近屿智能致力于通过先进技术，逐步的将人类从繁重、重复的劳动中解放出来，提升人类工作的效能。
            </p>
            <p class="text2">
              其核心产品AI得贤招聘官是一款智慧招聘管理系统，区别于传统的智能招聘ATS，AI得贤招聘官具有AI多模态算法核心，能够替代人类面试官对候选人的核心价值观，通用素质，潜在领导力和专业素质进行基于视频面试的判断，是一款L5级别AIGC面试系统。该系统已经被包括三星中国、招商银行、西门子等众多大客户验证可以完全替代人类面试官进行招聘面试场景的最终决策。
            </p>
            <p class="text3">
              AI得贤招聘官已上架至SAP APP
              Center，在200个国家和地区，向SAP的全球客户提供AI招聘和面试科技解决方案。AI得贤招聘官也已登录钉钉开放平台，为3000多万家中小型客户提供千企千面的招聘自动化、AI视频面试、模拟编程、认知能力测试、职业性格测试、心理测评等服务。
            </p>
          </div>
        </div>
        <div class="kecheng1-page">
					<div class="image">
						<div class="content"></div>
						<div class="content1"></div>
						<p class="text">公司奖项</p>
						<div class="content2">
							<img
								:src="require('./../assets/aboutus/kc1-Image9.png')"
								class="image1"
								alt="Image Asset 9"
							/>
							<div class="main">
								<div class="section">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image2"
										alt="Image Asset 12"
									/><img
										:src="require('./../assets/aboutus/kc1-Image13.png')"
										class="image3"
										alt="Image Asset 13"
									/>
								</div>
								<p class="text1">
									<span class="text1__linebreak">哈佛创业创新大赛亚太区 </span
									><span>预选赛季军</span>
								</p>
							</div>
							<div class="main1">
								<div class="section1">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image4"
										alt="Image Asset 17"
									/><img
										:src="require('./../assets/aboutus/kc1-Image18.png')"
										class="image5"
										alt="Image Asset 18"
									/>
								</div>
								<p class="text2">上海最具潜力50佳创业企业</p>
							</div>
							<div class="main2">
								<div class="section2">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image6"
										alt="Image Asset 22"
									/><img
										:src="require('./../assets/aboutus/kc1-Image18.png')"
										class="image7"
										alt="Image Asset 23"
									/>
								</div>
								<p class="text3">科大讯飞全球1024开发者节“最具商业价值”奖</p>
							</div>
							<div class="main3">
								<div class="section3">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image8"
										alt="Image Asset 27"
									/><img
										:src="require('./../assets/aboutus/kc1-Image18.png')"
										class="image9"
										alt="Image Asset 28"
									/>
								</div>
								<p class="text4">
									HR领域在中国人工智能协会和中国中文信息协会唯--家企业会员单位
								</p>
							</div>
						</div>
						<div class="content3">
							<img
								:src="require('./../assets/aboutus/kc1-Image31.png')"
								class="image10"
								alt="Image Asset 31"
							/>
							<div class="main4">
								<div class="section4">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image11"
										alt="Image Asset 34"
									/><img
										:src="require('./../assets/aboutus/kc1-Image35.png')"
										class="image12"
										alt="Image Asset 35"
									/>
								</div>
								<p class="text5">第四届“中国创翼”创业创新大赛优胜奖</p>
							</div>
							<div class="main5">
								<div class="section5">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image13"
										alt="Image Asset 39"
									/><img
										:src="require('./../assets/aboutus/kc1-Image35.png')"
										class="image14"
										alt="Image Asset 40"
									/>
								</div>
								<p class="text6">科大讯飞全球1024开发者节“最受喜爱展商”奖</p>
							</div>
							<div class="main6">
								<div class="section6">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image15"
										alt="Image Asset 44"
									/><img
										:src="require('./../assets/aboutus/kc1-Image35.png')"
										class="image16"
										alt="Image Asset 45"
									/>
								</div>
								<p class="text7">
									大中华地区HRVP高峰论坛“中国十大影响力人力资源品牌”
								</p>
							</div>
							<div class="main7">
								<div class="section7">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image17"
										alt="Image Asset 49"
									/><img
										:src="require('./../assets/aboutus/kc1-Image35.png')"
										class="image18"
										alt="Image Asset 50"
									/>
								</div>
								<p class="text8">大中华地区HRVP高峰论坛“最具创新招聘产品”</p>
							</div>
						</div>
						<div class="content4">
							<img
								:src="require('./../assets/aboutus/kc1-Image53.png')"
								class="image19"
								alt="Image Asset 53"
							/>
							<div class="main8">
								<div class="section8">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image20"
										alt="Image Asset 56"
									/><img
										:src="require('./../assets/aboutus/kc1-Image57.png')"
										class="image21"
										alt="Image Asset 57"
									/>
								</div>
								<p class="text9">人力资源品牌服务商百强榜单</p>
							</div>
							<div class="main9">
								<div class="section9">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image22"
										alt="Image Asset 61"
									/><img
										:src="require('./../assets/aboutus/kc1-Image62.png')"
										class="image23"
										alt="Image Asset 62"
									/>
								</div>
								<p class="text10">招聘技术创新HR&nbsp;臻选供应商</p>
							</div>
							<div class="main10">
								<div class="section10">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image24"
										alt="Image Asset 66"
									/><img
										:src="require('./../assets/aboutus/kc1-Image62.png')"
										class="image25"
										alt="Image Asset 67"
									/>
								</div>
								<p class="text11">人工智能技术应用HR甄选供应商奖——招聘与任用</p>
							</div>
							<div class="main11">
								<div class="section11">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image26"
										alt="Image Asset 71"
									/><img
										:src="require('./../assets/aboutus/kc1-Image72.png')"
										class="image27"
										alt="Image Asset 72"
									/>
								</div>
								<p class="text12">黑马AI创新大赛季军</p>
							</div>
						</div>
						<div class="content5">
							<img
								:src="require('./../assets/aboutus/kc1-Image75.png')"
								class="image28"
								alt="Image Asset 75"
							/>
							<div class="main12">
								<div class="section12">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image29"
										alt="Image Asset 78"
									/><img
										:src="require('./../assets/aboutus/kc1-Image72.png')"
										class="image30"
										alt="Image Asset 79"
									/>
								</div>
								<p class="text13">首届“文创上海”创业创新大赛二等奖</p>
							</div>
							<div class="main13">
								<div class="section13">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image31"
										alt="Image Asset 83"
									/><img
										:src="require('./../assets/aboutus/kc1-Image72.png')"
										class="image32"
										alt="Image Asset 84"
									/>
								</div>
								<p class="text14">上海市徐汇区创新创业大赛光启创客铜奖</p>
							</div>
							<div class="main14">
								<div class="section14">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image33"
										alt="Image Asset 88"
									/><img
										:src="require('./../assets/aboutus/kc1-Image89.png')"
										class="image34"
										alt="Image Asset 89"
									/>
								</div>
								<p class="text15">专精特新企业</p>
							</div>
							<div class="main15">
								<div class="section15">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image35"
										alt="Image Asset 93"
									/><img
										:src="require('./../assets/aboutus/kc1-Image89.png')"
										class="image36"
										alt="Image Asset 94"
									/>
								</div>
								<p class="text16">高新技术企业</p>
							</div>
						</div>
						<div class="content6">
							<img
								:src="require('./../assets/aboutus/kc1-Image97.png')"
								class="image37"
								alt="Image Asset 97"
							/>
							<div class="main16">
								<div class="section16">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image38"
										alt="Image Asset 100"
									/><img
										:src="require('./../assets/aboutus/kc1-Image89.png')"
										class="image39"
										alt="Image Asset 101"
									/>
								</div>
								<p class="text17">人力资源供应商价值大奖</p>
							</div>
							<div class="main17">
								<div class="section17">
									<img
										:src="require('./../assets/aboutus/kc1-Image12.png')"
										class="image40"
										alt="Image Asset 105"
									/><img
										:src="require('./../assets/aboutus/kc1-Image89.png')"
										class="image41"
										alt="Image Asset 106"
									/>
								</div>
								<p class="text18">工业和信息化部工业文化发展中心认证</p>
							</div>
							<div class="main18">
								<img
									:src="require('./../assets/aboutus/kc1-Image12.png')"
									class="image42"
									alt="Image Asset 109"
								/>
								<div class="section18">
									<img
										:src="require('./../assets/aboutus/kc1-Image89.png')"
										class="image43"
										alt="Image Asset 111"
									/>
									<p class="text19">发明专利</p>
									<p class="text20"><span>◇</span>&nbsp;一种基于位置关系的智能面试方法及系统</p>
									<p class="text21"><span>◇</span>&nbsp;一种基于编程能力评估的创新能力评估方法</p>
									<p class="text22">
										<span>◇</span>&nbsp;一种综合招聘难度模型的构建方法及招聘难度的评估方法
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
        <div class="kecheng2-page">
          <p class="text"><span class="blue">团队</span>介绍</p>
          <div class="wrap">
            <div class="image">
              <img
                class="image_1"
                :src="require('./../assets/aboutus/kc2-Image1.png')"
                alt="Image Asset 1"
              />
              <img
                class="image_2"
                :src="require('./../assets/aboutus/kc2-Image4.png')"
                alt="Image Asset 4"
              />
            </div>
            <div class="image1">
              <img
                class="image1_1"
                :src="require('./../assets/aboutus/kc2-Image2.png')"
                alt="Image Asset 2"
              />
              <img
                class="image1_2"
                :src="require('./../assets/aboutus/kc2-Image5.png')"
                alt="Image Asset 5"
              />
            </div>
            <div class="image2">
              <img
                class="image2_1"
                :src="require('./../assets/aboutus/kc2-Image3.png')"
                alt="Image Asset 3"
              />
              <img
                class="image2_2"
                :src="require('./../assets/aboutus/kc2-Image6.png')"
                alt="Image Asset 6"
              />
            </div>
          </div>

        </div>
        <div class="kecheng3-page">
          <p class="text">一线<span class="blue">投资机构</span>广泛认可</p>
          <div class="wrap">
            <img
              :src="require('./../assets/aboutus/kc3-Image5.png')"
              class="image"
              alt="Image Asset 5"
            />
            <p class="text2">朱啸虎</p>
            <p class="text3">金沙江创投 主管合伙人</p>
            <div class="container">
              <p class="text4">➢ 10年投出5家独角兽，被誉为互联网创投界的金手指；</p>
              <p class="text5">➢ 投资案例：滴滴，ofo，饿了么的早期投资人；</p>
              <p class="text7">
                ➢ 在新全球创投界含金量最高排行榜“2017全球点石成金榜”（Midas List
                2017）中，继入选“Midas List 2016”之后，再次入选“Midas List 2017”，
              </p>
              <p class="text8">名列第 84位，成为榜单上中国区最年轻投资人；</p>
              <p class="text6">➢ 入选“2019年中国最具影响力的30位投资人”榜单。</p>
            </div>
          </div>
          <div class="wrap1">
            <img
              :src="require('./../assets/aboutus/kc3-Image16.png')"
              class="image1"
              alt="Image Asset 16"
            />
            <p class="text10">李竹</p>
            <p class="text11">英诺天使基金 创始合伙人</p>
            <div class="container1">
              <p class="text12">➢ 中国青年天使会会长，清华校友TMT协会荣誉会长；</p>
              <p class="text13">
                ➢
                投资案例：美团网、游族网络、德生科技、柠檬微趣、臻迪科技、推想科技、智行者、未来黑科技、京微齐力等；
              </p>
              <p class="text14">
                ➢ 2000年开始天使投资，累计参投项目300+，多个项目已上市或被并购；
              </p>
              <p class="text15">➢ 入选“2017投资界TOP100投资人”榜单。</p>
            </div>
          </div>
          <div class="wrap2">
            <img
              :src="require('./../assets/aboutus/kc3-Image26.png')"
              class="image2"
              alt="Image Asset 26"
            />
            <p class="text17">牛文文</p>
            <p class="text18">黑马基金 创始人</p>
            <div class="container2">
              <p class="text19">
                ➢
                投资案例：凯叔讲故事、贝尔科教、疯狂老师、微语言、宜花、易代储、食务链、信良记、Wook、公司宝、魔方招聘、Vphoto、火星文化等70多个项目；
              </p>
              <p class="text20">
                ➢
                黑马基金的出资人来自各领域领军型上市公司的创始人，包括京东集团创始人刘强东、58赶集创始人姚劲波、360公司创始人周鸿祎、光线传媒创始人王
              </p>
              <p class="text21">
                长田、新东方创始人俞敏洪、蓝色光标创始人赵文权、探路者创始人盛发强等，以及红杉资本、赛伯乐投资、何伯权、徐小平、戴志康等著名投资机构与
              </p>
              <p class="text22">投资人。</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import navpage from './../components/nav.vue'

export default {
  components: { navpage },
  data() {
    return {
      subSection: 1,


    }
  }
}
</script>
<style lang="less" scoped>
.blue{
  color: #1F6AFB;
}
.aigc-main {
  min-width: 1280px;
  max-width: 100%;
  margin: auto;
  .banner-page {
    // width: 100%;
    height: 480px;
    // padding-top: 80px;
    overflow: hidden;
    box-sizing: border-box;
    background: rgba(244,247,252,1);
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
			max-width: 1920px;
      height: 480px;
      box-sizing: border-box;
      background-image: url('./../assets/aboutus/bannerImage.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .image1 {
        width: 252px;
        height: 59px;
        margin: 200px auto 0;
      }
      .text {
        margin: 45.96px auto;
        // margin-left: 606px;
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(51,51,51,1);
        font-weight: 500;
        white-space: pre-line;
      }
      .wrap {
        width: 179px;
        height: 48px;
        border-radius: 61px;
        box-sizing: border-box;
        background: rgba(31,106,251,1);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 50px auto 0;
        .image2 {
          width: 105.01px;
          height: 16.63px;
          margin-right: 8px;
        }
        .image3 {
          width: 16px;
          height: 16px;
          overflow: hidden;
          box-sizing: border-box;
        }
      }
    }
  }
  .kecheng-page{
    margin-top: -80px;
		// height: 148px;
		border-radius: 16px;
		box-sizing: border-box;
		background: #F4F7FC;
    .kecheng-title {
      margin: 0 auto 20px;
      width: 1280px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 1280px;
      height: 148px;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;
      background: rgba(255,255,255,1);


      p {
        margin-left: 39.53px;
        margin-bottom: 39.26px;
        
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(0,0,0,1);
        line-height: 33.60px;
        font-weight: 500;
      }
    }
    .kecheng-content{
      display: flex;
      flex-direction: column;
      width: 100%;
      // background-image: url('./../assets/aigcImg/kechengBg1.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      >img{
        margin: 20px auto;
        width: 1280px;
      }
    }
    .kecheng0-page {
      // width: 1296px;
      height: 288px;
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 1280px;
        height: 288px;
        overflow: hidden;
        border-radius: 16px;
        padding: 0 40px;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.03);
        background: rgba(255,255,255,0.95);
        // background-image: url('./../assets/aboutus/kc1-Image2.png');
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        .text {
          margin-top: 28px;
          font-family: 'PingFang SC';
          font-size: 24px;
          white-space: nowrap;
          text-align: center;
          color: rgba(0,0,0,1);
          line-height: 33.60px;
          font-weight: 500;
        }
        .text1 {
          margin-top: 24px;
          // width: 1200px;
          font-family: 'PingFang SC';
          font-size: 14px;
          text-align: center;
          color: rgba(102,102,102,1);
          line-height: 19.60px;
          font-weight: 400;
        }
        .text2 {
          margin-top: 16px;
          // width: 1200px;
          font-family: 'PingFang SC';
          font-size: 14px;
          text-align: center;
          color: rgba(102,102,102,1);
          line-height: 19.60px;
          font-weight: 400;
        }
        .text3 {
          margin-top: 16px;
          // width: 1200px;
          font-family: 'PingFang SC';
          font-size: 14px;
          text-align: center;
          color: rgba(102,102,102,1);
          line-height: 19.60px;
          font-weight: 400;
        }
      }
    }
		.kecheng1-page {
			// width: 1920px;
			height: 840px;
			margin-top: 80px;
			overflow: hidden;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
					.image {
						position: relative;
						width: 1920px;
						height: 840px;
						background-image: url('./../assets/aboutus/kc1-Image4.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;
						.content {
							width: 1920px;
							opacity: 0.25;
							box-sizing: border-box;
							background: rgba(0,20,33,1);
							min-height: 840px;
							position: absolute;
							top: 0px;
							left: 0px;
						}
						.content1 {
							width: 1920px;
							mix-blend-mode: soft-light;
							opacity: 0.6499999761581421;
							box-sizing: border-box;
							background: rgba(44,70,145,1);
							min-height: 840px;
							position: absolute;
							top: 0px;
							left: 0px;
						}
						.text {
							position: absolute;
							top: 80px;
							left: 888px;
							font-family: 'PingFang SC';
							font-size: 36px;
							white-space: nowrap;
							text-align: center;
							color: rgba(255,255,255,1);
							line-height: 50.40px;
							font-weight: 500;
						}
						.content2 {
							position: absolute;
							top: 170px;
							left: 322.47px;
							width: 192px;
							height: 487.50px;
							.image1 {
								width: 1px;
								height: 471.38px;
								position: absolute;
								top: 16.13px;
								left: 7.50px;
							}
							.main {
								position: absolute;
								top: 0px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section {
									width: 87.94px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image2 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image3 {
										width: 54.88px;
										height: 19.03px;
									}
								}
								.text1 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									font-weight: 400;
									white-space: pre-line;
									.text1__linebreak {
									}
								}
							}
							.main1 {
								position: absolute;
								top: 116px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section1 {
									width: 87.50px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image4 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image5 {
										width: 54.45px;
										height: 19.03px;
									}
								}
								.text2 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main2 {
								position: absolute;
								top: 232px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section2 {
									width: 87.50px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image6 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image7 {
										width: 54.45px;
										height: 19.03px;
									}
								}
								.text3 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main3 {
								position: absolute;
								top: 348px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 96px;
								.section3 {
									width: 87.50px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image8 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image9 {
										width: 54.45px;
										height: 19.03px;
									}
								}
								.text4 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
						}
						.content3 {
							position: absolute;
							top: 170px;
							left: 586.10px;
							width: 192px;
							height: 487.50px;
							.image10 {
								width: 1px;
								height: 471.38px;
								position: absolute;
								top: 16.13px;
								left: 7.50px;
							}
							.main4 {
								position: absolute;
								top: 0px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section4 {
									width: 90.47px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image11 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image12 {
										width: 57.41px;
										height: 19.03px;
									}
								}
								.text5 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 170px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main5 {
								position: absolute;
								top: 116px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section5 {
									width: 90.47px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image13 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image14 {
										width: 57.41px;
										height: 19.03px;
									}
								}
								.text6 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main6 {
								position: absolute;
								top: 232px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 96px;
								.section6 {
									width: 90.47px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image15 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image16 {
										width: 57.41px;
										height: 19.03px;
									}
								}
								.text7 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main7 {
								position: absolute;
								top: 368px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section7 {
									width: 90.47px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image17 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image18 {
										width: 57.41px;
										height: 19.03px;
									}
								}
								.text8 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
						}
						.content4 {
							position: absolute;
							top: 170px;
							left: 859.74px;
							width: 192px;
							height: 487.50px;
							.image19 {
								width: 1px;
								height: 471.38px;
								position: absolute;
								top: 16.13px;
								left: 7.50px;
							}
							.main8 {
								position: absolute;
								top: 0px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section8 {
									width: 84.53px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image20 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image21 {
										width: 51.48px;
										height: 19.03px;
									}
								}
								.text9 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main9 {
								position: absolute;
								top: 116px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section9 {
									width: 89.52px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image22 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image23 {
										width: 56.47px;
										height: 19.03px;
									}
								}
								.text10 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main10 {
								position: absolute;
								top: 232px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section10 {
									width: 89.52px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image24 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image25 {
										width: 56.47px;
										height: 19.03px;
									}
								}
								.text11 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main11 {
								position: absolute;
								top: 348px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 56px;
								.section11 {
									width: 89.35px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image26 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image27 {
										width: 56.29px;
										height: 19.03px;
									}
								}
								.text12 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									white-space: nowrap;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
						}
						.content5 {
							position: absolute;
							top: 170px;
							left: 1133.37px;
							width: 192px;
							height: 487.50px;
							.image28 {
								width: 1px;
								height: 471.38px;
								position: absolute;
								top: 16.13px;
								left: 7.50px;
							}
							.main12 {
								position: absolute;
								top: 0px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section12 {
									width: 89.35px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image29 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image30 {
										width: 56.29px;
										height: 19.03px;
									}
								}
								.text13 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 170px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main13 {
								position: absolute;
								top: 116px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section13 {
									width: 89.35px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image31 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image32 {
										width: 56.29px;
										height: 19.03px;
									}
								}
								.text14 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main14 {
								position: absolute;
								top: 232px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 56px;
								.section14 {
									width: 90.02px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image33 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image34 {
										width: 56.96px;
										height: 19.03px;
									}
								}
								.text15 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									white-space: nowrap;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main15 {
								position: absolute;
								top: 328px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 56px;
								.section15 {
									width: 90.02px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image35 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image36 {
										width: 56.96px;
										height: 19.03px;
									}
								}
								.text16 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									white-space: nowrap;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
						}
						.content6 {
							position: absolute;
							top: 170px;
							left: 1400px;
							width: 192px;
							height: 487.50px;
							.image37 {
								width: 1px;
								height: 471.38px;
								position: absolute;
								top: 16.13px;
								left: 7.50px;
							}
							.main16 {
								position: absolute;
								top: 0px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 56px;
								.section16 {
									width: 90.02px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image38 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image39 {
										width: 56.96px;
										height: 19.03px;
									}
								}
								.text17 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									white-space: nowrap;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main17 {
								position: absolute;
								top: 96px;
								left: 0px;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								width: 192px;
								height: 76px;
								.section17 {
									width: 90.02px;
									height: 19.03px;
									margin-top: 6.54px;
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
									.image40 {
										width: 16px;
										height: 16px;
										margin-top: 1.46px;
									}
									.image41 {
										width: 56.96px;
										height: 19.03px;
									}
								}
								.text18 {
									margin-top: 10.42px;
									margin-left: 32px;
									width: 160px;
									font-family: 'PingFang SC';
									font-size: 14px;
									color: rgba(255,255,255,1);
									line-height: 19.60px;
									font-weight: 400;
								}
							}
							.main18 {
								position: absolute;
								top: 212px;
								left: 0px;
								display: flex;
								justify-content: space-between;
								align-items: flex-start;
								width: 192px;
								height: 208px;
								.image42 {
									width: 16px;
									height: 16px;
									margin-top: 8px;
								}
								.section18 {
									width: 160px;
									height: 201.46px;
									margin-top: 6.54px;
									display: flex;
									flex-direction: column;
									justify-content: flex-start;
									align-items: flex-start;
									.image43 {
										width: 56.96px;
										height: 19.03px;
										margin-left: 1.06px;
									}
									.text19 {
										margin-top: 10.42px;
										width: 160px;
										font-family: 'PingFang SC';
										font-size: 14px;
										white-space: nowrap;
										color: rgba(255,255,255,1);
										line-height: 19.60px;
										font-weight: 500;
									}
									.text20 {
										margin-top: 4px;
										width: 160px;
										font-family: 'PingFang SC';
										font-size: 14px;
										color: rgba(255,255,255,1);
										line-height: 19.60px;
										font-weight: 400;
										>span{
											position: relative;
											top: 3px;
											font-size: 20px;
										}
									}
									.text21 {
										margin-top: 4px;
										width: 160px;
										font-family: 'PingFang SC';
										font-size: 14px;
										color: rgba(255,255,255,1);
										line-height: 19.60px;
										font-weight: 400;
										>span{
											position: relative;
											top: 3px;
											font-size: 20px;
										}
									}
									.text22 {
										margin-top: 4px;
										width: 160px;
										font-family: 'PingFang SC';
										font-size: 14px;
										color: rgba(255,255,255,1);
										line-height: 19.60px;
										font-weight: 400;
										>span{
											position: relative;
											top: 3px;
											font-size: 20px;
										}
							}
						}
					}
				}
			}
		}
    .kecheng2-page {
      // width: 1280px;
      // height: 490px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .text {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        line-height: 50.40px;
        font-weight: 500;
        margin: 80px 0 40px;
      }
      .wrap {
        width: 1279px;
        height: 460px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
          height: 100%;
          width: 100%;
        }
        .image {
          width: 413px;
          height: 460px;
          >.image_2 {
            display: none;
          }
        }
        .image1 {
          width: 413px;
          height: 460px;
          margin-left: 20px;
          >.image1_2 {
            display: none;
          }
        }
        .image2 {
          width: 413px;
          height: 460px;
          margin-left: 20px;
          >.image2_2 {
            display: none;
          }
        }
        
        .image:hover{
          >.image_2 {
            display: block;
          }
          .image_1 {
            display: none;
          }
        }
        .image1:hover{
          >.image1_2 {
            display: block;
          }
          .image1_1 {
            display: none;
          }
        }
        .image2:hover{
          >.image2_2 {
            display: block;
          }
          .image2_1 {
            display: none;
          }
        }
      }
    }
    .kecheng3-page {
			// width: 1296px;
			// height: 922px;
      padding-bottom: 80px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.text {
				font-family: 'PingFang SC';
				font-size: 36px;
				white-space: nowrap;
				text-align: center;
				color: rgba(0,0,0,1);
				line-height: 50.40px;
				font-weight: 500;
        margin: 80px 0 40px;
			}
			.wrap {
				width: 1280px;
				height: 260px;
				overflow: hidden;
				border-radius: 16px;
				border-width: 2px;
				box-sizing: border-box;
				box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.03);
				background: rgba(255,255,255,1);
				position: relative;
				.image {
					width: 238px;
					height: 260px;
					overflow: hidden;
					box-sizing: border-box;
					background: linear-gradient(180deg, rgba(69,69,78,1) 0%,rgba(113,121,133,1) 100%);
					position: absolute;
					// top: 4px;
					// left: 8px;
				}
				.text2 {
					position: absolute;
					top: 44px;
					left: 286px;
					font-family: 'PingFang SC';
					font-size: 24px;
					white-space: nowrap;
					color: rgba(0,0,0,1);
					line-height: 33.60px;
					font-weight: 500;
				}
				.text3 {
					position: absolute;
					top: 55px;
					left: 374px;
					font-family: 'PingFang SC';
					font-size: 14px;
					white-space: nowrap;
					color: rgba(0,0,0,1);
					line-height: 19.60px;
					font-weight: 400;
				}
				.container {
					position: absolute;
					top: 98px;
					left: 286px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					width: 962px;
					height: 123.95px;
					.text4 {
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text5 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text7 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text8 {
						margin-left: 18.89px;
						width: 943.11px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text6 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
				}
			}
			.wrap1 {
				width: 1280px;
				height: 260px;
				overflow: hidden;
				border-radius: 16px;
				border-width: 2px;
				box-sizing: border-box;
				box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.03);
				background: rgba(255,255,255,1);
				margin-top: 20px;
				position: relative;
				.image1 {
					width: 238px;
					height: 260px;
					overflow: hidden;
					box-sizing: border-box;
					background: linear-gradient(180deg, rgba(69,69,78,1) 0%,rgba(113,121,133,1) 100%);
					position: absolute;
					// top: 4px;
					// left: 8px;
				}
				.text10 {
					position: absolute;
					top: 44px;
					left: 286px;
					font-family: 'PingFang SC';
					font-size: 24px;
					white-space: nowrap;
					color: rgba(0,0,0,1);
					line-height: 33.60px;
					font-weight: 500;
				}
				.text11 {
					position: absolute;
					top: 55px;
					left: 350px;
					font-family: 'PingFang SC';
					font-size: 14px;
					white-space: nowrap;
					color: rgba(0,0,0,1);
					line-height: 19.60px;
					font-weight: 400;
				}
				.container1 {
					position: absolute;
					top: 98px;
					left: 286px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
					width: 962px;
					height: 104px;
					.text12 {
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text13 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text14 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text15 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
				}
			}
			.wrap2 {
				width: 1280px;
				height: 260px;
				overflow: hidden;
				border-radius: 16px;
				border-width: 2px;
				box-sizing: border-box;
				box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.03);
				background: rgba(255,255,255,1);
				margin-top: 20px;
				position: relative;
				.image2 {
					width: 238px;
					height: 260px;
					overflow: hidden;
					box-sizing: border-box;
					background: linear-gradient(180deg, rgba(69,69,78,1) 0%,rgba(113,121,133,1) 100%);
					position: absolute;
					// top: 4px;
					// left: 8px;
				}
				.text17 {
					position: absolute;
					top: 44px;
					left: 286px;
					font-family: 'PingFang SC';
					font-size: 24px;
					white-space: nowrap;
					color: rgba(0,0,0,1);
					line-height: 33.60px;
					font-weight: 500;
				}
				.text18 {
					position: absolute;
					top: 55px;
					left: 374px;
					font-family: 'PingFang SC';
					font-size: 14px;
					white-space: nowrap;
					color: rgba(0,0,0,1);
					line-height: 19.60px;
					font-weight: 400;
				}
				.container2 {
					position: absolute;
					top: 98px;
					left: 286px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					width: 974.47px;
					height: 88px;
					.text19 {
						width: 974.47px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text20 {
						margin-top: 8px;
						width: 962px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text21 {
						margin-left: 17.61px;
						width: 944.39px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
					.text22 {
						margin-left: 17.61px;
						width: 944.39px;
						font-family: 'PingFang SC';
						font-size: 14px;
						white-space: nowrap;
						color: rgba(102,102,102,1);
						line-height: 19.60px;
						font-weight: 400;
					}
				}
			}
		}
  }
}
</style>