<template>
  <PagePc v-if="true"></PagePc>
  <PageH5 v-else></PageH5>
</template>
<script>
import PagePc from "./lanhai/main.vue";
import PageH5 from "./lanhai_h5/index.vue";
export default {
  components: { PagePc,PageH5 },
  data() {
    return {
      isMobile: false
    };
  },
  created(){
    const isMobile =
      /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(
        navigator.userAgent.toLowerCase()
      );
    if (isMobile) {
      console.log("移动端访问");
      this.isMobile = true
      // this.checkAndSetViewport();
    }
    document.title = "近屿智能AI多模态大模型应用工程师和产品经理人才培养项目"
  },
  methods: {
    checkAndSetViewport() {
      let viewportMeta = document.querySelector('meta[name="viewport"]');
      if (!viewportMeta) {
        viewportMeta = document.createElement('meta');
        viewportMeta.setAttribute('name', 'viewport');
        viewportMeta.setAttribute('user-scalable', 'yes');
        document.head.appendChild(viewportMeta);
      }
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=yes');
    }
  }
};
</script>
