<template>
  <van-loading v-if="loading" class="pageLoading" type="spinner" vertical color="#1989fa" text-color="#1989fa">

  </van-loading>
  <div class="page flex-col" v-else>
    <div class="group_1 flex-col">
      <span class="paragraph_1"
        >近屿智能<br />AI多模态大模型应用工程师和产品经理人才培养项目</span
      >
      <span class="text_1"
        >上海近屿智能科技有限公司的前身是南京葡萄诚信息科技有限公司，成立于2017年5月，她是一家AI+RPA+BI技术为核心的人力资源方向的产品和解决方案提供商，拥有基于先进的篇章级语义识别算法和多模态算法构建的人力资源行业超脑-近屿超脑。近屿智能致力于通过先进技术，逐步的将人类从繁重、重复的劳动中解放出来，提升人类工作的效能。</span
      >
      <span class="text_2"
        >AI得贤人才管理系统是中国第一款基于AIGC和虚拟人技术的HR数字孪生系统，包含L5级AI视频面试&nbsp;、&nbsp;L5级AI人才盘点、&nbsp;L5级AI人才培训测评&nbsp;、L5级敬业度管理、智能招聘管理系统、在线考试、认知能力测试、职业性格测试、心理健康测评、MeriTas测评、编程考试等服务。</span
      >
      <span class="text_3"
        >得贤L5级AI面试真正做到以候选人的胜任力，知识和技能为基础，以完全自研的AI多模态大模型为核心，进行人岗匹配。帮助企业高效识别高潜人员并给予最佳培养，帮助高潜人员尽快脱颖而出并在晋升前做好充分的胜任力、知识和技能准备。</span
      >
      <div class="box_1 flex-col">
        <!-- <video
          id="bannerVideo"
          ref="videoPlayer2"
          :src="viewingUrl"
          muted
          controls
          preload
          controlslist="nodownload noremoteplayback"
          :poster="require('@/views/lanhai/assets/img/index_p2_img4.png')"
        ></video> -->
        <video-player ref="videoPlayer" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions"></video-player>

        <!-- <div type="primary" class="unmute_btn" @click="unmute2">
          <img :src="muted2 ? require('@/views/lanhai/assets/img/unmuted.png') : require('@/views/lanhai/assets/img/muted.png')" />
          <div>{{ muted2 ? '静音' : '解除静音' }}</div>
        </div> -->
      </div>
      <div class="box_2 flex-row justify-between">
        <span class="paragraph_2">联系我们，<br />了解课程详情</span>
        <div class="box_3 flex-col">
          <img :src="require('@/views/lanhai_h5/assets/img/qrcode@2x.png')" alt="" srcset="">
        </div>
      </div>
      <div class="box_4 flex-col"></div>
      <span class="paragraph_3">AIGC蓝海<br />高薪就业机会等你把握</span>
    </div>
    <div class="block_16 flex-col">
      <div class="box_40 flex-row justify-between">
        <div class="text-wrapper_2 flex-col"><span class="text_1">01</span></div>
        <div class="text-wrapper_3">
          <span class="text_2">灵活</span>
          <span class="paragraph_2">课程安排，
            <br />
            满足</span>
          <span class="text_3">多元</span>
          <span class="text_4">学习需求</span>
        </div>
      </div>
    </div>
    <div class="section_2 flex-row">
      <div class="text-group_57 flex-col">
        <span class="text_5">0基础启航</span>
        <span class="paragraph_3">
          从零开始，帮助学员快速上手，
          <br />
          打下坚实基础，掌握AI领域新知识
        </span>
      </div>
    </div>
    <div class="section_3 flex-row">
      <div class="text-group_58 flex-col">
        <span class="text_6">应届毕业生</span>
        <span class="paragraph_4">
          为即将步入职场的你量身定制，
          <br />
          助力快速适应职场需求
        </span>
      </div>
    </div>
    <div class="section_4 flex-row">
      <div class="text-group_59 flex-col">
        <span class="text_7">转行提薪</span>
        <span class="paragraph_5">
          助力职场转型，掌握新技能，
          <br />
          实现薪资跃升
        </span>
      </div>
    </div>
    <div class="block_17 flex-col">
      <div class="section_53 flex-row justify-between">
        <div class="text-wrapper_4 flex-col"><span class="text_8">02</span></div>
        <div class="text-wrapper_5">
          <span class="text_9">三大</span>
          <span class="text_10">AIGC</span>
          <span class="paragraph_6">大模型系列
            <br />
            培训课程
          </span>
        </div>
      </div>
      <div class="text-wrapper_6 flex-col"><span class="text_11">AIGC大模型应用工程师</span></div>
    </div>
    <div class="section_6 flex-col">
      <div class="text-wrapper_7 flex-col"><span class="text_12">课程简介</span></div>
      <span class="paragraph_7">
        本课程适合零基础入门到进阶AI人工智能工程师意向学员。
        <br />
        我们提供个性化的课程设计，以适应不同学员的起点和需求。课程每个阶段都设定了相应的学习标准，并且从A3阶段开始，哈工大的博导会亲手带着你开发<br />3-5个AI实战项目，结合热门行业电商、金融、电信、医药真实案例和业务出发，升华技术应用场景，达到企业用人标准，快速在AI时代找准工作定位。大家可以根据自己的职业规划，选择对应的就业方向。
      </span>
      <div class="text-wrapper_8 flex-col"><span class="text_13">课程目标</span></div>
      <span class="text_14">
        0基础和有基础均可学习，不同阶段课程自由选择，定向培养人工智能方向的AI人才，培训结束合格即可上岗，从事Prompt(提示词)工程师、AI应用开发工程师等高薪技术岗位。
      </span>
    </div>
    <div class="section_7 flex-col">
      <div class="text-wrapper_9 flex-col"><span class="text_15">A2&nbsp;基于提示工程的大模型集成</span></div>
      <span class="text_16">•&nbsp;A2.0&nbsp;Python之提示工程基础</span>
      <span class="text_17">•&nbsp;A2.1&nbsp;大模型提示工程</span>
      <span class="text_18">•&nbsp;A2.2&nbsp;大模型编程</span>
      <span class="text_19">•&nbsp;A2.3&nbsp;大模型的API及Plugins调用</span>
      <span class="text_20">•&nbsp;A2.4&nbsp;远程实践项目+爬梯考试</span>
      <span class="text_21">•&nbsp;A2.5&nbsp;大模型的发展历程（选修）</span>
      <span class="text_22">•&nbsp;A2.6&nbsp;GPT4-Turbo与Gemini最新解读（选修）</span>
    </div>
    <div class="section_8 flex-col">
      <div class="text-wrapper_10 flex-col"><span class="text_23">A3&nbsp;基于大模型框架的领域应用开发</span></div>
      <span class="text_24">•&nbsp;A3.1&nbsp;大模型RAG</span>
      <span class="text_25">•&nbsp;A3.2&nbsp;LangChain&nbsp;&amp;&nbsp;Semantic&nbsp;Kernel原理</span>
      <span class="text_26">•&nbsp;A3.3&nbsp;LangChain&nbsp;&amp;&nbsp;Semantic&nbsp;Kernel实战</span>
      <span class="text_27">•&nbsp;A3.4&nbsp;AutoGen原理（多智能体）</span>
      <span class="text_28">•&nbsp;A3.5&nbsp;AutoGen实战（多智能体）</span>
      <span class="text_29">•&nbsp;A3.6&nbsp;远程实践项目+爬梯考试</span>
    </div>
    <div class="section_9 flex-col">
      <div class="text-wrapper_11 flex-col"><span class="text_30">A4&nbsp;面向任务的大模型指令训练</span></div>
      <span class="text_31">•&nbsp;A4.1&nbsp;大模型开发环境及工具</span>
      <span class="text_32">•&nbsp;A4.2&nbsp;大模型微调</span>
      <span class="text_33">•&nbsp;A4.3&nbsp;大模型微调实战</span>
      <span class="text_34">•&nbsp;A4.4&nbsp;大模型思维链</span>
      <span class="text_35">•&nbsp;A4.5&nbsp;大模型结构解析</span>
      <span class="text_36">•&nbsp;A4.6&nbsp;深度学习基础与实战</span>
      <span class="text_37">•&nbsp;A4.7&nbsp;Transformer原理</span>
      <span class="text_38">•&nbsp;A4.8&nbsp;远程实践项目+爬梯考试</span>
      <span class="text_39">•&nbsp;A4.9&nbsp;Stable&nbsp;Diffusion（选修）</span>
    </div>
    <div class="text-wrapper_12 flex-col"><span class="text_40">AIGC多模态大模型应用工程师</span></div>
    <div class="section_10 flex-col">
      <div class="text-wrapper_13 flex-col"><span class="text_41">课程简介</span></div>
      <span class="text_42">
        本课程专为希望成为AIGC（人工智能生成内容）多模态大模型应用工程师的专业人士设计。课程旨在为学生提供从基础工具使用到高级工具开发和增强的全面知识体系。通过本课程的学习，学生将能够掌握多模态大模型的应用，理解其理论基础，并学会如何使用和创新AI创作工具。
      </span>
      <div class="text-wrapper_14 flex-col"><span class="text_43">课程目标</span></div>
      <div class="image-text_35 flex-row justify-between">
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_4">掌握多模态大模型（MLLM）工具的使用，包括内容生成、视觉艺术创作和音乐生成等。</span>
      </div>
      <div class="image-text_36 flex-row justify-between">
        <img
          class="thumbnail_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_5">学习MLLM的API调用方法及其理论基础，理解多模态认知和技术发展的最新趋势。</span>
      </div>
      <div class="image-text_37 flex-row justify-between">
        <img
          class="thumbnail_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_6">
          开发创意工具，包括开源AI创作工具的实现，以及文生图、文生音乐和文生视频工具的开发。
        </span>
      </div>
      <div class="image-text_38 flex-row justify-between">
        <img
          class="thumbnail_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_7">增强现有工具的功能，通过优化和微调提高模型的性能和个性化程度。</span>
      </div>
      <div class="image-text_39 flex-row justify-between">
        <img
          class="thumbnail_5"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_8">
          完成课程后，学生将具备面试所需的技能和知识，为职业发展做好准备。
          <br />
          <br />
        </span>
      </div>
    </div>
    <div class="section_11 flex-col">
      <div class="text-wrapper_15 flex-col"><span class="text_44">B1&nbsp;MLLM工具的使用</span></div>
      <span class="text_45">•&nbsp;应用先导:如何使用PS、剪映等媒体编辑工具</span>
      <span class="text_46">•&nbsp;多模态大模型应用预览</span>
      <span class="text_47">•&nbsp;AI创作内容生成：短视频文案与剧本创作</span>
      <span class="text_48">•&nbsp;AI视觉艺术创作:从文字到图像</span>
      <span class="text_49">•&nbsp;AI音乐生成:用文字谱曲</span>
      <span class="text_50">•&nbsp;文本转视频:AI驱动的视觉故事</span>
      <span class="text_51">•&nbsp;多模态应用整合</span>
    </div>
    <div class="section_12 flex-col">
      <div class="text-wrapper_16 flex-col"><span class="text_52">B2&nbsp;MLLM的API调用及理论基础</span></div>
      <span class="text_53">•&nbsp;多模态认知：MLLM的理论与应用</span>
      <span class="text_54">•&nbsp;MLLM的进化之路：技术发展与创新</span>
      <span class="text_55">•&nbsp;AI图像理解：多模态模型的实际应用</span>
      <span class="text_56">•&nbsp;视觉创作新视角：MLLM在图像生成中的应用</span>
      <span class="text_57">•&nbsp;从开源到创意：视频生成模型解析与应用</span>
      <span class="text_58">•&nbsp;API驱动的创意：AI创作技术实战</span>
    </div>
    <div class="section_13 flex-col">
      <div class="text-wrapper_17 flex-col"><span class="text_59">B3&nbsp;MLLM的工具开发</span></div>
      <span class="text_60">•&nbsp;创意工具开发：开源AI创作的实现</span>
      <span class="text_61">•&nbsp;视觉创作工具：开源文生图开发</span>
      <span class="text_62">•&nbsp;音乐创作新境界：开源文生音乐工具开发</span>
      <span class="text_63">•&nbsp;从文字到影像：开源文生视频工具开发</span>
      <span class="text_64">•&nbsp;多模态整合：开发框架中的MLLM集成</span>
    </div>
    <div class="block_18 flex-col">
      <div class="section_15 flex-col">
        <div class="text-wrapper_18 flex-col"><span class="text_65">B4&nbsp;MLLM的工具增强</span></div>
        <span class="text_66">•&nbsp;图像生成进阶：开源文生图工具优化</span>
        <span class="text_67">•&nbsp;角色定制：文生图模型的个性化微调</span>
        <span class="text_68">•&nbsp;范式优化：统一微调策略</span>
        <span class="text_69">•&nbsp;面试辅导</span>
      </div>
      <div class="text-wrapper_19 flex-col"><span class="text_70">AIGC多模态大模型产品经理</span></div>
    </div>
    <div class="section_16 flex-col">
      <div class="text-wrapper_20 flex-col"><span class="text_71">课程简介</span></div>
      <span class="text_72">
        本课程是为那些希望成为AI时代产品经理的专业人士设计的。课程旨在为学生提供从入门到高级的AI时代产品经理的全面知识体系。通过本课程的学习，学生将能够理解产品经理的角色与职责，掌握产品管理的核心技能，并学会如何利用AI技术加速产品开发流程。
      </span>
      <div class="text-wrapper_21 flex-col"><span class="text_73">课程目标</span></div>
      <div class="image-text_40 flex-row justify-between">
        <img
          class="thumbnail_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_9">理解产品经理在AI时代的核心职责和挑战。</span>
      </div>
      <div class="image-text_41 flex-row justify-between">
        <img
          class="thumbnail_7"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_10">学习如何进行有效的竞品调研和需求分析，以及如何利用AI工具来优化这些过程。</span>
      </div>
      <div class="image-text_42 flex-row justify-between">
        <img
          class="thumbnail_8"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_11">掌握必要的产品工具，如Axure、业务流程图、需求文档和思维导图等。</span>
      </div>
      <div class="image-text_43 flex-row justify-between">
        <img
          class="thumbnail_9"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_12">了解大模型技术的原理和应用，包括提示工程、API调用和最新技术解读。</span>
      </div>
      <div class="image-text_44 flex-row justify-between">
        <img
          class="thumbnail_10"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_13">
          探索多模态技术的应用，包括内容生成、视觉艺术创作、音乐生成等，并理解其背后的技术原理。
        </span>
      </div>
      <div class="image-text_45 flex-row justify-between">
        <img
          class="thumbnail_11"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5ab19eeff30a26fb5ee2884ae7b5620ae99c2a8fde91e0b75ccd848f50dc8cb0.png"
        />
        <span class="text-group_14">
          通过实战项目学习如何将AI技术与传统SaaS产品相结合，并通过案例分析来理解成功和失败的关键因素。
        </span>
      </div>
    </div>
    <div class="block_19 flex-col">
      <div class="group_3 flex-col">
        <div class="text-wrapper_22 flex-col"><span class="text_74">C1&nbsp;入门与基础</span></div>
        <div class="text-group_60 flex-col">
          <span class="text_75">•&nbsp;产品经理的角色与职责</span>
          <span class="text_76">•&nbsp;产品管理概论</span>
          <span class="text_77">•&nbsp;AI时代产品概览</span>
        </div>
      </div>
      <div class="group_4 flex-col">
        <div class="text-wrapper_23 flex-col"><span class="text_78">C2&nbsp;竞品调研/需求分析</span></div>
        <span class="text_79">•&nbsp;竞品调研：学习和超越-“抄作业”</span>
        <span class="text_80">•&nbsp;如何进行高效的产品功能点调研分析</span>
        <span class="text_81">•&nbsp;如何使用AI加速调研流程</span>
        <span class="text_82">•&nbsp;如何使用AI帮你进行需求分析</span>
      </div>
    </div>
    <div class="section_18 flex-col">
      <div class="text-wrapper_24 flex-col"><span class="text_83">C3&nbsp;产品工具学习使用</span></div>
      <span class="text_84">•&nbsp;从零入门Axure</span>
      <span class="text_85">•&nbsp;业务流程图</span>
      <span class="text_86">•&nbsp;需求文档</span>
      <span class="text_87">•&nbsp;思维导图</span>
      <span class="text_88">•&nbsp;AI工具学习使用</span>
    </div>
    <div class="section_19 flex-col">
      <div class="text-wrapper_25 flex-col"><span class="text_89">C4&nbsp;大模型技术通识</span></div>
      <span class="text_90">•&nbsp;大模型提示工程</span>
      <span class="text_91">•&nbsp;大模型的API及Plugins调用</span>
      <span class="text_92">•&nbsp;大模型的发展历程</span>
      <span class="text_93">•&nbsp;GPT4-Turbo与Gemini最新解读</span>
      <span class="text_94">•&nbsp;大模型RAG</span>
      <span class="text_95">•&nbsp;LangChain&nbsp;&amp;&nbsp;Semantic&nbsp;Kernel原理</span>
      <span class="text_96">•&nbsp;AutoGen原理</span>
      <span class="text_97">•&nbsp;大模型思维链</span>
    </div>
    <div class="section_20 flex-col">
      <div class="text-wrapper_26 flex-col"><span class="text_98">C5&nbsp;多模态技术通识</span></div>
      <span class="text_99">•&nbsp;多模态大模型应用总览</span>
      <span class="text_100">•&nbsp;AI创作内容生成</span>
      <span class="text_101">•&nbsp;AI视觉艺术创作</span>
      <span class="text_102">•&nbsp;AI音乐生成</span>
      <span class="text_103">•&nbsp;视觉到听觉</span>
      <span class="text_104">•&nbsp;文本转视频</span>
      <span class="text_105">•&nbsp;多模态认知</span>
      <span class="text_106">•&nbsp;MLLM的进化之路</span>
      <span class="text_107">•&nbsp;AI图像理解</span>
      <span class="text_108">•&nbsp;视觉创作新视角</span>
      <span class="text_109">•&nbsp;从开源到创意</span>
      <span class="text_110">•&nbsp;API驱动的创意</span>
      <span class="text_111">•&nbsp;多模态整合</span>
    </div>
    <div class="section_21 flex-col">
      <div class="text-wrapper_27 flex-col"><span class="text_112">C6&nbsp;实战项目</span></div>
      <span class="text_113">•&nbsp;如何将AI和传统saas产品相结合</span>
      <span class="text_114">•&nbsp;成功案例分析</span>
      <span class="text_115">•&nbsp;失败案例分析</span>
      <span class="text_116">•&nbsp;小组项目：模拟产品开发过程</span>
      <span class="text_117">•&nbsp;课程总结与职业规划建议</span>
      <span class="text_118">•&nbsp;面试辅导</span>
    </div>
    <div class="block_20 flex-col">
      <div class="group_41 flex-row justify-between">
        <div class="text-wrapper_28 flex-col"><span class="text_119">03</span></div>
        <div class="text-wrapper_29">
          <span class="text_120">课程</span>
          <span class="text_121">核心亮点</span>
          <span class="paragraph_8">详解
            <br />
            主力学员越上新台阶
          </span>
        </div>
      </div>
      <div class="group_42 flex-col">
        <div class="box_3 flex-row justify-between">
          <div class="section_23 flex-col"></div>
          <div class="text-group_61 flex-col">
            <div class="text-wrapper_30">
              <span class="text_122">双模式</span>
              <span class="text_123">课堂</span>
            </div>
            <span class="text_124">结合直播与录播课程，提供实时互动和答疑，以及随时随地的学习体验</span>
          </div>
          <span class="text_125">1</span>
        </div>
        <div class="box_4 flex-row justify-between">
          <div class="group_5 flex-col"></div>
          <div class="text-group_62 flex-col">
            <div class="text-wrapper_31">
              <span class="text_126">PBL</span>
              <span class="text_127">地点</span>
            </div>
            <span class="text_128">线上远程/线下（上海、南通、深圳）即将开放北京、广州</span>
          </div>
          <span class="text_129">2</span>
        </div>
        <div class="box_5 flex-row justify-between">
          <div class="group_6 flex-col"></div>
          <div class="text-group_63 flex-col">
            <div class="text-wrapper_32">
              <span class="text_130">线上/线下</span>
              <span class="text_131">自习室</span>
            </div>
            <span class="text_132">提供线上腾讯会议自习室及线下上海总部自习室，均有老师督学和答疑</span>
          </div>
          <span class="text_133">3</span>
        </div>
        <div class="box_6 flex-row justify-between">
          <div class="section_24 flex-col"></div>
          <div class="text-group_64 flex-col">
            <div class="text-wrapper_33">
              <span class="text_134">实战检测</span>
              <span class="text_135">机制</span>
            </div>
            <span class="text_136">通过实际案例作业检测学习效果</span>
          </div>
          <span class="text_137">4</span>
        </div>
        <div class="box_7 flex-row justify-between">
          <div class="block_1 flex-col"></div>
          <div class="text-group_65 flex-col">
            <div class="text-wrapper_34">
              <span class="text_138">全程伴学</span>
              <span class="text_139">服务</span>
            </div>
            <span class="text_140">从入学到学籍期结束提供全程学习辅助</span>
          </div>
          <span class="text_141">5</span>
        </div>
        <div class="box_8 flex-row justify-between">
          <div class="group_7 flex-col"></div>
          <div class="text-group_66 flex-col">
            <div class="text-wrapper_35">
              <span class="text_142">1V1</span>
              <span class="text_143">技术答疑</span>
            </div>
            <span class="text_144">专属学管老师长期跟进学习薄弱点，学习小助手答疑7x24小时AI技术支持</span>
          </div>
          <span class="text_145">6</span>
        </div>
        <div class="box_9 flex-row justify-between">
          <div class="box_10 flex-col"></div>
          <div class="text-group_67 flex-col">
            <div class="text-wrapper_36">
              <span class="text_146">积分奖励</span>
              <span class="text_147">机制</span>
            </div>
            <span class="text_148">通过学习和积极性行为获取积分，可在积分商城兑换</span>
          </div>
          <span class="text_149">7</span>
        </div>
        <div class="box_11 flex-row justify-between">
          <div class="box_12 flex-col"></div>
          <div class="text-group_68 flex-col">
            <div class="text-wrapper_37">
              <span class="text_150">就业面试</span>
              <span class="text_151">保障</span>
            </div>
            <span class="text_152">制定个性化就业方案，匹配简历优化及面试指导，至少5次岗位面试机会</span>
          </div>
          <span class="text_153">8</span>
        </div>
        <div class="box_13 flex-row justify-between">
          <div class="group_8 flex-col"></div>
          <div class="text-group_69 flex-col">
            <div class="text-wrapper_38">
              <span class="text_154">课程升级</span>
              <span class="text_155">策略</span>
            </div>
            <span class="text_156">学员可联系学管老师选择课程升级服务，补缴额外费用即可转入更高级别课程</span>
          </div>
          <span class="text_157">9</span>
        </div>
        <div class="box_14 flex-col">
          <div class="section_54 flex-row justify-between">
            <div class="section_26 flex-col"></div>
            <div class="text-wrapper_39">
              <span class="text_158">微软</span>
              <span class="text_159">证书</span>
            </div>
          </div>
          <div class="text-wrapper_63 flex-row"><span class="text_160">提供考试辅导和陪考服务（需另外收费）</span></div>
          <span class="text_161">10</span>
        </div>
        <div class="box_15 flex-row justify-between">
          <div class="box_16 flex-col"></div>
          <div class="text-group_70 flex-col">
            <div class="text-wrapper_41">
              <span class="text_162">结业</span>
              <span class="text_163">证书</span>
            </div>
            <span class="text_164">完成阶段课程后颁发，由近屿智能JYI颁发，行业认可</span>
          </div>
          <span class="text_165">11</span>
        </div>
      </div>
    </div>
    <div class="section_27 flex-row justify-between">
      <div class="text-wrapper_42 flex-col"><span class="text_166">04</span></div>
      <span class="paragraph_9">
        卓越师资阵容
        <br />
        共享独步秘籍
      </span>
      <div class="box_17 flex-col">
        <div class="box_41 flex-row justify-between">
          <div class="section_28 flex-col"></div>
          <div class="box_42 flex-col">
            <div class="text-wrapper_43 flex-col"><span class="text_167">Aiden</span></div>
            <span class="paragraph_10">
              AIGC大模型实验室&nbsp;主任
              <br />
              哈尔滨工业大学&nbsp;人工智能博士
              <br />
              8年以上NLP研究经验，主要研究方向包括自然语言生成、文本摘
            </span>
          </div>
        </div>
        <span class="text_168">
          要、机器阅读理解；对开放问答评估、对话系统构建、口音识别等具有丰富经验。在AIGC大模型领域有着丰富的研发经验和成果。负责公司自研AIGC&nbsp;HR行业大模型——近屿超脑的设计、增量预训练和微调等工作。能熟练运用各种技术手段构建性能卓越的AI大规模产品。
        </span>
      </div>
    </div>
    <div class="section_30 flex-col">
      <div class="box_43 flex-row justify-between">
        <div class="block_3 flex-col"></div>
        <div class="section_55 flex-col">
          <div class="text-wrapper_44 flex-col"><span class="text_169">张立赛&nbsp;Leon</span></div>
          <span class="paragraph_11">
            近屿智能&nbsp;AIGC大模型讲师&nbsp;
            <br />
            哈工大人工智能博士，曾供职于国内顶级AI实验室-之江实验室，研究方向为文生图、跨模态生成
          </span>
        </div>
      </div>
      <span class="text_170">等。他在CVPR、ACL、MMCIKM等顶级会议上发表过多篇文章。</span>
    </div>
    <div class="section_31 flex-col">
      <div class="section_56 flex-row justify-between">
        <div class="box_20 flex-col"></div>
        <div class="box_44 flex-col">
          <div class="text-wrapper_45 flex-col"><span class="text_171">王巍达&nbsp;Vida</span></div>
          <span class="paragraph_12">
            近屿智能&nbsp;AIGC大模型讲师&nbsp;
            <br />
            哈工大人工智能硕士，有多年的语言模型、智能对话、语义理解、知识图谱、量化策略等领域
          </span>
        </div>
      </div>
      <span class="text_172">
        的研究和实践经验。曾负责过智能问答机器人、百度日文输入法、百度商情、小度智能音箱等产品的算法研发工作。
      </span>
    </div>
    <div class="section_32 flex-col">
      <div class="group_43 flex-row justify-between">
        <div class="block_5 flex-col"></div>
        <div class="box_45 flex-col">
          <div class="text-wrapper_46 flex-col"><span class="text_173">丁晋杰&nbsp;Dino</span></div>
          <span class="text_174">
            墨尔本大学（QS&nbsp;14）人工智能专业硕士，资深大模型研发工程师，主要研究方向为基于大模型的智能体构建，具备丰富的大模
          </span>
        </div>
      </div>
      <span class="text_175">
        型训练、微调、集成、量化经验。目前主要负责包括销售智能体、游戏智能体、AI虚拟人在内的项目研发和大模型相关的理论、应用和开发培训。
      </span>
    </div>
    <div class="section_34 flex-col">
      <div class="block_21 flex-row justify-between">
        <div class="section_35 flex-col"></div>
        <div class="section_57 flex-col">
          <div class="text-wrapper_47 flex-col"><span class="text_176">曹永凯</span></div>
          <span class="text_177">
            毕业于南京邮电大学，具有多年基于深度学习的自然语言处理算法的实战和开发经验。目前主要从事人力资源领域相关的大语言
          </span>
        </div>
      </div>
      <span class="text_178">
        模型应用开发工作，负责完成了基于大模型的简历解析、简历评价、人岗匹配和工作能力评估、基于知识库的海聊和销售助手等重要业务模型训练和搭建。深知业务痛点，对人力资源领域和大模型应用有独到见解。
      </span>
    </div>
    <div class="section_37 flex-col">
      <div class="group_44 flex-row justify-between">
        <div class="group_10 flex-col"></div>
        <div class="group_45 flex-col">
          <div class="text-wrapper_48 flex-col"><span class="text_179">朱文韬&nbsp;Andy</span></div>
          <span class="paragraph_13">
            近屿智能&nbsp;AIGC大模型讲师
            <br />
            985高校研究生
            <br />
            主要研究以大语言模型为基础的类人智能技术和健康医疗大数据
          </span>
        </div>
      </div>
      <span class="text_180">
        挖掘，具备深厚的大模型应用开发经验。参与并完成了包括基于私域大模型的金融对话系统、基于多模态大模型的图像修复与要素抽取系统等重要课题的研发工作。
      </span>
    </div>
    <div class="section_38 flex-col">
      <div class="section_58 flex-row justify-between">
        <div class="group_12 flex-col"></div>
        <div class="group_46 flex-col">
          <div class="text-wrapper_49 flex-col"><span class="text_181">吴蒙&nbsp;Mark</span></div>
          <span class="paragraph_14">
            近屿智能&nbsp;AIGC大模型讲师
            <br />
            美国人工智能top10高校研究生机器学习专业，曾在多家大厂做算法研究实习，主要研究多模态
          </span>
        </div>
      </div>
      <span class="text_182">生成模型，扩散模型等。</span>
    </div>
    <div class="section_39 flex-col">
      <div class="block_22 flex-row justify-between">
        <div class="box_23 flex-col"></div>
        <div class="section_59 flex-col">
          <div class="text-wrapper_50 flex-col"><span class="text_183">方小雷&nbsp;Lucas</span></div>
          <span class="text_184">
            多伦多大学硕士，近屿智能创始人，负责产品的设计思维相关课程的研发，AI得贤招聘官SaaS系统从L2级别到L5级别的产品设计
          </span>
        </div>
      </div>
      <span class="text_185">
        师。南京大学工商管理学士，多伦多大学Rotman商学院MBA。11年跨国地区(中国、德国、西班牙、英国和加拿大)人力资源管理经验。
      </span>
    </div>
    <div class="section_40 flex-row">
      <div class="image-text_46 flex-row justify-between">
        <div class="section_41 flex-col"></div>
        <div class="group_47 flex-col">
          <div class="text-wrapper_51 flex-col"><span class="text_186">代妍&nbsp;Mia</span></div>
          <span class="text-group_26">
            资深猎头顾问，负责A1职场导航暨机遇速递项目的研发，作为职业生涯规划导师为学员推荐国央企和民营企业的工作机会。
          </span>
        </div>
      </div>
    </div>
    <div class="section_43 flex-col">
      <div class="section_60 flex-row justify-between">
        <div class="group_15 flex-col"></div>
        <div class="box_46 flex-col">
          <div class="text-wrapper_52 flex-col"><span class="text_187">Vivian</span></div>
          <span class="paragraph_15">
            世界500强企业HR高管&nbsp;
            <br />
            国内知名大学英语系毕业，从事人力资源管理工作已将近18年。在FMCG、贸易、投资领域的
          </span>
        </div>
      </div>
      <span class="text_188">
        TOP500强公司任职管理职务。领导并参与过多个大型人力资源组织发展改革项目（集团顶层职级体系迭代更新、OKR绩效考核体系导入后先固化再优化、多样化薪酬激励方案、大型建厂前期人员筹备项目等）。积累了丰富的变革管理经验，对于行业发展和人员职业生涯发展有着深刻的认识和见解。
      </span>
    </div>
    <div class="block_23 flex-col">
      <div class="group_48 flex-row justify-between">
        <div class="text-wrapper_53 flex-col"><span class="text_189">05</span></div>
        <div class="text-wrapper_54">
          <span class="text_190">为什么</span>
          <span class="text_191">选择</span>
          <span class="paragraph_16">我们？
            <br />
            我们提供的不只是指导，
            <br />
            更有强大的支持
          </span>
        </div>
      </div>
      <div class="group_49 flex-col">
        <div class="image-text_13 flex-row justify-between">
          <div class="text-group_71 flex-col">
            <div class="text-wrapper_55">
              <span class="text_192">专业</span>
              <span class="text_193">的导师团队</span>
            </div>
            <span class="text_194">行业专家将贴身指导，帮助您攻克项目开发中的难题。</span>
          </div>
          <div class="box_27 flex-col"><div class="group_17 flex-col"></div></div>
        </div>
        <div class="image-text_14 flex-row justify-between">
          <div class="text-group_72 flex-col">
            <div class="text-wrapper_56">
              <span class="text_195">顶尖的</span>
              <span class="text_196">A800</span>
              <span class="text_197">算力支持</span>
            </div>
            <span class="text_198">为您的项目提供必要的技术支撑，让模型训练更加高效。</span>
          </div>
          <div class="box_28 flex-col"><div class="block_9 flex-col"></div></div>
        </div>
        <div class="image-text_15 flex-row justify-between">
          <div class="text-group_73 flex-col">
            <div class="text-wrapper_57">
              <span class="text_199">丰富的</span>
              <span class="text_200">实践</span>
              <span class="text_201">机会</span>
            </div>
            <span class="text_202">真实的业务场景让您的项目不再是空中楼阁，而是接地气的解决方案。</span>
          </div>
          <div class="box_29 flex-col"><div class="block_10 flex-col"></div></div>
        </div>
        <div class="image-text_16 flex-row justify-between">
          <div class="text-group_74 flex-col">
            <div class="text-wrapper_58">
              <span class="text_203">OJAC</span>
              <span class="text_204">标准会员</span>
              <span class="text_205">权益</span>
            </div>
            <span class="text_206">
              我们每个月都会举办Al领域的前沿讲座，分享最新的技术洞见，并提供Al职场的导航与未来的机遇速递。
            </span>
          </div>
          <div class="box_30 flex-col"><div class="section_45 flex-col"></div></div>
        </div>
        <div class="image-text_17 flex-row justify-between">
          <div class="text-group_75 flex-col">
            <div class="text-wrapper_59">
              <span class="text_207">AIGC</span>
              <span class="text_208">原力周末</span>
            </div>
            <span class="text_209">
              这是一个专为大模型与原生产品开发设计的集训营，每周末两天，我们将深入实践，让您在项目中学以致用。
            </span>
          </div>
          <div class="group_18 flex-col"><div class="section_46 flex-col"></div></div>
        </div>
        <div class="image-text_18 flex-row justify-between">
          <div class="text-group_76 flex-col">
            <div class="text-wrapper_60">
              <span class="text_210">OJAC</span>
              <span class="text_211">高级会员</span>
              <span class="text_212">权益</span>
            </div>
            <span class="text_213">
              参与我们的Al大模型探索者计划，我们将共同探索Al的边界，并通过实践案例塑造您的职业星辉路。
            </span>
          </div>
          <div class="box_31 flex-col"><div class="group_19 flex-col"></div></div>
        </div>
      </div>
    </div>
    <div class="section_47 flex-col">
      <div class="group_20 flex-col">
        <div class="image-text_47 flex-col">
          <div class="box_32 flex-col"></div>
          <div class="text-group_33">
            <span class="text_214">现在领取我们的</span>
            <span class="text_215">免费试听</span>
            <span class="paragraph_17">课程，
              <br />
              先人一步，拥抱AIGC大模型！
            </span>
          </div>
        </div>
        <div class="box_33 flex-row">
          <div class="image-text_48 flex-row justify-between">
            <span class="text-group_34">0元领取试听课</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfbfc83476337992a57125f4046124b6b288f8552ac4b80d77a44fbc17205df96.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section_48 flex-col">
      <div class="section_49 flex-col">
        <span class="text_216">行业前景广</span>
        <span class="text_217">
          《中国AIGC应用全景报告》预测，到2026年，中国AI市场规模将达千亿级别。2030年，我国AIGC应用将成为万亿规模市场，2024-2028年平均复合增长率超过30%。（来源：量子位智库）
        </span>
        <div class="group_21 flex-col"></div>
        <span class="text_218">人才需求大</span>
        <span class="text_219">到2030年，中国的人工智能（AI）人才供应只有市场需求的三分之一，人才缺口将达500万。</span>
        <div class="group_22 flex-col"></div>
        <div class="text-group_77 flex-col">
          <span class="text_220">薪资水平高</span>
          <span class="text_221">
            根据猎聘发布报告显示AIGC领域的职位招聘年薪平均超40万。在不同的年薪段中，10-20K的职位占了30%，20-50K的职位占了50%，AI行业的薪资随着经验的积累增长非常快。
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: '',
      muted: false,
      muted2: false,
      viewingUrl: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/l5introduce.mp4',
      loading: true,
    }
  },
  computed: {
    playerOptions() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: 'https://aimianshiguan.oss-accelerate.aliyuncs.com/resume/90911920808274414.mp4', // url地址
          },
        ],
        poster: require('@/views/lanhai_h5/assets/img/video-p-2.png'), // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
  },
  created() {
    setTimeout(() => {
      this.loading = false
    }, 800)
  },
  methods: {
    
    unmute2(e) {
      e.stopPropagation()
      const player = this.$refs.videoPlayer2
      player.muted = this.muted2
      this.muted2 = !this.muted2
    },
  }
};
</script>
<style scoped lang="less" src="./assets/index.rem.less" />