<template>
  <div id="app">
    <router-view/>
    <div class="copy-right">
      <div>
        Copyright © 上海近屿智能科技有限公司 Shanghai Jinyu Intelligent Technology Co.，Ltd.
      </div>
      <div>
        <img
          src="~@/views/lanhai/assets/images/national-emblem.png"
          style="margin: 0 10px"
        />
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
          沪ICP备19028147号</a
        >
        <span class="dot">·</span>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008003"
          target="_blank"
          >沪公网安备 31010402008003号</a
        >
      </div>
    </div>
    <Slide v-if="this.$route.name != 'Aboutus'" />

  </div>
</template>
<script>
import Slide from './../src/views/lanhai/components/slide.vue'

export default {
  components: { Slide },
}
</script>

<style lang="less" src="./assets/common.less">
</style>

<style lang="less">

  .copy-right {
    min-width: 1280px;
    display: flex;
    justify-content: center;
    margin: 20px;
    font-size: 12px;
    color: #666;
    > div {
      display: flex;
      align-items: center;
    }
    .dot {
      padding: 0 5px;
    }
    a {
      color: #666;
      display: flex;
      align-items: center;
      &:active {
        color: #666;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>