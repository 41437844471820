<template>
  <div class="customer-wrapper">
    <div
      v-for="(item, index) in imgList"
      :id="'scrollWrapId' + (index + 1)"
      :key="index"
      class="scroll-wrap"
    >
      <ul>
        <li
          v-for="(childItem, childIndex) in [...item,...item,...item,...item,...item,...item,...item,...item,...item,...item,...item]"
          :key="childIndex"
          class="item-box"
        >
          <img :src="childItem" height="60px" width="200px" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgList: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  data() {
    return {
      phone: '',
    }
  },
  mounted() {
    const scrollWrapDiv1 = document.getElementById("scrollWrapId1");
    const scrollWrapDiv2 = document.getElementById("scrollWrapId2");
    scrollWrapDiv2.style.left = -150 + 'px'
    scrollWrapDiv2.style.marginRight = -150 + 'px'
    this.scrollFun(scrollWrapDiv1);
    this.scrollFun(scrollWrapDiv2);
  },
  methods: {
    scrollFun(divDocument) {
      // 指定滚动
      const oUl = divDocument.getElementsByTagName("ul")[0];
      const aLi = oUl.getElementsByTagName("li");
      const iSpeed = 1; //  正左负右
      //  计算ul的宽为所有li的宽的和;
      oUl.innerHTML += oUl.innerHTML + oUl.innerHTML;
      oUl.style.width = aLi[0].offsetWidth * aLi.length / 2 + "px";
      function Slider() {
        if (oUl.offsetLeft < -oUl.offsetWidth / 2) {
          // #14600 测试要求不要有重新滑动
          oUl.style.left = 0;
        } else if (oUl.offsetLeft > 0) {
          oUl.style.left = -oUl.offsetWidth / 2 + "px";
        }
        oUl.style.left = oUl.offsetLeft - iSpeed + "px"; // 正负为方向
      }
      setInterval(Slider, 30);
    },
  },
}
</script>
<style lang="less" scoped>
.customer-wrapper {
  margin-top: 30px;
  .scroll-wrap {
    position: relative;
    margin: 0px auto 0;
    height: 190px;
    overflow: hidden;
    ul {
      position: absolute;
      left: 0;
      li {
        margin-top: 30px;
        list-style: none;
        float: left;
        margin-right: 39px;
        width: 200px;
        height: 60px;
        background: #ffffff;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0px 5px 10px 0px rgba(196, 204, 223, 0.44);
        display: flex;
        img {
          flex: 1;
          width: 100%;
          height: 100%;
        }
      }
      >li:first-child{
        margin-left: 40px;
      }
    }
  }
}
</style>