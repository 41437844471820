<template>
  <div class="aigc-main">
    <navpage class="nav" :isdefault="true"></navpage>

    <div class="banner-page">
      <div class="image">
        <img :src="require('./../assets/aigcImg/aigc3/bannerImage3.png')" class="image1" alt="Image Asset 3" />
        <p class="text">
          AI赋能产品创新，
          <br />成为引领大模型多模态时代的AIGC产品经理
        </p>
        <div class="wrap" @click="yuyueDialogVisible = true">
          <img :src="require('./../assets/aigcImg/kc0-Image6.png')" class="image2" alt="Image Asset 6" /><img
            :src="require('./../assets/aigcImg/kc0-Image7.png')" class="image3" alt="Image Asset 7" />
        </div>
      </div>
    </div>

    <div class="kecheng-page">
      <div class="kecheng-title">
        <p>
          培养一个新兴的职业角色AI产品经理，专注于利用人工智能生成内容（AI Generated Content）的技术，来设计、优化和推广产品。这个角色结合了AI技术、产品管理和多模态内容生成的专业知识，旨在将AI能力完美地融入公司现有业务中，以满足用户在娱乐、教育、资讯等方面的需求。
        </p>
      </div>

      <div class="kecheng-content">
        <div class="kecheng0-page">
          <div class="wrap">
            <div class="container1">
              <div class="content3">
                <div class="main4">
                  <div class="section3">
                    <img
                      :src=" require('./../assets/aigcImg/aigc3/kc0-Image1.png')"
                      class="image19"
                      alt="Image Asset 1"
                    />
                    <p class="text19">C1&nbsp;大模型技术通识</p>
                  </div>
                </div>
                <div class="main5">
                  <div class="section4">
                    <div class="subSection17">
                      <div class="block17">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image20"
                          alt="Image Asset 2"
                        />
                        <p class="text20">Python提示工程之基础</p>
                      </div>
                    </div>
                    <div class="subSection18">
                      <div class="block18">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image21"
                          alt="Image Asset 3"
                        />
                        <p class="text21">大模型提示工程</p>
                      </div>
                    </div>
                    <div class="subSection19">
                      <div class="block19">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image22"
                          alt="Image Asset 4"
                        />
                        <p class="text22">大模型的API及Plugins调用</p>
                      </div>
                    </div>
                    <div class="subSection20">
                      <div class="block20">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image23"
                          alt="Image Asset 5"
                        />
                        <p class="text23">大模型的发展历程（录播）</p>
                      </div>
                    </div>
                    <div class="subSection21">
                      <div class="block21">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image24"
                          alt="Image Asset 6"
                        />
                        <p class="text24">GPT4-Turbo与Gemini最新解读（录播）</p>
                      </div>
                    </div>
                    <div class="subSection22">
                      <div class="block22">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image25"
                          alt="Image Asset 7"
                        />
                        <p class="text25">大模型RAG</p>
                      </div>
                    </div>
                    <div class="subSection23">
                      <div class="block23">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image26"
                          alt="Image Asset 8"
                        />
                        <p class="text26">LangChain&amp;Semantic&nbsp;Kernel原理</p>
                      </div>
                    </div>
                    <div class="subSection24">
                      <div class="block24">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image27"
                          alt="Image Asset 9"
                        />
                        <p class="text27">LangChain&amp;Semantic&nbsp;Kernel实战</p>
                      </div>
                    </div>
                    <div class="subSection25">
                      <div class="block25">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image28"
                          alt="Image Asset 10"
                        />
                        <p class="text28">AutoGen原理</p>
                      </div>
                    </div>
                    <div class="subSection26">
                      <div class="block26">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image2.png')"
                          class="image29"
                          alt="Image Asset 11"
                        />
                        <p class="text29">大模型思维链</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content2">
                <div class="main2">
                  <div class="section1">
                    <img
                      :src=" require('./../assets/aigcImg/aigc3/kc0-Image12.png')"
                      class="image4"
                      alt="Image Asset 12"
                    />
                    <p class="text4">C2&nbsp;多模态技术通识</p>
                  </div>
                </div>
                <div class="main3">
                  <div class="section2">
                    <div class="subSection3">
                      <div class="block3">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image5"
                          alt="Image Asset 13"
                        />
                        <p class="text5">多模态大模型应用总览</p>
                      </div>
                    </div>
                    <div class="subSection4">
                      <div class="block4">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image6"
                          alt="Image Asset 14"
                        />
                        <p class="text6">
                          <span class="text6__seg0">AI创作内容生成：</span
                          >短视频文案与剧本创作
                        </p>
                      </div>
                    </div>
                    <div class="subSection5">
                      <div class="block5">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image7"
                          alt="Image Asset 15"
                        />
                        <p class="text7">AI视觉艺术创作（一）</p>
                      </div>
                    </div>
                    <div class="subSection6">
                      <div class="block6">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image8"
                          alt="Image Asset 16"
                        />
                        <p class="text8">AI视觉艺术创作（二）</p>
                      </div>
                    </div>
                    <div class="subSection7">
                      <div class="block7">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image9"
                          alt="Image Asset 17"
                        />
                        <p class="text9">AI音乐生成</p>
                      </div>
                    </div>
                    <div class="subSection8">
                      <div class="block8">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image10"
                          alt="Image Asset 18"
                        />
                        <p class="text10">文本转视频</p>
                      </div>
                    </div>
                    <div class="subSection9">
                      <div class="block9">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image11"
                          alt="Image Asset 19"
                        />
                        <p class="text11">多模态认知</p>
                      </div>
                    </div>
                    <div class="subSection10">
                      <div class="block10">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image12"
                          alt="Image Asset 20"
                        />
                        <p class="text12">MLLM的进化之路</p>
                      </div>
                    </div>
                    <div class="subSection11">
                      <div class="block11">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image13"
                          alt="Image Asset 21"
                        />
                        <p class="text13">AI图像理解</p>
                      </div>
                    </div>
                    <div class="subSection12">
                      <div class="block12">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image14"
                          alt="Image Asset 22"
                        />
                        <p class="text14">视觉创作新视角（一）</p>
                      </div>
                    </div>
                    <div class="subSection13">
                      <div class="block13">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image15"
                          alt="Image Asset 23"
                        />
                        <p class="text15">视觉创作新视角（二）</p>
                      </div>
                    </div>
                    <div class="subSection14">
                      <div class="block14">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image16"
                          alt="Image Asset 24"
                        />
                        <p class="text16">从开源到创意</p>
                      </div>
                    </div>
                    <div class="subSection15">
                      <div class="block15">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image17"
                          alt="Image Asset 25"
                        />
                        <p class="text17">API驱动的创意</p>
                      </div>
                    </div>
                    <div class="subSection16">
                      <div class="block16">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image13.png')"
                          class="image18"
                          alt="Image Asset 26"
                        />
                        <p class="text18">多模态整合</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="content">
                <div class="main">
                  <img
                    :src=" require('./../assets/aigcImg/aigc3/kc0-Image27.png')"
                    class="image"
                    alt="Image Asset 27"
                  />
                  <p class="text">C6&nbsp;实战项目</p>
                </div>
              </div>
              <div class="content1">
                <div class="main1">
                  <div class="section">
                    <div class="subSection">
                      <div class="block">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image28.png')"
                          class="image1"
                          alt="Image Asset 28"
                        />
                        <p class="text1">
                          <span class="text1__seg0">无限可能：</span>万物皆可AI赋能
                        </p>
                      </div>
                    </div>
                    <div class="subSection1">
                      <div class="block1">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image28.png')"
                          class="image2"
                          alt="Image Asset 29"
                        />
                        <p class="text2">实战项目如何做？以AI&nbsp;CRM为例</p>
                      </div>
                    </div>
                    <div class="subSection2">
                      <div class="block2">
                        <img
                          :src=" require('./../assets/aigcImg/aigc3/kc0-Image28.png')"
                          class="image3"
                          alt="Image Asset 30"
                        />
                        <p class="text3">面试辅导</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap1">
            <div class="container2">
              <div class="content5">
                <div class="main7">
                  <img
                    :src=" require('./../assets/aigcImg/aigc3/kc0-Image31.png')"
                    class="image33"
                    alt="Image Asset 31"
                  />
                  <p class="text33">C3&nbsp;入门与基础</p>
                </div>
              </div>
              <div class="content4">
                <div class="main6">
                  <div class="section5">
                    <div class="subSection27">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image32.png')"
                        class="image30"
                        alt="Image Asset 32"
                      />
                      <p class="text30">产品经理的角色与职责</p>
                    </div>
                  </div>
                  <div class="section6">
                    <div class="subSection28">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image32.png')"
                        class="image31"
                        alt="Image Asset 33"
                      />
                      <p class="text31">产品管理概论</p>
                    </div>
                  </div>
                  <div class="section7">
                    <div class="subSection29">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image32.png')"
                        class="image32"
                        alt="Image Asset 34"
                      />
                      <p class="text32">AI时代产品概览</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container3">
              <div class="content6">
                <div class="main8">
                  <img
                    :src=" require('./../assets/aigcImg/aigc3/kc0-Image35.png')"
                    class="image34"
                    alt="Image Asset 35"
                  />
                  <p class="text34">C4&nbsp;竞品调研/需求分析</p>
                </div>
              </div>
              <div class="content7">
                <div class="main9">
                  <div class="section8">
                    <div class="subSection30">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image36.png')"
                        class="image35"
                        alt="Image Asset 36"
                      />
                      <p class="text35">洞察市场收集需求</p>
                    </div>
                  </div>
                  <div class="section9">
                    <div class="subSection31">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image36.png')"
                        class="image36"
                        alt="Image Asset 37"
                      />
                      <p class="text36">识别需求真伪 规划产品方向</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container4">
              <div class="content8">
                <div class="main10">
                  <img
                    :src=" require('./../assets/aigcImg/aigc3/kc0-Image38.png')"
                    class="image37"
                    alt="Image Asset 38"
                  />
                  <p class="text37">C5&nbsp;产品工具学习使用</p>
                </div>
              </div>
              <div class="content9">
                <div class="main11">
                  <div class="section10">
                    <div class="subSection32">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image39.png')"
                        class="image38"
                        alt="Image Asset 39"
                      />
                      <p class="text38">从零入门Axure</p>
                    </div>
                  </div>
                  <div class="section11">
                    <div class="subSection33">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image39.png')"
                        class="image39"
                        alt="Image Asset 40"
                      />
                      <p class="text39">业务流程图</p>
                    </div>
                  </div>
                  <div class="section12">
                    <div class="subSection34">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image39.png')"
                        class="image40"
                        alt="Image Asset 41"
                      />
                      <p class="text40">思维导图</p>
                    </div>
                  </div>
                  <div class="section13">
                    <div class="subSection35">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image39.png')"
                        class="image41"
                        alt="Image Asset 42"
                      />
                      <p class="text41">初识需求文档</p>
                    </div>
                  </div>
                  <div class="section14">
                    <div class="subSection36">
                      <img
                        :src=" require('./../assets/aigcImg/aigc3/kc0-Image39.png')"
                        class="image42"
                        alt="Image Asset 43"
                      />
                      <p class="text42">AI工具学习使用</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="kecheng1-page">
          <div class="image">
          <p class="text">
            涵盖从基础入门到实战项目，包括产品管理、竞品分析、AI工具应用、大模型技术原理及其在多模态领域的创新应用，
            <br />旨在培养具备AI时代产品管理能力的专家。
          </p>
          <div class="wrap">
            <div class="container">
              <img
                :src="require('./../assets/aigcImg/aigc3/kc1-Image6.png')"
                class="image1"
                alt="Image Asset 6"
              />
              <p class="text1">
                12节专业课程，
                <br />为AI产品经理转行打造
                <br />入门到精通的一站式学习体验
              </p>
            </div>
            <div class="container1">
              <p class="text2">
                深入学习<span style="color: #1aff00;">50+</span>顶尖AIGC应用，
                <br />并通过<span style="color: #1aff00;">50+</span>案例分析，
                <br />提升AI产品开发效率和实战能力
              </p>
              <img
                :src="require('./../assets/aigcImg/aigc3/kc1-Image10.png')"
                class="image2"
                alt="Image Asset 10"
              />
            </div>
            <div class="container2">
              <p class="text3">
                从产品基础必学课程到
                <br />多模态及大模型技术通识，
                <br />全方位打造职业竞争力
              </p>
              <img
                :src="require('./../assets/aigcImg/aigc3/kc1-Image13.png')"
                class="image3"
                alt="Image Asset 13"
              />
            </div>
          </div>
        </div>
        </div>

        <div class="kecheng2-page">
          <p class="text"><span class="blue">实战</span>项目</p>
          <div class="image">
            <div class="wrap1">
              <p class="text8">以AI赋能的销售代理（Sales Agent）为例。</p>
              <p class="text9">
                我们将从传统销售系统出发，探讨其主要功能及存在的痛点。
                引入大模型后，我们可以针对上述痛点提出新的功能需求，如右图所示：
              </p>
              <img
                :src="require('./../assets/aigcImg/aigc3/kc2-Image7.png')"
                class="image1"
                alt="Image Asset 7"
              />
            </div>
            <div class="wrap">
              <div class="container">
                <div class="content">
                  <div class="main">
                    <p class="text1">精准的用户画像构建：</p>
                    <p class="text2">
                      通过大模型，能够更准确地分析用户需求，形成详细的用户画像。
                    </p>
                  </div>
                  <div class="main1"></div>
                </div>
                <div class="content1">
                  <div class="main2">
                    <p class="text3">动态定价策略：</p>
                    <p class="text4">
                      利用实时数据分析，动态调整产品定价，以提高竞争力。
                    </p>
                  </div>
                  <div class="main3"></div>
                </div>
                <div class="content2">
                  <div class="main4">
                    <p class="text5">个性化营销建议：</p>
                    <p class="text6">
                      大模型可以根据用户行为和偏好，提供量身定制的营销策略。
                    </p>
                  </div>
                  <div class="main5"></div>
                </div>
              </div>
              <p class="text7">我们将从中选择具体的功能点进行深入拓展。</p>
            </div>
          </div>

        </div>

        <div class="kecheng3-page">
          <p class="text"><span>就业</span>方向</p>
          <div class="wrap">
            <div class="image6"><p class="text4">AI产品经理目前分为三大类型</p></div>
            <div class="container">
              <div class="image">
                <img
                  :src="require('./../assets/aigcImg/aigc3/kc3-Image8.png')"
                  class="image1"
                  alt="Image Asset 8"
                />
                <p class="text1">
                  第一类是负责传统产品中 AI 功能设计的产品经理。（市场主要人才需求）
                </p>
              </div>
              <div class="image2">
                <img
                  :src="require('./../assets/aigcImg/aigc3/kc3-Image11.png')"
                  class="image3"
                  alt="Image Asset 11"
                />
                <p class="text2">第二类是<br/>专门负责数据生产的产品经理。</p>
              </div>
              <div class="image4">
                <img
                  :src="require('./../assets/aigcImg/aigc3/kc3-Image14.png')"
                  class="image5"
                  alt="Image Asset 14"
                />
                <p class="text3">
                  第三类是专职于 AI native 产品的产品经理，<br/>特别是注重生成式 AI 效果的
                  PM。（目前不多）
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="kecheng4-page">
          <p class="kecheng4-title"><span class="blue">就业</span>案例</p>
          <div class="kecheng4-wrap">
            <el-carousel :interval="3000" arrow="never" height="450px">
              <el-carousel-item v-for="(item, index) in caseItem" :key="index">
                <div class="kecheng4-container">
                  <div class="kecheng4-image" :style="'backgroundImage: url(' + item.img + ')'">
                    <div class="kecheng4-content">
                      <div class="kecheng4-main">
                        <div class="kecheng4-section">
                          <img :src="item.head" class="kecheng4-image1" alt="Image Asset 9" />
                          <p class="kecheng4-text1">{{ item.name }}</p>
                        </div>
                        <div v-if="item.label" v-html="item.label" class="kecheng4-section2">
                        </div>
                        <div class="kecheng4-section1">
                          <p class="kecheng4-text2" v-html="item.desc"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </el-carousel-item>
            </el-carousel>

          </div>
        </div>
      </div>
    </div>


    <el-dialog title="" width="480px" top="30vh" custom-class="yuyueDialog" :visible.sync="yuyueDialogVisible">
      <div class="yuyue-page">
        <div class="yuyue-wrap">
          <img :src="require('./../assets/images/yuyueImage3.png')" class="yuyue-image" alt="Image Asset 3"
            @click="yuyueDialogVisible = false" />
          <p class="yuyue-text"><span>立即扫码</span>预约今晚讲座</p>
        </div>
        <img :src="require('./../assets/images/yuyueImage5.png')" class="yuyue-image1" alt="Image Asset 5" />
      </div>
    </el-dialog>




  </div>
</template>
<script>
import navpage from './../components/nav.vue'

export default {
  components: { navpage },
  data() {
    return {
      cur: 1,
      szActive: 1,
      subSectionNum: 1,
      yuyueDialogVisible: false,
      caseItem: [
        {
          name: '陈同学-软件工程本科-4年工作经验',
          head: require('./../assets/aigcImg/aigc3/kc4-head1.png'),
          img: require('./../assets/aigcImg/aigc3/kc4-bg1.png'),
          label: `
            <span>• 学员：陈同学</span>
            <span>• 毕业院校：上海某二本大学</span>
            <span>• 专业：软件工程</span>
            <span style="margin: 0;">• 学历：本科</span>
            <br />
            <span>• 学生属性：20年毕业</span>
            <span>• 就业单位：安徽合肥某教育科技公司</span>
            <span style="margin: 0;">• 职位：教育BG-产品经理（AIGC）</span>
          `,
          desc: `
              陈同学毕业于上海某二本大学的软件工程专业，今年27岁，2020年毕业。上一份工作是产品经理，做了3年，薪资20万左右。然而，在实际工作中，陈同学遇到了职业发展的瓶颈，他意识到仅凭现有的知识已经难以应对行业的快速变化。
              <br /><br />为了寻求新的突破，2024年6月，陈同学加入了近屿智能的AIGC多模态大模型产品经理课程。通过系统的学习与深入的项目实践，他不仅掌握了AIGC领域的前沿技术，还学会了如何将AI技术应用到实际产品中。学习期间，他专注于提升自己的产品规划、设计和实现能力，参与了多个产品原型设计项目。
              <br /><br />在学管团队的悉心指导下，陈同学的努力很快得到了回报。2024年9月，陈同学成功入职安徽合肥某教育科技公司，担任教育产品经理（AIGC方向），年薪也提升至29万。他目前主要负责产品规划、原型设计及实现，保障相关教育AIGC产品的落地实施。在此，向陈同学表示热烈祝贺，并期待他未来在AI产品领域中取得更大成就！            `,
        },
        {
          name: '杨同学-计算机硕士-应届生',
          head: require('./../assets/aigcImg/aigc3/kc4-head2.png'),
          img: require('./../assets/aigcImg/aigc3/kc4-bg2.png'),
          desc: `
              杨同学，25岁，北京某理工科大学的计算机相关专业硕士毕业生。作为一名应届毕业生，他面临着激烈的就业竞争和不确定性。2024年6月，杨同学加入了近屿智能的AIGC多模态大模型产品经理课程，希望通过学习AIGC技术来提升自己的就业竞争力。
              <br /><br />在三个月的学习过程中，杨同学展现出了极强的学习能力和适应能力。他不仅快速掌握了AIGC产品经理所需的专业知识，还通过课程中的实战项目积累了宝贵的产品管理经验。2024年9月，他成功被北京一家互联网公司录用为AI产品经理，月薪15K，为自己的职业生涯开启了一个崭新的篇章。`
        },
        {
          name: '谢同学-新闻专业本科-工作7年',
          head: require('./../assets/aigcImg/aigc3/kc4-head3.png'),
          img: require('./../assets/aigcImg/aigc3/kc4-bg3.png'),
          desc: `谢同学，31岁，毕业于西安某大学新闻专业。作为一名资深产品经理，他对于产品创新和市场趋势有着敏锐的洞察力。2024年5月，谢同学加入了近屿智能的AIGC多模态大模型产品经理课程，希望能够通过学习AIGC技术来提升自己的产品管理能力。
              <br /><br />在三个月的学习过程中，谢同学不仅系统地学习了AIGC产品经理的专业知识，还通过实战项目锻炼了自己的产品策划和市场分析能力。2024年8月，他成功被深圳一家科技企业录用为AI产品经理，月薪23K，实现了从传统产品经理到AI产品经理的顺利过渡。`
        },
        {
          name: '唐同学-产品经理-工作9年',
          head: require('./../assets/aigcImg/aigc3/kc4-head4.png'),
          img: require('./../assets/aigcImg/aigc3/kc4-bg4.png'),
          desc: `唐同学，32岁，毕业于上海某综合性大学。作为一名经验丰富的产品经理，他一直在寻找能够将产品管理经验与新兴技术结合的机会。2024年6月，唐同学加入了近屿智能的AIGC多模态大模型产品经理课程，希望能够通过学习AIGC技术来提升自己的产品创新能力。
              <br /><br />学习期间，唐同学将产品管理经验与AIGC技术相结合，创造性的提出了很多新的关于产品设计的想法。2024年9月，他成功被杭州一家科技公司录用为AI产品经理，月薪20K，不仅拓宽了自己的职业道路，也为这家科技公司带去了新的思维和方法。`
        },
      ]
    };
  },
  mounted() {
    document.title = "AIGC大模型培训";
  },
  methods: {
    openBaidu() {
      const aff = document.getElementById("aff-im-root");
      const btn = aff.getElementsByClassName("embed-icon-pcIcon3")[0];
      btn.click();
    },
    handleClick(type) {
      if (type && type === "openBaidu") {
        this.openBaidu();
        // this[type]()
      }
    },
  },
};
</script>
<style lang="less" scoped>
.blue {
  color: #1F6AFB;
}

.aigc-main {
  max-width: 100%;
  min-width: 1280px;

  .banner-page {
    width: 100%;
    height: 600px;
    overflow: hidden;
    // padding-top: 80px;
    background: rgba(244, 247, 252, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 1920px;
      height: 600px;
      box-sizing: border-box;
      background-image: url('./../assets/aigcImg/aigc3/bannerImage2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .image1 {
        height: 58.37px;
        margin-top: 172.67px;
      }

      .text {
        margin-top: 45.96px;
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(51, 51, 51, 1);
        font-weight: 500;
        white-space: pre-line;
      }

      .wrap {
        width: 179px;
        height: 48px;
        border-radius: 61px;
        box-sizing: border-box;
        background: rgba(31, 106, 251, 1);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        cursor: pointer;

        .image2 {
          width: 105.01px;
          height: 16.63px;
          margin-right: 8px;
        }

        .image3 {
          width: 16px;
          height: 16px;
          overflow: hidden;
          box-sizing: border-box;
        }
      }
    }
  }



  .kecheng-page {
    margin-top: -120px;
    border-radius: 16px;
    box-sizing: border-box;
    padding-top: 20px;
    background-color: #F4F7FC;
    .kecheng-title {
      margin: 0 auto 40px;
      width: 1280px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 1280px;
      height: 148px;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;
      background: rgb(255, 255, 255);
      position: relative;


      p {
        margin-left: 39.53px;
        margin-bottom: 19.26px;
        width: 1200px;
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 33.60px;
        font-weight: 500;
      }
    }

    .kecheng-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      >img {
        margin: 20px auto;
        width: 1280px;
      }

      .kecheng0-page {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 1280.33px;
        height: 772px;
        .wrap {
          width: 847px;
          height: 772px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .container1 {
            width: 846px;
            height: 538px;
            margin-left: 1px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .content3 {
              width: 413px;
              height: 538px;
              overflow: hidden;
              border-radius: 16px;
              border-color: rgba(255,255,255,1);
              border-style: solid;
              border-width: 2px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(239,245,255,1) 0%,rgba(255,255,255,1) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              .main4 {
                width: 248px;
                height: 34px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                gap: 12px;
                margin-top: 40px;
                margin-left: 40px;
                .section3 {
                  width: 248px;
                  height: 34px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .image19 {
                    width: 32px;
                    height: 32px;
                    overflow: hidden;
                    border-radius: 28px;
                    box-sizing: border-box;
                    background: rgba(34,107,237,1);
                  }
                  .text19 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .main5 {
                width: 334px;
                height: 290px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                margin-left: 40px;
                .section4 {
                  width: 334px;
                  height: 290px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  .subSection17 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    .block17 {
                      width: 155px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image20 {
                        width: 6px;
                        height: 6px;
                      }
                      .text20 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection18 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block18 {
                      width: 110px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image21 {
                        width: 6px;
                        height: 6px;
                      }
                      .text21 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection19 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block19 {
                      width: 179px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image22 {
                        width: 6px;
                        height: 6px;
                      }
                      .text22 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection20 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block20 {
                      width: 180px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image23 {
                        width: 6px;
                        height: 6px;
                      }
                      .text23 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection21 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block21 {
                      width: 267px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image24 {
                        width: 6px;
                        height: 6px;
                      }
                      .text24 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection22 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block22 {
                      width: 84px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image25 {
                        width: 6px;
                        height: 6px;
                      }
                      .text25 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection23 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block23 {
                      width: 227px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image26 {
                        width: 6px;
                        height: 6px;
                      }
                      .text26 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection24 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block24 {
                      width: 227px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image27 {
                        width: 6px;
                        height: 6px;
                      }
                      .text27 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection25 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block25 {
                      width: 97px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image28 {
                        width: 6px;
                        height: 6px;
                      }
                      .text28 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection26 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block26 {
                      width: 96px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image29 {
                        width: 6px;
                        height: 6px;
                      }
                      .text29 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }
            .content2 {
              width: 413px;
              height: 538px;
              overflow: hidden;
              border-radius: 16px;
              border-color: rgba(255,255,255,1);
              border-style: solid;
              border-width: 2px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(237,247,255,1) 0%,rgba(255,255,255,1) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              .main2 {
                width: 252px;
                height: 34px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                gap: 12px;
                margin-top: 40px;
                margin-left: 40px;
                .section1 {
                  width: 252px;
                  height: 34px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .image4 {
                    width: 32px;
                    height: 32px;
                    overflow: hidden;
                    border-radius: 39px;
                    box-sizing: border-box;
                    background: rgba(79,160,225,1);
                  }
                  .text4 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .main3 {
                width: 334px;
                height: 410px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                margin-left: 40px;
                .section2 {
                  width: 334px;
                  height: 410px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  .subSection3 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    .block3 {
                      width: 152px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image5 {
                        width: 6px;
                        height: 6px;
                      }
                      .text5 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection4 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block4 {
                      width: 263px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image6 {
                        width: 6px;
                        height: 6px;
                      }
                      .text6 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(102,102,102,1);
                        line-height: 19.60px;
                        font-weight: 400;
                        .text6__seg0 {
                          color: rgba(0,0,0,1);
                        }
                      }
                    }
                  }
                  .subSection5 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block5 {
                      width: 151px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image7 {
                        width: 6px;
                        height: 6px;
                      }
                      .text7 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection6 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block6 {
                      width: 151px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image8 {
                        width: 6px;
                        height: 6px;
                      }
                      .text8 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection7 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block7 {
                      width: 81px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image9 {
                        width: 6px;
                        height: 6px;
                      }
                      .text9 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection8 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block8 {
                      width: 82px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image10 {
                        width: 6px;
                        height: 6px;
                      }
                      .text10 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection9 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block9 {
                      width: 82px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image11 {
                        width: 6px;
                        height: 6px;
                      }
                      .text11 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection10 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block10 {
                      width: 124px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image12 {
                        width: 6px;
                        height: 6px;
                      }
                      .text12 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection11 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block11 {
                      width: 81px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image13 {
                        width: 6px;
                        height: 6px;
                      }
                      .text13 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection12 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block12 {
                      width: 152px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image14 {
                        width: 6px;
                        height: 6px;
                      }
                      .text14 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection13 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block13 {
                      width: 152px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image15 {
                        width: 6px;
                        height: 6px;
                      }
                      .text15 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection14 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block14 {
                      width: 96px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image16 {
                        width: 6px;
                        height: 6px;
                      }
                      .text16 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection15 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block15 {
                      width: 104px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image17 {
                        width: 6px;
                        height: 6px;
                      }
                      .text17 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection16 {
                    width: 334px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 10px;
                    .block16 {
                      width: 82px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image18 {
                        width: 6px;
                        height: 6px;
                      }
                      .text18 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }
          }
          .container {
            width: 847px;
            height: 214px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(255,238,238,1) 0%,rgba(255,255,255,1) 100%);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .content {
              width: 180px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 12px;
              margin-top: 40px;
              margin-left: 40px;
              .main {
                width: 180px;
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .image {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;
                  border-radius: 39px;
                  box-sizing: border-box;
                  background: rgba(244,43,47,1);
                }
                .text {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(0,0,0,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }
            }
            .content1 {
              width: 768px;
              height: 20px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 10px;
              margin-top: 20px;
              margin-left: 40px;
              .main1 {
                width: 768px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                .section {
                  width: 768px;
                  height: 20px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .subSection {
                    width: 256px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    .block {
                      width: 179px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image1 {
                        width: 6px;
                        height: 6px;
                      }
                      .text1 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(102,102,102,1);
                        line-height: 19.60px;
                        font-weight: 400;
                        .text1__seg0 {
                          color: rgba(0,0,0,1);
                        }
                      }
                    }
                  }
                  .subSection1 {
                    width: 256px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    .block1 {
                      width: 216px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image2 {
                        width: 6px;
                        height: 6px;
                      }
                      .text2 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                  .subSection2 {
                    width: 256px;
                    height: 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    .block2 {
                      width: 68px;
                      height: 20px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .image3 {
                        width: 6px;
                        height: 6px;
                      }
                      .text3 {
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        white-space: nowrap;
                        color: rgba(0,0,0,1);
                        line-height: 19.60px;
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .wrap1 {
          width: 413.33px;
          height: 772px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .container2 {
            width: 413.33px;
            height: 214px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(255,247,240,1) 0%,rgba(255,255,255,1) 100%);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .content5 {
              width: 202.26px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 12px;
              margin-top: 40px;
              margin-left: 40px;
              .main7 {
                width: 204px;
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .image33 {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;
                  border-radius: 39px;
                  box-sizing: border-box;
                  background: rgba(247,128,37,1);
                }
                .text33 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(0,0,0,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }
            }
            .content4 {
              width: 334px;
              height: 80px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 10px;
              margin-top: 20px;
              margin-left: 40px;
              .main6 {
                width: 334px;
                height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .section5 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  .subSection27 {
                    width: 152px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image30 {
                      width: 6px;
                      height: 6px;
                    }
                    .text30 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section6 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  margin-top: 10px;
                  .subSection28 {
                    width: 96px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image31 {
                      width: 6px;
                      height: 6px;
                    }
                    .text31 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section7 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  margin-top: 10px;
                  .subSection29 {
                    width: 109px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image32 {
                      width: 6px;
                      height: 6px;
                    }
                    .text32 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
          .container3 {
            width: 413.33px;
            height: 244px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(241,255,246,1) 0%,rgba(255,255,255,1) 100%);
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .content6 {
              width: 288px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 12px;
              margin-top: 40px;
              margin-left: 40px;
              .main8 {
                width: 288px;
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .image34 {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;
                  border-radius: 39px;
                  box-sizing: border-box;
                  background: rgba(31,179,88,1);
                }
                .text34 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(0,0,0,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }
            }
            .content7 {
              width: 334px;
              height: 50px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 10px;
              margin-top: 20px;
              margin-left: 40px;
              .main9 {
                width: 334px;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .section8 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  .subSection30 {
                    width: 124px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image35 {
                      width: 6px;
                      height: 6px;
                    }
                    .text35 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section9 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  .subSection31 {
                    width: 185px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image36 {
                      width: 6px;
                      height: 6px;
                    }
                    .text36 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
          .container4 {
            width: 413.33px;
            height: 274px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(248,239,255,1) 0%,rgba(255,255,255,1) 100%);
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .content8 {
              width: 276px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 12px;
              margin-top: 40px;
              margin-left: 40px;
              .main10 {
                width: 276px;
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .image37 {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;
                  border-radius: 39px;
                  box-sizing: border-box;
                  background: rgba(140,57,199,1);
                }
                .text37 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(0,0,0,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }
            }
            .content9 {
              width: 334px;
              height: 140px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              gap: 10px;
              margin-top: 20px;
              margin-left: 40px;
              .main11 {
                width: 334px;
                height: 140px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .section10 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  .subSection32 {
                    width: 106px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image38 {
                      width: 6px;
                      height: 6px;
                    }
                    .text38 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section11 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  margin-top: 10px;
                  .subSection33 {
                    width: 82px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image39 {
                      width: 6px;
                      height: 6px;
                    }
                    .text39 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section12 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  margin-top: 10px;
                  .subSection34 {
                    width: 68px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image40 {
                      width: 6px;
                      height: 6px;
                    }
                    .text40 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section13 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  margin-top: 10px;
                  .subSection35 {
                    width: 96px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image41 {
                      width: 6px;
                      height: 6px;
                    }
                    .text41 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
                .section14 {
                  width: 334px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;
                  margin-top: 10px;
                  .subSection36 {
                    width: 109px;
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .image42 {
                      width: 6px;
                      height: 6px;
                    }
                    .text42 {
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }


      .kecheng1-page {
        width: 100%;
        // height: 392px;
        margin-top: 40px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin-bottom: 80px;
          box-sizing: border-box;
          background-image: url('./../assets/aigcImg/aigc3/kc1-Image2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .text {
            margin-top: 80px;
            width: 1280px;
            font-family: 'PingFang SC';
            font-size: 24px;
            text-align: center;
            color: rgba(0,0,0,1);
            font-weight: 400;
            white-space: pre-line;
          }
          .wrap {
            width: 1312px;
            height: 190px;
            margin-top: 50px;
            position: relative;
            .container {
              width: 413px;
              height: 124px;
              overflow: hidden;
              border-radius: 16px;
              border-width: 2px;
              box-sizing: border-box;
              box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
              background: linear-gradient(73deg, rgba(73,97,255,1) 0%,rgba(80,244,198,1) 100%);
              position: absolute;
              top: 0px;
              left: 0px;
              .image1 {
                width: 220px;
                height: 124px;
                box-sizing: border-box;
                position: absolute;
                top: 12px;
                left: 209px;
              }
              .text1 {
                position: absolute;
                top: 28px;
                left: 40px;
                line-height: 22px;
                font-family: 'PingFang SC';
                font-size: 14px;
                color: rgba(255,255,255,1);
                font-weight: 400;
                white-space: pre-line;
              }
            }
            .container1 {
              width: 413px;
              height: 124px;
              overflow: hidden;
              border-radius: 16px;
              border-width: 2px;
              box-sizing: border-box;
              box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
              background: linear-gradient(73deg, rgba(91,113,255,1) 0%,rgba(0,42,228,1) 100%);
              position: absolute;
              top: 0px;
              left: 433.50px;
              .text2 {
                position: absolute;
                top: 28px;
                left: 40px;
                line-height: 22px;
                font-family: 'PingFang SC';
                font-size: 14px;
                color: rgba(255,255,255,1);
                font-weight: 400;
                white-space: pre-line;
              }
              .image2 {
                width: 218.50px;
                height: 124px;
                box-sizing: border-box;
                position: absolute;
                top: 12px;
                left: 210.50px;
              }
            }
            .container2 {
              width: 413px;
              height: 124px;
              overflow: hidden;
              border-radius: 16px;
              border-width: 2px;
              box-sizing: border-box;
              box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
              background: linear-gradient(73deg, rgba(117,33,251,1) 0%,rgba(54,235,255,1) 100%);
              position: absolute;
              top: 0px;
              left: 867px;
              .text3 {
                position: absolute;
                top: 28px;
                left: 40px;
                line-height: 22px;
                font-family: 'PingFang SC';
                font-size: 14px;
                color: rgba(255,255,255,1);
                font-weight: 400;
                white-space: pre-line;
              }
              .image3 {
                width: 220px;
                height: 124px;
                box-sizing: border-box;
                position: absolute;
                top: 12px;
                left: 209px;
              }
            }
          }
        }
      }

      .kecheng2-page {
				width: 100%;
				height: 629px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.text {
					font-family: 'PingFang SC';
					font-size: 36px;
					white-space: nowrap;
					text-align: center;
					line-height: 50.40px;
					font-weight: 500;
				}
				.image {
					display: flex;
					justify-content: center;
					// align-items: flex-start;
					width: 100%;
					height: 539px;
					overflow: hidden;
					box-sizing: border-box;
					background: rgba(244,247,252,1);
					background-image: url('./../assets/aigcImg/aigc3/kc2-Image3.png');
					background-size: 1920px 539px;
					background-repeat: no-repeat;
          background-position: center;
					.wrap1 {
						width: 620px;
						height: 388px;
						margin-top: 80px;
						// margin-left: 320px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						.text8 {
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							color: rgba(255,255,255,1);
							line-height: 33.60px;
							font-weight: 500;
						}
						.text9 {
							margin-top: 20px;
							width: 620px;
							font-family: 'PingFang SC';
							font-size: 14px;
							color: rgba(255,255,255,1);
							font-weight: 400;
							white-space: pre-line;
						}
						.image1 {
							width: 620px;
							height: 254px;
							margin-top: 40px;
						}
					}
					.wrap {
						width: 580px;
						height: 388px;
						overflow: hidden;
						border-radius: 16px;
						box-sizing: border-box;
						background: rgba(255,255,255,0.98);
						margin-top: 80px;
						margin-left: 80px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						.container {
							width: 500px;
							height: 268px;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							flex-wrap: nowrap;
							justify-content: flex-start;
							align-items: flex-start;
							margin-top: 40px;
							margin-left: 40px;
							.content {
								width: 500px;
								height: 76px;
								box-sizing: border-box;
								display: flex;
								flex-direction: column;
								flex-wrap: nowrap;
								justify-content: flex-start;
								align-items: flex-start;
								margin-bottom: 20px;
								.main {
									width: 500px;
									height: 55px;
									box-sizing: border-box;
									display: flex;
									flex-direction: column;
									flex-wrap: nowrap;
									justify-content: flex-start;
									align-items: flex-start;
									margin-bottom: 20px;
									.text1 {
										margin-bottom: 10px;
										width: 500px;
										font-family: 'PingFang SC';
										font-size: 18px;
										white-space: nowrap;
										color: rgba(0,0,0,1);
										line-height: 25.20px;
										font-weight: 500;
									}
									.text2 {
										width: 500px;
										font-family: 'PingFang SC';
										font-size: 14px;
										white-space: nowrap;
										color: rgba(102,102,102,1);
										line-height: 19.60px;
										font-weight: 400;
									}
								}
								.main1 {
									width: 500px;
									box-sizing: border-box;
									background: rgba(0,0,0,0.08);
									min-height: 1px;
								}
							}
							.content1 {
								width: 500px;
								height: 76px;
								box-sizing: border-box;
								display: flex;
								flex-direction: column;
								flex-wrap: nowrap;
								justify-content: flex-start;
								align-items: flex-start;
								margin-bottom: 20px;
								.main2 {
									width: 500px;
									height: 55px;
									box-sizing: border-box;
									display: flex;
									flex-direction: column;
									flex-wrap: nowrap;
									justify-content: flex-start;
									align-items: flex-start;
									margin-bottom: 20px;
									.text3 {
										margin-bottom: 10px;
										width: 500px;
										font-family: 'PingFang SC';
										font-size: 18px;
										white-space: nowrap;
										color: rgba(0,0,0,1);
										line-height: 25.20px;
										font-weight: 500;
									}
									.text4 {
										width: 500px;
										font-family: 'PingFang SC';
										font-size: 14px;
										white-space: nowrap;
										color: rgba(102,102,102,1);
										line-height: 19.60px;
										font-weight: 400;
									}
								}
								.main3 {
									width: 500px;
									box-sizing: border-box;
									background: rgba(0,0,0,0.08);
									min-height: 1px;
								}
							}
							.content2 {
								width: 500px;
								height: 76px;
								box-sizing: border-box;
								display: flex;
								flex-direction: column;
								flex-wrap: nowrap;
								justify-content: flex-start;
								align-items: flex-start;
								.main4 {
									width: 500px;
									height: 55px;
									box-sizing: border-box;
									display: flex;
									flex-direction: column;
									flex-wrap: nowrap;
									justify-content: flex-start;
									align-items: flex-start;
									margin-bottom: 20px;
									.text5 {
										margin-bottom: 10px;
										width: 500px;
										font-family: 'PingFang SC';
										font-size: 18px;
										white-space: nowrap;
										color: rgba(0,0,0,1);
										line-height: 25.20px;
										font-weight: 500;
									}
									.text6 {
										width: 500px;
										font-family: 'PingFang SC';
										font-size: 14px;
										white-space: nowrap;
										color: rgba(102,102,102,1);
										line-height: 19.60px;
										font-weight: 400;
									}
								}
								.main5 {
									width: 500px;
									box-sizing: border-box;
									background: rgba(0,0,0,0.08);
									min-height: 1px;
								}
							}
						}
						.text7 {
							margin-top: 20px;
							margin-left: 40px;
							font-family: 'PingFang SC';
							font-size: 14px;
							white-space: nowrap;
							color: rgba(31,106,251,1);
							line-height: 19.60px;
							font-weight: 400;
						}
					}
				}
			}

      .kecheng3-page {
				width: 1280px;
				// height: 274px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.text {
					font-family: 'PingFang SC';
					font-size: 36px;
					white-space: nowrap;
					text-align: center;
					line-height: 50.40px;
					font-weight: 500;
          margin: 60px 0 30px;
          span{
            color: rgba(31,106,251,1);
          }
				}
				.wrap {
					width: 1280px;
					height: 184px;
					overflow: hidden;
					border-radius: 16px;
					box-sizing: border-box;
					background: rgba(255,255,255,1);
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					.image6 {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 1280px;
						height: 64px;
						overflow: hidden;
						box-sizing: border-box;
						background-image: url('./../assets/aigcImg/aigc3/kc3-Image4.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;
						.text4 {
							font-family: 'PingFang SC';
							font-size: 24px;
							white-space: nowrap;
							text-align: center;
							color: rgba(255,255,255,1);
							line-height: 33.60px;
							font-weight: 500;
						}
					}
					.container {
						width: 1280px;
						height: 120px;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						.image {
							position: relative;
							width: 426.67px;
							height: 120px;
							background-image: url('./../assets/aigcImg/aigc3/kc3-Image7.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.image1 {
								width: 83.63px;
								height: 40px;
								position: absolute;
								top: 20px;
								left: 167.71px;
							}
							.text1 {
								position: absolute;
								top: 36px;
								left: 40px;
								width: 346.66px;
								font-family: 'PingFang SC';
								font-size: 16px;
								text-align: center;
								color: rgba(0,0,0,1);
								line-height: 24px;
								font-weight: 400;
							}
						}
						.image2 {
							position: relative;
							width: 426.67px;
							height: 120px;
							background-image: url('./../assets/aigcImg/aigc3/kc3-Image10.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.image3 {
								width: 111.63px;
								height: 40px;
								position: absolute;
								top: 28px;
								left: 153.71px;
							}
							.text2 {
								position: absolute;
								top: 36px;
								left: 101.34px;
								font-family: 'PingFang SC';
								font-size: 16px;
								text-align: center;
								color: rgba(0,0,0,1);
								font-weight: 400;
								white-space: pre-line;
							}
						}
						.image4 {
							position: relative;
							width: 426.67px;
							height: 120px;
							background-image: url('./../assets/aigcImg/aigc3/kc3-Image13.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.image5 {
								width: 111.19px;
								height: 40px;
								position: absolute;
								top: 28px;
								left: 153.71px;
							}
							.text3 {
								position: absolute;
								top: 36px;
								left: 40.01px;
								width: 346.66px;
								font-family: 'PingFang SC';
								font-size: 16px;
								text-align: center;
								color: rgba(0,0,0,1);
								font-weight: 400;
								white-space: pre-line;
							}
						}
					}
				}
			}



      .kecheng4-page {
        // width: 1280px;
        height: 632px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .kecheng4-title {
          font-family: 'PingFang SC';
          font-size: 36px;
          white-space: nowrap;
          text-align: center;
          margin: 42px 0 0;
          font-weight: 500;
        }

        .kecheng4-wrap {
          margin-top: 40.24px;
          width: 1280px;
          height: 400px;

          .kecheng4-container {
            width: 1280px;
            height: 400px;
            // overflow: hidden;
            box-sizing: border-box;
            // background: rgba(0,0,0,1);
            display: flex;
            justify-content: center;
            align-items: center;

            .kecheng4-image {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1280px;
              height: 400px;
              border-radius: 16px;
              // background-image: url('./../assets/aigcImg/kecheng4-Image1.png');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: right center;

              .kecheng4-content {
                width: 1280px;
                height: 400px;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .kecheng4-main {
                  width: 800px;
                  height: 220px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-top: 40px;
                  margin-left: 40px;

                  .kecheng4-section {
                    width: 376px;
                    height: 48px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 32px;

                    .kecheng4-image1 {
                      width: 48px;
                      height: 48px;
                      overflow: hidden;
                      border-radius: 38px;
                      box-sizing: border-box;
                      background: rgba(255, 255, 255, 1);
                      margin-right: 20px;
                    }

                    .kecheng4-text1 {
                      font-family: 'PingFang SC';
                      font-size: 24px;
                      white-space: nowrap;
                      color: rgba(255, 255, 255, 1);
                      line-height: 33.60px;
                      font-weight: 500;
                    }
                  }
                  .kecheng4-section2{
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    opacity: 0.85;
                    height: 40px;
                    margin: -28px 0 16px 60px;
                    span{
                      margin-right: 60px;
                    }
                  }
                  .kecheng4-section1 {
                    width: 800px;
                    height: 140px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .kecheng4-text2 {
                      margin-bottom: 20px;
                      width: 800px;
                      opacity: 0.8500000238418579;
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      background-color: #ffffff;
                      line-height: 19.6px;
                      font-weight: 400;
                      padding: 16px;
                      border-radius: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        .wrap1 {
          width: 248px;
          height: 4px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 40.24px;

          .container1 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.15);
            min-height: 4px;
            margin-right: 8px;
          }

          .container2 {
            width: 48px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(31, 106, 251, 1);
            min-height: 4px;
            margin-right: 8px;
          }

          .container3 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.15);
            min-height: 4px;
            margin-right: 8px;
          }

          .container4 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.15);
            min-height: 4px;
            margin-right: 8px;
          }

          .container5 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.15);
            min-height: 4px;
            margin-right: 8px;
          }

          .container6 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.15);
            min-height: 4px;
          }
        }
      }


    }

  }

}
</style>

<style lang="less">
.kecheng4-wrap {

  .el-carousel__indicators--horizontal {
    font-size: 16px;

    .el-carousel__indicator {
      .el-carousel__button {
        background-color: rgba(0, 0, 0, 0.15);
        width: 32px;
      }
    }

    .el-carousel__button {
      height: 4px;
      border-radius: 3px;
    }

    .is-active {
      .el-carousel__button {
        background-color: #1F6AFB;
        width: 48px;
      }
    }
  }
}
</style>