<template>
  <div class="aigc-main">
    <navpage class="nav" :isdefault="true"></navpage>

    <div class="banner-page">
      <div class="image">
        <img
          :src="require('./../assets/aigcImg/bannerImage3.png')"
          class="image1"
          alt="Image Asset 3"
        />
        <p class="text">
          您学会的不只是理论，而是3-5个AI实战项目<br />
          我们卖的不只是课，而是A800和50万年薪AI工程师的咨询辅导时间
        </p>
        <div class="wrap" @click="yuyueDialogVisible = true">
          <img
            :src="require('./../assets/aigcImg/bannerImage6.png')"
            class="image2"
            alt="Image Asset 6"
          /><img
            :src="require('./../assets/aigcImg/bannerImage7.png')"
            class="image3"
            alt="Image Asset 7"
          />
        </div>
      </div>
    </div>

    <div class="kecheng-page">
      <div class="kecheng-title">
        <p>
          培养扎实的人工智能理论基础，专注于大模型集成、应用开发和指令训练，教授如何调用API、构建专业领域AI
          Agent，以及对大模型进行精准微调，以提升特定任务的商业应用性能。
        </p>
      </div>

      <div class="kecheng-content">
        <img
          src="https://file.aidexianzhaopinguan.com/resume/102128155601272517.jpg"
          alt="Image Asset 1"
        />

        <div class="kecheng1-md">
          <div class="image">
            <div class="wrap"><p class="text">哈工大的<br /><span class="blue">博士和硕士</span>教学</p></div>
            <div class="wrap1">
              <p class="text1">做<span class="blue">产品研发</span>出身的<br />（AI面试产品）</p>
            </div>
            <div class="wrap2"><p class="text2"><span class="blue">三个项目</span>实战</p></div>
            <div class="wrap3"><p class="text3">PBL<span class="blue">实战</span></p></div>
            <div class="wrap4"><p class="text4"><span class="blue">A800</span>真实算力</p></div>
            <div class="wrap5"><p class="text5"><span class="blue">就业</span>推荐</p></div>
            <div class="wrap6"><p class="text6">前沿技术<br /><span class="blue">更新讲座</span>(OJAC)</p></div>
            <div class="wrap7"><p class="text7">老师和助教<br /><span class="blue">多对一</span>手把手教学</p></div>
            <div class="wrap8"><p class="text8">微软<span class="blue">证书</span></p></div>
          </div>
        </div>

        <div class="kecheng2">

          <div class="kecheng2-image">
            <p class="text1">实战项目（学员作品）</p>
            <div class="wrap1">
              <div class="container1" :class="{ szActive: szActive == 1 }" @mouseover="szActive = 1"><p class="text2">AI中医问答</p></div>
              <div class="container2" :class="{ szActive: szActive == 2 }" @mouseover="szActive = 2"><p class="text3">医疗大模型助手</p></div>
              <div class="container3" :class="{ szActive: szActive == 3 }" @mouseover="szActive = 3"><p class="text4">AI-CRM</p></div>
              <div class="container4" :class="{ szActive: szActive == 4 }" @mouseover="szActive = 4"><p class="text5">金融助手</p></div>
              <div class="container5" :class="{ szActive: szActive == 5 }" @mouseover="szActive = 5"><p class="text6">CSV助手</p></div>
            </div>
            <div class="kecheng2-wrap">
              <p class="kecheng2-text" v-show="szActive == 1">利用RAG、langchain等框架实现智能中医问答系统，用户可以通过该系统进行行业内的知识问答。</p>
              <p class="kecheng2-text" v-show="szActive == 2">医疗大模型助手是一个基于 LLaMA-Factory 框架，并结合医疗领域数据集微调的大模型应用，可支持医学知识问答、病情分析和辅助诊疗建议等智能化服务。</p>
              <p class="kecheng2-text" v-show="szActive == 3">AI-CRM 是一个基于人工智能的客户关系管理系统，通过自动化分析客户数据和交互优化，提高企业营销效率和客户满意度。</p>
              <p class="kecheng2-text" v-show="szActive == 4">金融助手是一个结合 LangChain 和 RAG 的智能工具，通过检索实时金融数据并生成精准回答，为用户提供投资建议、市场分析和金融决策支持。</p>
              <p class="kecheng2-text" v-show="szActive == 5">CSV助手是一个基于 LangChain 开发的智能应用，旨在高效处理 CSV 数据，支持数据查询以及分析，提升数据处理效率。</p>
              <div class="container">
                <div class="content">
                  <img
                    :src="require('./../assets/aigcImg/kecheng2-Image19.png')"
                    class="kecheng2-image1"
                    alt="Image Asset 19"
                  />
                </div>

                <img
                  v-show="szActive == 1"
                  :src="require('./../assets/aigcImg/kecheng2-Image20.png')"
                  class="kecheng2-image2"
                  alt="Image Asset 20"
                />
                <img
                  v-show="szActive == 2"
                  :src="require('./../assets/aigcImg/kecheng2-Image21.png')"
                  class="kecheng2-image2"
                  alt="Image Asset 20"
                />
                <img
                  v-show="szActive == 3"
                  :src="require('./../assets/aigcImg/kecheng2-Image22.png')"
                  class="kecheng2-image2"
                  alt="Image Asset 20"
                />
                <img
                  v-show="szActive == 4"
                  :src="require('./../assets/aigcImg/kecheng2-Image23.png')"
                  class="kecheng2-image2"
                  alt="Image Asset 20"
                />
                <img
                  v-show="szActive == 5"
                  :src="require('./../assets/aigcImg/kecheng2-Image24.png')"
                  class="kecheng2-image2"
                  alt="Image Asset 20"
                />
              </div>
            </div>
          </div>



        </div>
        <div class="kecheng3">
          <div class="kecheng3-image">
            <div class="kecheng3-wrap"></div>
            <p class="kecheng3-text">
              AIGC大模型应用工程师课程能够帮助您从事与AI
              应用开发相关的工作，如<span class="blue">大模型应用开发工程师、SFT（微调）中的工程师岗位。</span>对于更高级别（中级或高级），则需要具备硕士及以上学位，并且通过学习A系列课程可以掌握应用开发、审核等方面的知识。
            </p>
            <p class="kecheng3-text1">未来职业道路</p>
          </div>
        </div>

        <div class="kecheng4">
          <p class="kecheng4-title"><span class="blue">就业</span>案例</p>
          <div class="kecheng4-wrap">
            <el-carousel :interval="3000" arrow="never" height="450px">
              <el-carousel-item v-for="(item, index) in caseItem" :key="index">
                <div class="kecheng4-container">
                  <div class="kecheng4-image" :style="'backgroundImage: url('+item.img+')'">
                    <div class="kecheng4-content">
                      <div class="kecheng4-main">
                        <div class="kecheng4-section">
                          <img
                            :src="item.head"
                            class="kecheng4-image1"
                            alt="Image Asset 9"
                          />
                          <p class="kecheng4-text1">{{ item.name }}</p>
                        </div>
                        <div class="kecheng4-section1">
                          <p class="kecheng4-text2" v-html="item.desc"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </el-carousel-item>
            </el-carousel>

          </div>

        </div>


      </div>


    </div>

    <el-dialog title="" width="480px" top="30vh" custom-class="yuyueDialog" :visible.sync="yuyueDialogVisible">
      <div class="yuyue-page">
        <div class="yuyue-wrap">
          <img
            :src="require('./../assets/images/yuyueImage3.png')"
            class="yuyue-image"
            alt="Image Asset 3"
            @click="yuyueDialogVisible = false"
          />
          <p class="yuyue-text"><span>立即扫码</span>预约今晚讲座</p>
        </div>
        <img
          :src="require('./../assets/images/yuyueImage5.png')"
          class="yuyue-image1"
          alt="Image Asset 5"
        />
      </div>
    </el-dialog>




  </div>
</template>
<script>
import navpage from './../components/nav.vue'

export default {
  components: { navpage },
  data() {
    return {
      cur: 1,
      szActive: 1,
      yuyueDialogVisible: false,
      caseItem:[
        {
          name:'张同学-建筑学本科-工作5年',
          head: require('./../assets/aigcImg/kecheng4-head1.png'),
          img: require('./../assets/aigcImg/kecheng4-Image1.png'),
          desc: `
            湖北某大学建筑学专业 ，28岁
            ，过去5年一直从事建筑设计相关产品设计工作，但从前年开始因为市场下行，建筑行业变得非常冷淡，公司进行了一波的裁员和降薪，虽然张同学没有被裁掉，但工资也降了不少，所以一直在寻求换行业，今年3月份了解到我们这边的AI课程，参与了我们的行业讲解的公开课，决定接下来向AI产品经理的工作方向努力，并在当月26号报名入学。
            <br /><br />
            学员基础基本为0，
            但非常努力，每天学习时间都至少4个小时，然后在7月初完成课程学习，7月8号学管给学员安排了简历优化和面试辅导，在7月20日到7月31号陆续收到了3家公司的offer，分别是广州小迈科技公司的内部孵化简历优化项目的AI产品经理岗位、深圳智慧城市科技集团的中软外包的AI产品经理的岗位和深圳市二一教育公司的k12教育信息站的AI产品经理岗的offer。
          `,
        },
        {
          name:'袁同学-计算机211本科-应届生',
          head: require('./../assets/aigcImg/kecheng4-head2.png'),
          img: require('./../assets/aigcImg/kecheng4-Image2.png'),
          desc: `本科学校是一所普通 211 院校，读的计算机科学与技术专业，本科学习也不是很认真努力，大学四年一晃而过，从去年到今年4月份，经历了半年多校招的屡屡碰壁，袁同学才猛然发现，以自身现有的专业技能，在与同龄人的竞争中毫无优势可言，很迷茫，不知道自己的未来到底在哪。
            <br /><br />不过，很幸运的是袁同学并未就此放弃。今年4月份，在和我们顾问老师的咨询下，决定参加咱们得AI 大模型工程师培训；课程学习期间，袁同学非常努力，每天除了吃饭睡觉基本就是看我们的课程学习，一直持续了2个月，中间很多学习的疑问在学管老师的不断解答下也都得到了解决，学习累的时候也和他的学管老师说过“感觉大学四年所有的努力加到一块都没有这段时间付出的多”。
            <br /><br />天道酬勤，功夫不负有心人，最终在7月初成功以18K的薪资入职一家大型民企，担任大模型应用开发工程师一职。`
        },
        {
          name:'娜娜同学-管理学硕士-工作4年',
          head: require('./../assets/aigcImg/kecheng4-head3.png'),
          img: require('./../assets/aigcImg/kecheng4-Image3.png'),
          desc: `大连某大学管理学硕士，在公司的数据部门工作了四年，担任数据产品经理的岗位，负责BI开发业务，由于今年初公司内部新增了AI大模型方向的产品经理岗位，待遇和未来发展都比她现在的数据产品经理岗位更有优势，所以就想看能否进行内部转岗，但了解下来转岗也是需要通过技能考核和AI领域的面试的，所以在5月6号找到了我们，报名参加我们AIGC大模型工程师培训课程，学习关于AI大模型的相关知识；
            <br /><br />学员虽然有一定的计算机基础，但之前是没有深入了解过AI领域的，所以学习前期在技术方面遇到的问题也是特别多的，基本每天都会和她的学管老师沟通学习中遇到的困难，还一度因为学不明白想过放弃，但最终还是坚持了下来，中间学管以及授课的Dion老师和曹老师也为了他开了很多次的腾讯会议面对面帮助她梳理学习难点和问题；
            <br /><br />然后在今年7月15号，课程学习到A3阶段，公司内部转岗的窗口出现了，学员当时还不是很自信，但我们学管还是非常鼓励学员尝试去参加的，同步给学员的简历进行了优化，也安排了AI产品经理岗位方向的面试指导，上个月8月6号学员收到公司反馈也告知了我们，非常顺利，学员考核和面试都一次性通过，抓住了这次转岗的机会，加入了公司的AI项目，在其中负责业务场景搭建、测试基于BI的AI功能等重要板块；薪资和个人发展都得到了很大的提升。`
        },
        {
          name:'徐同学-建筑学硕士-工作6年',
          head: require('./../assets/aigcImg/kecheng4-head4.png'),
          img: require('./../assets/aigcImg/kecheng4-Image4.png'),
          desc: `哈尔滨某大学硕士学历-建筑学专业 ，已经工作了6年，之前从事过建筑工程师，项目经理等岗位，但一直觉得不是自己喜欢的方向，而且收入待遇也达不到自己的要求；
            <br /><br />在今年过完年了解到了AI领域，得知这是一个新的的发展方向，很具有行业革命性，决心想要转行大模型相关岗位，所以在今年3月25号报名了我们的课程；
            <br /><br />但由于自己基础实在是不够好，之前虽然也学过一些代码和编程知识，但也都忘了很多，所以报名之后，学管和授课老师做了很多次的腾讯会议方式的答疑，帮助她掌握学习内容和提升技术能力；
            <br /><br />学习了4个多月，在上个月也就是八月份，收到了江苏一家上市公司的offer，基础薪资是18k，还通过了北京的一家做金融业务的公司二面，但由于这家公司的终面必须要去北京现场，学员时间没排的过来就没去参加，这两家公司的岗位都是大模型算法工程师`
        },
        {
          name:'李同学-计算机本科-考研失败',
          head: require('./../assets/aigcImg/kecheng4-head6.png'),
          img: require('./../assets/aigcImg/kecheng4-Image6.png'),
          desc: `李同学毕业于湖南某工程学院计算机专业，今年24岁。23年毕业，毕业即失业，在家考研了一年，但考研还是失利了，没能上岸，准备就业，但又对自己的职业规划感到非常迷茫，尤其是在面临当前激烈的就业竞争时。然而，李同学并没有选择再次退缩。2024年3月，他选择参加了近屿智能的AIGC大模型应用开发工程师课程。
            <br /><br />在学习过程中，李同学全身心投入，不断提高自己的技术能力。经过四个月的学习，成功入职杭州一家智能科技公司，成为一名AI工程师，年薪15万元。他的工作职责涵盖AI领域的软件工程化和产品开发，AI算法及系统的辅助设计与实现。衷心祝愿李同学在AI领域继续攀登，迎接更加美好的职业未来！`
        },
      ]
    };
  },
  mounted() {
    document.title = "AIGC大模型培训";
  },
  methods: {
    openBaidu() {
      const aff = document.getElementById("aff-im-root");
      const btn = aff.getElementsByClassName("embed-icon-pcIcon3")[0];
      btn.click();
    },
    handleClick(type) {
      if (type && type === "openBaidu") {
        this.openBaidu();
        // this[type]()
      }
    },
  },
};
</script>
<style lang="less" scoped>
.blue{
  color: #1F6AFB;
}
.aigc-main {
  min-width: 1300px;
  max-width: 100%;
  margin: auto;
  .banner-page {
    // width: 100%;
    height: 600px;
    overflow: hidden;
    box-sizing: border-box;
    background: rgba(244,247,252,1);
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      max-width: 1920px;
      height: 600px;
      box-sizing: border-box;
      background-image: url('./../assets/aigcImg/bannerImage2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .image1 {
        width: 777px;
        height: 59px;
        // margin-top: 172.67px;
        // margin-left: 319.63px;
        margin: 172.67px auto 0;
      }
      .text {
        margin: 45.96px auto;
        // margin-left: 606px;
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(51,51,51,1);
        font-weight: 500;
        white-space: pre-line;
      }
      .wrap {
        width: 179px;
        height: 48px;
        border-radius: 61px;
        box-sizing: border-box;
        background: rgba(31,106,251,1);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 50px auto 0;
        .image2 {
          width: 105.01px;
          height: 16.63px;
          margin-right: 8px;
        }
        .image3 {
          width: 16px;
          height: 16px;
          overflow: hidden;
          box-sizing: border-box;
        }
      }
    }
  }
  .kecheng-page{
    margin-top: -40px;
		border-radius: 16px;
		box-sizing: border-box;
		// background: rgba(255,255,255,1);
    .kecheng-title {
      margin: 0 auto 20px;
      width: 1280px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 1280px;
      height: 148px;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;
      background: rgba(255,255,255,1);


      p {
        margin-left: 39.53px;
        margin-bottom: 39.26px;
        width: 1200px;
        font-family: 'PingFang SC';
        font-size: 24px;
        text-align: center;
        color: rgba(0,0,0,1);
        line-height: 33.60px;
        font-weight: 500;
      }
    }
    .kecheng-content{
      display: flex;
      flex-direction: column;
      width: 100%;
      background-image: url('./../assets/aigcImg/kechengBg1.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      >img{
        margin: 20px auto;
        width: 1280px;
      }

      .kecheng1-md{
        margin: 20px auto 0;
        width: 100%;
        height: 356px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('./../assets/aigcImg/kecheng1-md2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .image {
          position: relative;
          width: 1280px;
          height: 356px;
          overflow: hidden;
          box-sizing: border-box;
          .wrap {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 68px;
            // left: 304px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
              font-family: 'PingFang SC';
              font-size: 24px;
              text-align: center;
              color: rgba(0,0,0,1);
              font-weight: 500;
              white-space: pre-line;
            }
          }
          .wrap1 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 68px;
            left: 264px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text1 {
              font-family: 'PingFang SC';
              font-size: 24px;
              text-align: center;
              color: rgba(0,0,0,1);
              font-weight: 500;
              white-space: pre-line;
            }
          }
          .wrap2 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 68px;
            left: 524px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text2 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              text-align: center;
              line-height: 33.60px;
              font-weight: 500;
            }
          }
          .wrap3 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 68px;
            left: 784px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text3 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              text-align: center;
              color: rgba(0,0,0,1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }
          .wrap4 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 68px;
            left: 1044px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text4 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              text-align: center;
              line-height: 33.60px;
              font-weight: 500;
            }
          }
          .wrap5 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 176px;
            left: 134px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text5 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              text-align: center;
              line-height: 33.60px;
              font-weight: 500;
            }
          }
          .wrap6 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 176px;
            left: 394px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text6 {
              font-family: 'PingFang SC';
              font-size: 24px;
              text-align: center;
              color: rgba(0,0,0,1);
              font-weight: 500;
              white-space: pre-line;
            }
          }
          .wrap7 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 176px;
            left: 654px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text7 {
              font-family: 'PingFang SC';
              font-size: 24px;
              text-align: center;
              color: rgba(0,0,0,1);
              font-weight: 500;
              white-space: pre-line;
            }
          }
          .wrap8 {
            width: 240px;
            height: 88px;
            overflow: hidden;
            border-radius: 8px 24px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
            background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
            position: absolute;
            top: 176px;
            left: 914px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text8 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              text-align: center;
              color: rgba(0,0,0,1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }
        }
			
      }
      .kecheng2 {
        // width: 1920px;
        height: 700px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .kecheng2-image {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          height: 700px;
          box-sizing: border-box;
          background-image: url('./../assets/aigcImg/kecheng2-Image2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .text1 {
            margin-top: 80px;
            width: 100%;
            font-family: 'PingFang SC';
            font-size: 36px;
            white-space: nowrap;
            text-align: center;
            color: rgba(0,0,0,1);
            line-height: 50.40px;
            font-weight: 500;
          }
          .wrap1 {
            width: 100%;
            height: 80px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            color: rgba(0,0,0,1);
            .container1 {
              width: 167px;
              height: 50px;
              border-radius: 73px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-right: 40px;
              .text2 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container2 {
              width: 216px;
              height: 50px;
              border-radius: 73px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-right: 40px;
              .text3 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container3 {
              width: 141px;
              height: 50px;
              border-radius: 73px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-right: 40px;
              .text4 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container4 {
              width: 144px;
              height: 50px;
              border-radius: 73px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-right: 40px;
              .text5 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container5 {
              width: 145px;
              height: 50px;
              border-radius: 73px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              .text6 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .szActive{
              background: rgba(31,106,251,1);
              color: rgba(255,255,255,1);
            }
          }
          .kecheng2-wrap {
            width: 1280px;
            height: 424px;
            margin: 16px auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .kecheng2-text {
              margin-top: 156px;
              width: 447px;
              font-family: 'PingFang SC';
              font-size: 18px;
              color: rgba(102,102,102,1);
              line-height: 32px;
              font-weight: 400;
            }
            .container {
              width: 753px;
              height: 360px;
              overflow: hidden;
              border-radius: 8px;
              border-width: 4px;
              box-sizing: border-box;
              box-shadow: 0px 8px 32px 0px rgba(0,18,53,0.25);
              background: rgba(55,55,55,1);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .content {
                width: 753px;
                height: 20px;
                box-sizing: border-box;
                background: rgba(82,82,82,1);
                margin-top: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .kecheng2-image1 {
                  width: 40px;
                  height: 8px;
                  box-sizing: border-box;
                  margin-left: 20px;
                }
              }
              .kecheng2-image2 {
                width: 740px;
                height: 327px;
                overflow: hidden;
                border-radius: 8px;
                box-sizing: border-box;
                background: rgba(255,255,255,1);
                margin-top: 6.50px;
              }
            }
          }
        }
      }
      .kecheng3 {
        width: 100%;
        height: 294px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .kecheng3-image {
          position: relative;
          width: 100%;
          height: 294px;
          background-image: url('./../assets/aigcImg/kecheng3-Image2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .kecheng3-wrap {
            width: 100%;
            opacity: 0.8500000238418579;
            box-sizing: border-box;
            background: rgba(9,17,69,0.85);
            min-height: 294px;
            // position: absolute;
            // top: 0px;
            // left: 0px;
          }
          .kecheng3-text {
            position: absolute;
            top: 170px;
            left: 50%;
            transform: translateX(-50%);
            width: 960px;
            font-family: 'PingFang SC';
            font-size: 14px;
            text-align: center;
            color: rgba(255,255,255,1);
            line-height: 22px;
            font-weight: 500;
          }
          .kecheng3-text1 {
            position: absolute;
            top: 80px;
            left: 50%;
            transform: translateX(-50%);
            font-family: 'PingFang SC';
            font-size: 36px;
            white-space: nowrap;
            text-align: center;
            color: rgba(255,255,255,1);
            line-height: 50.40px;
            font-weight: 500;
          }
        }
      }
      .kecheng4 {
        // width: 1280px;
        height: 632px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .kecheng4-title {
          font-family: 'PingFang SC';
          font-size: 36px;
          white-space: nowrap;
          text-align: center;
          margin: 42px 0 0;
          font-weight: 500;
        }
        .kecheng4-wrap {
          margin-top: 40.24px;
          width: 1280px;
          height: 400px;
          .kecheng4-container {
            width: 1280px;
            height: 400px;
            border-radius: 16px;
            // overflow: hidden;
            box-sizing: border-box;
            // background: rgba(0,0,0,1);
            display: flex;
            justify-content: center;
            align-items: center;
            .kecheng4-image {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1280px;
              height: 400px;
              border-radius: 16px;
              background-image: url('./../assets/aigcImg/kecheng4-Image1.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              .kecheng4-content {
                width: 1280px;
                height: 400px;
                box-sizing: border-box;
                border-radius: 16px;
                background: linear-gradient(270deg, rgba(0,25,252,0.1) 0%,rgba(0,25,252,0.85) 100%);
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .kecheng4-main {
                  width: 800px;
                  height: 220px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-top: 40px;
                  margin-left: 40px;
                  .kecheng4-section {
                    width: 376px;
                    height: 48px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 32px;
                    .kecheng4-image1 {
                      width: 48px;
                      height: 48px;
                      overflow: hidden;
                      border-radius: 38px;
                      box-sizing: border-box;
                      background: rgba(255,255,255,1);
                      margin-right: 20px;
                    }
                    .kecheng4-text1 {
                      font-family: 'PingFang SC';
                      font-size: 24px;
                      white-space: nowrap;
                      color: rgba(255,255,255,1);
                      line-height: 33.60px;
                      font-weight: 500;
                    }
                  }
                  .kecheng4-section1 {
                    width: 800px;
                    height: 140px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .kecheng4-text2 {
                      margin-bottom: 20px;
                      width: 800px;
                      opacity: 0.8500000238418579;
                      font-family: 'PingFang SC';
                      font-size: 14px;
                      color: rgba(255,255,255,1);
                      line-height: 19.60px;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
        .wrap1 {
          width: 248px;
          height: 4px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 40.24px;
          .container1 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0,0,0,0.15);
            min-height: 4px;
            margin-right: 8px;
          }
          .container2 {
            width: 48px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(31,106,251,1);
            min-height: 4px;
            margin-right: 8px;
          }
          .container3 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0,0,0,0.15);
            min-height: 4px;
            margin-right: 8px;
          }
          .container4 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0,0,0,0.15);
            min-height: 4px;
            margin-right: 8px;
          }
          .container5 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0,0,0,0.15);
            min-height: 4px;
            margin-right: 8px;
          }
          .container6 {
            width: 32px;
            border-radius: 27px;
            box-sizing: border-box;
            background: rgba(0,0,0,0.15);
            min-height: 4px;
          }
        }
      }


    }

  }
  
}
</style>

<style lang="less">
.kecheng4-wrap{

  .el-carousel__indicators--horizontal{
    font-size: 16px;
    .el-carousel__indicator{
      .el-carousel__button{
      background-color: rgba(0, 0, 0, 0.15);
      width: 32px;
      }
    }
    .el-carousel__button{
      height: 4px;
      border-radius: 3px;
    }
    .is-active{
      .el-carousel__button{
      background-color: #1F6AFB;
      width: 48px;
      }
    }
  }
}

</style>
