<template>
    <div class="aigc-main">
      <navpage class="nav" :isdefault="true"></navpage>
  
      <div class="banner-page">
        <div class="image">
          <img
            :src="require('./../assets/aigcImg/kc0-Image3.png')"
            class="image1"
            alt="Image Asset 3"
          />
          <p class="text">
            成为能驾驭文本、图像、音频、视频的多模态大模型应用工程师，
            <br />赋能实际业务场景，开启无限可能！
          </p>
          <div class="wrap" @click="yuyueDialogVisible = true">
            <img
              :src="require('./../assets/aigcImg/kc0-Image6.png')"
              class="image2"
              alt="Image Asset 6"
            /><img
              :src="require('./../assets/aigcImg/kc0-Image7.png')"
              class="image3"
              alt="Image Asset 7"
            />
          </div>
        </div>
      </div>
  
      <div class="kecheng-page">
        <div class="kecheng-title">
          <p>
            深入探索MLLM工具的使用、API调用、工具开发与增强，涵盖AI创作、视觉艺术、音乐生成及多模态技术，
            <br />旨在打造精通AI技术应用与创新的多模态大模型工程师。
          </p>
        </div>
  
        <div class="kecheng-content">
          <div class="kecheng0-page">
            <div class="wrap">
              <div class="container">
                <img
                  :src="require('./../assets/aigcImg/kecheng0-Image4.png')"
                  class="image"
                  alt="Image Asset 4"
                />
                <p class="text">B1 MLLM工具的使用</p>
              </div>
              <div class="container1">
                <div class="content" v-for="(item,index) in mllmB1" :key="index">
                  <div class="main1" v-for="(a,i) in item" :key="i">
                    <img
                      :src="require('./../assets/aigcImg/kecheng0-Image9.png')"
                      class="image2"
                      alt="Image Asset 12"
                    />
                    <p class="text2">{{ a.title }}<span>{{ a.val }}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap1">
              <div class="container2">
                <img
                  :src="require('./../assets/aigcImg/kecheng0-Image33.png')"
                  class="image8"
                  alt="Image Asset 33"
                />
                <p class="text8">B2 MLLM的API调用及理论基础</p>
              </div>
              <div class="container3">
                <div class="content3" v-for="(item,index) in mllmB2" :key="index">
                  <div class="main7" v-for="(a,i) in item" :key="i">
                    <img
                      :src="require('./../assets/aigcImg/kecheng0-Image38.png')"
                      class="image9"
                      alt="Image Asset 38"
                    />
                    <p class="text9">{{ a.title }}<span>{{ a.val }}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap2">
              <div class="container4">
                <img
                  :src="require('./../assets/aigcImg/kecheng0-Image58.png')"
                  class="image15"
                  alt="Image Asset 58"
                />
                <p class="text15">B3 MLLM的工具开发</p>
              </div>
              <div class="container5">
                <div class="content5" v-for="(item,index) in mllmB3" :key="index">
                  <div class="main13" v-for="(a,i) in item" :key="i">
                    <img
                      :src="require('./../assets/aigcImg/kecheng0-Image63.png')"
                      class="image16"
                      alt="Image Asset 63"
                    />
                    <p class="text16">{{ a.title }}<span>{{ a.val }}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap3">
              <div class="container6">
                <img
                  :src="require('./../assets/aigcImg/kecheng0-Image80.png')"
                  class="image21"
                  alt="Image Asset 80"
                />
                <p class="text21">B4 MLLM的工具增强</p>
              </div>
              <div class="container7">
                <div class="content7" v-for="(item,index) in mllmB4" :key="index">
                  <div class="main18" v-for="(a,i) in item" :key="i">
                    <img
                      :src="require('./../assets/aigcImg/kecheng0-Image85.png')"
                      class="image22"
                      alt="Image Asset 85"
                    />
                    <p class="text22">{{ a.title }}<span>{{ a.val }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="kecheng1-page">
            <div class="image">
              <p class="text">
                近屿智能精心设计的<span class="blue">多模态大模型应用工程师课程，</span>旨在带领大家深入探索如何利用最前沿的人工智能技术，将简单的文字转化为丰富多彩的多媒体内容，包括文本、图像、语音、音乐和视频。课程从基础的文本生成（文生文）起步，逐步引导大家迈向更高阶的视频创作（文生视频），采用循序渐进的教学方式，让大家在轻松愉快的氛围中掌握图像、语音、音乐和视频的创作技巧。
              </p>
              <div class="wrap">
                <div class="container">
                  <div class="content">
                    <img
                      :src="require('./../assets/aigcImg/kc1-Image7.png')"
                      class="image1"
                      alt="Image Asset 7"
                    />
                    <p class="text1">文生文</p>
                  </div>
                  <p class="text2">
                    介绍如何使用开源的Llama、ChatGlm或者闭源的蛙蛙写作、通义、Kimi等进行AI文本创作，如果你有一些可以模仿的作品集或者自己的历史作品集，可以通过对开源的Llama、ChatGlm进行微调（SFT），让大模型生成海量的类似风格的小说、剧本、软文和广告脚本等。
                  </p>
                </div>
                <div class="container1">
                  <div class="content1">
                    <img
                      :src="require('./../assets/aigcImg/kc1-Image12.png')"
                      class="image2"
                      alt="Image Asset 12"
                    />
                    <p class="text3">AI语音生成</p>
                  </div>
                  <p class="text4">
                    用开源的GPTSoVits或者闭源的剪映进行AI语音生成，如果你有一些可以模仿的人物音频或者自己的历史音频集，可以通过对开源的GPTSoVits进行微调（SFT），让大模型生成海量的类似风格的语音等。
                  </p>
                </div>
                <div class="container2">
                  <div class="content2">
                    <img
                      :src="require('./../assets/aigcImg/kc1-Image17.png')"
                      class="image3"
                      alt="Image Asset 17"
                    />
                    <p class="text5">AI视频创作</p>
                  </div>
                  <p class="text6">
                    用开源的CogVideo或者闭源的极梦、Dream
                    Machine、Runway、Pika进行AI视频创作，如果你有一些可以模仿的视频作品集、电影或者自己的历史作品集，可以通过对开源的CogVideo进行微调（SFT），让大模型生成海量的类似风格的短视频。
                  </p>
                </div>
                <div class="container3">
                  <div class="image4"><div class="main"></div></div>
                </div>
                <div class="container4">
                  <div class="content3">
                    <img
                      :src="require('./../assets/aigcImg/kc1-Image25.png')"
                      class="image5"
                      alt="Image Asset 25"
                    />
                    <p class="text8">文生图</p>
                  </div>
                  <p class="text9">
                    会用开源的Flux、Stable
                    Diffusion或者闭源的MidJourney进行AI图片创作，如果你有一些可以模仿的作品集或者自己的历史作品集，可以通过对开源的Flux或者Stable
                    Diffusion进行微调（SFT），让大模型生成海量的类似风格的KV、电商详情页、海报和字体等。
                  </p>
                  <div class="content4">
                    <div class="main1">
                      <img
                        :src="require('./../assets/aigcImg/kc1-Image30.png')"
                        class="image6"
                        alt="Image Asset 30"
                      />
                      <p class="text10">AI音乐创作</p>
                    </div>
                    <p class="text11">
                      用开源的AudioLDM或者闭源的SunoAI进行AI音乐创作，如果你有一些可以模仿的乐曲集、歌曲集或者自己的历史作品集，可以通过对开源的AudioLDM进行微调（SFT），让大模型生成海量的类似风格的纯音乐或者带歌词的歌曲等。
                    </p>
                  </div>
                  <p class="text7">
                    结合以上的一些或者全部的开源算法，用Langchain等技术框架开发一个集合AI文本创作，文生图、文生音频、文生音乐和文生视频为一体的多模态大模型AI应用，例如：AI自动剪辑软件筷子科技，非实时数字人合成软件HeyGen和AI电商广告生成软件Fancy
                    Tech等。
                  </p>
                </div>
              </div>
            </div>
          </div>
  
          <div class="kecheng2-page">
            <div class="wrap3">
              <div class="image20">
                <div class="image21">
                  <div class="image21_1">
                  <p class="text41">实战项目</p>
                  <div class="section13">
                    <div class="main14">
                      <div class="subSection6" :class="{ subSectionNum: subSectionNum == 1 }" @mouseover="subSectionNum = 1">
                        <div class="block">
                          <img v-show="subSectionNum == 1" :src="require('./../assets/images/Image128_3.png')" class="image22" style="height: 36px;width: 40px;margin-right: 12px;" alt="Image Asset 128" />
                          <p class="text38">批量图像生成</p>
                        </div>
                      </div>
                      <div class="subSection7" :class="{ subSectionNum: subSectionNum == 2 }" @mouseover="subSectionNum = 2">
                        <div class="block1">
                          <img v-show="subSectionNum == 2" :src="require('./../assets/images/Image128.png')" class="image22" alt="Image Asset 128" />
                          <p class="text39">声音克隆项目</p>
                        </div>
                      </div>
                      <div class="subSection8" :class="{ subSectionNum: subSectionNum == 3 }" @mouseover="subSectionNum = 3">
                        <div class="block2">
                          <img v-show="subSectionNum == 3" :src="require('./../assets/images/Image128_2.png')" class="image22" style="height: 28px;width: 32px;margin-right: 12px;" alt="Image Asset 128" />
                          <p class="text40">多模态大模型本地化部署</p>
                        </div>
                      </div>
                      <img v-show="subSectionNum == 1" :src="require('./../assets/images/Image133_2.png')" class="image23" alt="Image Asset 133" />
                      <img v-show="subSectionNum == 2" :src="require('./../assets/images/Image133.png')" class="image23" alt="Image Asset 133" />
                      <img v-show="subSectionNum == 3" :src="require('./../assets/images/Image133_3.png')" class="image23" alt="Image Asset 133" />
                    </div>
                    <div class="main15">
                      <div class="subSection9">
                        <img :src="require('./../assets/images/Image136.png')" class="image24" alt="Image Asset 136" />
                      </div>

                      <img v-show="subSectionNum == 1" :src="require('./../assets/images/Image137_2.png')" class="image25" alt="Image Asset 137" />
                      <img v-show="subSectionNum == 2" :src="require('./../assets/images/Image137.png')" class="image25" alt="Image Asset 137" />
                      <img v-show="subSectionNum == 3" :src="require('./../assets/images/Image137_3.png')" class="image25" alt="Image Asset 137" />
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
  
          </div>
          <div class="kecheng3-page">
            <div class="image">
              <p class="text8"><span class="blue">就业</span>方向（部分）</p>
              <div class="wrap">
                <div class="container">
                  <div class="content">
                    <img
                      :src="require('./../assets/aigcImg/kc3-Image7.png')"
                      class="image1"
                      alt="Image Asset 7"
                    />
                    <p class="text">多模态算法工程师</p>
                  </div>
                </div>
                <div class="container1">
                  <div class="content1">
                    <img
                      :src="require('./../assets/aigcImg/kc3-Image11.png')"
                      class="image2"
                      alt="Image Asset 11"
                    />
                    <p class="text1">平面设计</p>
                  </div>
                </div>
                <div class="container2">
                  <div class="content2">
                    <div class="main">
                      <img
                        :src="require('./../assets/aigcImg/kc3-Image16.png')"
                        class="image3"
                        alt="Image Asset 16"
                      />
                    </div>
                    <p class="text2">UI设计</p>
                  </div>
                </div>
                <div class="container3">
                  <div class="content3">
                    <img
                      :src="require('./../assets/aigcImg/kc3-Image20.png')"
                      class="image4"
                      alt="Image Asset 20"
                    />
                    <p class="text3">新媒体运营</p>
                  </div>
                </div>
              </div>
              <div class="wrap1">
                <div class="container4">
                  <div class="content4">
                    <img
                      :src="require('./../assets/aigcImg/kc3-Image25.png')"
                      class="image5"
                      alt="Image Asset 25"
                    />
                    <p class="text4">游戏设计</p>
                  </div>
                </div>
                <div class="container5">
                  <div class="content5">
                    <img
                      :src="require('./../assets/aigcImg/kc3-Image29.png')"
                      class="image6"
                      alt="Image Asset 29"
                    />
                    <p class="text5">品牌设计</p>
                  </div>
                </div>
                <div class="container6">
                  <div class="content6">
                    <img
                      :src="require('./../assets/aigcImg/kc3-Image33.png')"
                      class="image7"
                      alt="Image Asset 33"
                    />
                    <p class="text6">电商</p>
                  </div>
                </div>
                <div class="container7"><p class="text7">・・・</p></div>
              </div>
            </div>
          </div>
  
          <div class="kecheng4">
            <p class="kecheng4-title"><span class="blue">就业</span>案例</p>
            <div class="kecheng4-wrap">
              <el-carousel :interval="3000" arrow="never" height="450px">
                <el-carousel-item v-for="(item, index) in caseItem" :key="index">
                  <div class="kecheng4-container">
                    <div class="kecheng4-image" :style="'backgroundImage: url('+item.img+')'">
                      <div class="kecheng4-content">
                        <div class="kecheng4-main">
                          <div class="kecheng4-section">
                            <img
                              :src="item.head"
                              class="kecheng4-image1"
                              alt="Image Asset 9"
                            />
                            <p class="kecheng4-text1">{{ item.name }}</p>
                          </div>
                          <div class="kecheng4-section1">
                            <p class="kecheng4-text2" v-html="item.desc"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                </el-carousel-item>
              </el-carousel>
  
            </div>
          </div>
        </div>
      </div>
  
  
      <el-dialog title="" width="480px" top="30vh" custom-class="yuyueDialog" :visible.sync="yuyueDialogVisible">
        <div class="yuyue-page">
          <div class="yuyue-wrap">
            <img
              :src="require('./../assets/images/yuyueImage3.png')"
              class="yuyue-image"
              alt="Image Asset 3"
              @click="yuyueDialogVisible = false"
            />
            <p class="yuyue-text"><span>立即扫码</span>预约今晚讲座</p>
          </div>
          <img
            :src="require('./../assets/images/yuyueImage5.png')"
            class="yuyue-image1"
            alt="Image Asset 5"
          />
        </div>
      </el-dialog>

  
  
  
    </div>
  </template>
  <script>
  import navpage from './../components/nav.vue'
  
  export default {
    components: { navpage },
    data() {
      return {
        cur: 1,
        szActive: 1,
        subSectionNum:1,
        yuyueDialogVisible: false,
        mllmB1:[
          [{title:'多模态大模型应用纵览',val:''},
            {title:'AI创作内容生成：',val:'短视频文案与剧本创作'},
            {title:'AI视觉艺术创作：',val:'从文字到图像（一）'},
          ],
          [{title:'AI视觉艺术创作：',val:'从文字到图像（二）'},
            {title:'快速上手PS：',val:'创意设计第一步'},
            {title:'AI视觉艺术创作：',val:'从文字到图像（三）'}
          ],
          [{title:'AI视觉艺术创作：',val:'从文字到图像（四）'},
            {title:'创意设计：',val:'从图层样式到实际应用'},
            {title:'AI音乐生成：',val:'用文字谱曲'}
          ],
          [{title:'文本转视频：',val:'AI驱动的视觉故事'},
            {title:'PS之滤镜',val:''},
            {title:'设计基础与经验分享',val:''}
          ],
          [{title:'多模态应用整合',val:''}
          ],
        ],
        mllmB2:[
          [{title:'多模态认知：',val:'MLLM的理论与应用'},
            {title:'剪辑',val:''},
            {title:'MLLM的进化之路：',val:'技术发展与创新'},
          ],
          [{title:'AI图像理解：',val:'多模态模型的实际应用'},
            {title:'视觉创作新视角：',val:'MLLM在图像生成中的应用（一）'},
            {title:'视觉创作新视角：',val:'MLLM在图像生成中的应用（二）'}
          ],
          [{title:'从开源到创意：',val:'视频生成模型解析与应用'},
            {title:'API驱动的创意：',val:'AI创作技术实战'},
          ],
        ],
        mllmB3:[
          [{title:'创意工具开发：',val:'开源AI创作的实现'},
            {title:'视觉创作工具：',val:'开源文生图开发'},
            {title:'音频创作：',val:'有声应用开发'},
          ],
          [{title:'从文字到影像：',val:'开源文生视频工具开发'},
            {title:'多模态整合：',val:'开发框架中的MLLM集成'},
          ],
        ],
        mllmB4:[
          [{title:'图像生成进阶：',val:'开源文生图工具优化'},
            {title:'角色定制：',val:'文生图模型的个性化微调'},
            {title:'范式优化：',val:'统一微调策略'},
          ],
          [{title:'面试辅导',val:''},
          ],
        ],
        caseItem:[
          {
            name:'孙同学-动画设计专升本-工作1年',
            head: require('./../assets/aigcImg/kc4-head1.png'),
            img: require('./../assets/aigcImg/kc4-bg1.png'),
            desc: `
              孙同学2023年从吉林某动画学院毕业，主修动画设计专业，现年23岁。上一份工作是曾担任美术指导，年薪为8万元。虽然自己在这份工作上感觉做的挺好的，但她更明白当前具备的能力不足以支撑自己进入更好的平台，未来收入也很难有进一步的提升。
              <br /><br />为了提高竞争力，孙同学于2024年5月参加了近屿智能的AIGC多模态大模型应用专家课程。通过课程学习，她掌握了AI在游戏设计和艺术创作中的多种应用，尤其是在图标设计、游戏UI设计、原画概念等领域通过AI辅助工具能取得她之前不敢想象的效率和作品精美度。她的作品在技术与艺术之间达到了非常好的平衡。
              <br /><br />最终，孙同学在2024年8月成功跳槽入职安徽芜湖某科技有限公司，担任游戏美术设计师，年薪增长至15万元。她现在的工作主要涉及游戏UI设计、写实风格的美术创作等，相信她在未来的职业生涯中将会取得更辉煌的成绩！            `,
          },
          {
            name:'赵同学-理工科大专-应届毕业生',
            head: require('./../assets/aigcImg/kc4-head2.png'),
            img: require('./../assets/aigcImg/kc4-bg2.png'),
            desc: `
              赵同学，23岁，河南某综合性大学的理工科相关专业大专毕业生。作为一名应届毕业生，他对于未来的职业规划充满了期待和好奇。2024年5月，赵同学加入了近屿智能的AIGC多模态大模型应用工程师课程，希望通过学习AIGC技术来为自己未来的职业生涯增添亮点。
              <br /><br />在四个月的学习过程中，赵同学表现出了极高的学习热情和创新能力。他不仅系统地学习了AIGC技术的理论知识，还积极参与课程中的实践项目，将所学知识应用于实际问题解决中。2024年9月，他成功被深圳一家创新型科技公司录用为AI视觉设计师，月薪18K，实现了从学生到专业人士的华丽转变。`
          },
          {
            name:'吴同学-服装设计本科-工作3年',
            head: require('./../assets/aigcImg/kc4-head3.png'),
            img: require('./../assets/aigcImg/kc4-bg3.png'),
            desc: `吴同学，26岁，毕业于安徽某学院的服装设计专业。毕业后一直从事的是视频剪辑师，积累了一定的工作经验后，他决定转行进入AI领域。2024年4月，吴同学加入了近屿智能的AIGC多模态大模型应用工程师课程，希望通过学习AIGC技术来实现自己的职业转型。
              <br /><br />在三个月的学习过程中，吴同学展现出了极强的学习能力和适应能力。他不仅快速掌握了AIGC技术的理论知识，还通过课程中的实战项目积累了宝贵的实践经验。2024年7月，他成功被北京一家广告公司录用为AI广告创意设计师，月薪14K，实现了从传统行业到AI领域的成功转型。`
          },
          {
            name:'胡同学-设计本科-工作2年',
            head: require('./../assets/aigcImg/kc4-head4.png'),
            img: require('./../assets/aigcImg/kc4-bg4.png'),
            desc: `胡同学，25岁，毕业于西安某艺术学院设计相关专业。作为一名有着丰富设计经验的UI设计师，他不满足于现有的职业成就，渴望在AIGC领域探索更多可能性。2024年3月，胡同学加入了近屿智能的AIGC多模态大模型应用工程师课程，希望能够将设计经验与AIGC技术相结合。
              <br /><br />在四个月的学习过程中，胡同学将设计理念与AIGC技术完美融合，创作出多个令人印象深刻的作品。2024年7月，他成功被杭州一家创新型公司录用为Stable Diffusion工程师，月薪17K，不仅拓宽了自己的职业道路，也为设计行业带去了新的活力。`
          },
          {
            name:'朱同学-设计本科-工作6年',
            head: require('./../assets/aigcImg/kc4-head5.png'),
            img: require('./../assets/aigcImg/kc4-bg5.png'),
            desc: `朱同学，30岁，毕业于山东某综合性大学设计相关专业。作为一名在职场中工作了几年却成绩一般的平面设计师，他一直在寻找能够将传统设计技能与新兴技术结合的机会，希望这样来提高自己职业价值。2024年6月，朱同学加入了近屿智能的AIGC多模态大模型应用工程师课程，希望能够通过学习AIGC技术来提升自己的设计能力。
              <br /><br />学习期间，朱同学不仅深入理解了AIGC技术的原理和应用，还积极探索如何将这些技术应用于设计创作中。2024年9月，他成功转型为艺术创作师，并以15K的薪资在上海一家广告公司就职，开启了一段全新的艺术创作之旅。`
          },
        ]
      };
    },
    mounted() {
      document.title = "AIGC大模型培训";
    },
    methods: {
      openBaidu() {
        const aff = document.getElementById("aff-im-root");
        const btn = aff.getElementsByClassName("embed-icon-pcIcon3")[0];
        btn.click();
      },
      handleClick(type) {
        if (type && type === "openBaidu") {
          this.openBaidu();
          // this[type]()
        }
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .blue{
    color: #1F6AFB;
  }
  .aigc-main {
    max-width: 100%;
    min-width: 1280px;

    .banner-page {
      width: 100%;
      height: 600px;
      overflow: hidden;
      // padding-top: 80px;
      background: rgba(244,247,252,1);
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 1920px;
        height: 600px;
        box-sizing: border-box;
        background-image: url('./../assets/aigcImg/kc0-Image2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .image1 {
          height: 58.37px;
          margin-top: 172.67px;
        }
        .text {
          margin-top: 45.96px;
          font-family: 'PingFang SC';
          font-size: 24px;
          text-align: center;
          color: rgba(51,51,51,1);
          font-weight: 500;
          white-space: pre-line;
        }
        .wrap {
          width: 179px;
          height: 48px;
          border-radius: 61px;
          box-sizing: border-box;
          background: rgba(31,106,251,1);
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          margin-top: 50px;
          cursor: pointer;
          .image2 {
            width: 105.01px;
            height: 16.63px;
            margin-right: 8px;
          }
          .image3 {
            width: 16px;
            height: 16px;
            overflow: hidden;
            box-sizing: border-box;
          }
        }
      }
    }



    .kecheng-page{
      margin-top: -120px;
      border-radius: 16px;
      box-sizing: border-box;
      background: #F4F7FC;
      .kecheng-title {
        margin: 0 auto 40px;
        width: 1280px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 1280px;
        height: 148px;
        overflow: hidden;
        border-radius: 16px;
        box-sizing: border-box;
        background: rgb(255, 255, 255);
        position: relative;
  
  
        p {
          margin-left: 39.53px;
          margin-bottom: 39.26px;
          width: 1200px;
          font-family: 'PingFang SC';
          font-size: 24px;
          text-align: center;
          color: rgba(0,0,0,1);
          line-height: 33.60px;
          font-weight: 500;
        }
      }
      .kecheng-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        >img{
          margin: 20px auto;
          width: 1280px;
        }
        .kecheng0-page {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background: #F4F7FC;
          padding-bottom: 70px;
          >div{
            padding-bottom: 32px;
          }
          span{
            color: #666;
          }
          .wrap {
            width: 1280px;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(239,245,255,1) 0%,rgba(255,255,255,1) 100%);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .container {
              width: 270px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 40px;
              margin-left: 40px;
              .image {
                width: 32px;
                height: 32px;
                overflow: hidden;
                border-radius: 28px;
                box-sizing: border-box;
                background: rgba(31,106,251,1);
                margin-right: 12px;
              }
              .text {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                color: rgba(0,0,0,1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container1 {
              width: 1200px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 20px;
              margin-left: 40px;
              .content {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                .main {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image1 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text1 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                    span{
                      color: rgba(102,102,102,1);
                    }
                  }
                }
                .main1 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image2 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text2 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main2 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image3 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text3 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
              .content1 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                .main3 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image4 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text4 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                    span{
                      color: rgba(102,102,102,1);
                    }
                  }
                }
                .main4 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image5 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text5 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                    span{
                      color: rgba(102,102,102,1);
                    }
                  }
                }
                .main5 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image6 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text6 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
              .content2 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .main6 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image7 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text7 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .wrap1 {
            width: 1280px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(241,255,249,1) 0%,rgba(255,255,255,1) 100%);
            margin-top: 20px;
            padding-bottom: 70px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .container2 {
              width: 385px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 40px;
              margin-left: 40px;
              .image8 {
                width: 32px;
                height: 32px;
                overflow: hidden;
                border-radius: 28px;
                box-sizing: border-box;
                background: rgba(36,219,143,1);
                margin-right: 12px;
              }
              .text8 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                color: rgba(0,0,0,1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container3 {
              width: 1200px;
              height: 50px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 20px;
              margin-left: 40px;
              .content3 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                .main7 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image9 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text9 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main8 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image10 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text10 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main9 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image11 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text11 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
              .content4 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .main10 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image12 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text12 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main11 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image13 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text13 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main12 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image14 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text14 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .wrap2 {
            width: 1280px;
            height: 184px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(255,248,239,1) 0%,rgba(255,255,255,1) 100%);
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .container4 {
              width: 275px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 40px;
              margin-left: 40px;
              .image15 {
                width: 32px;
                height: 32px;
                overflow: hidden;
                border-radius: 28px;
                box-sizing: border-box;
                background: rgba(253,164,43,1);
                margin-right: 12px;
              }
              .text15 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                color: rgba(0,0,0,1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container5 {
              width: 1200px;
              height: 50px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 20px;
              margin-left: 40px;
              .content5 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                .main13 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image16 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text16 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main14 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image17 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text17 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main15 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image18 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text18 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
              .content6 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .main16 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image19 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text19 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main17 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image20 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text20 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .wrap3 {
            width: 1280px;
            height: 184px;
            overflow: hidden;
            border-radius: 16px;
            border-color: rgba(255,255,255,1);
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(255,242,240,1) 0%,rgba(255,255,255,1) 100%);
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .container6 {
              width: 275px;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 40px;
              margin-left: 40px;
              .image21 {
                width: 32px;
                height: 32px;
                overflow: hidden;
                border-radius: 28px;
                box-sizing: border-box;
                background: rgba(234,66,28,1);
                margin-right: 12px;
              }
              .text21 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                color: rgba(0,0,0,1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .container7 {
              width: 1200px;
              height: 50px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 20px;
              margin-left: 40px;
              .content7 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                .main18 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image22 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text22 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main19 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image23 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text23 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
                .main20 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image24 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text24 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
              .content8 {
                width: 1200px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .main21 {
                  width: 400px;
                  height: 20px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image25 {
                    width: 6px;
                    height: 6px;
                    margin-right: 6px;
                  }
                  .text25 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 19.60px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
  

        .kecheng1-page {
          width: 100%;
          height: 1027px;
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          .image {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 1027px;
            box-sizing: border-box;
            background-image: url('./../assets/aigcImg/kc1-Image2.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .text {
              margin-top: 80px;
              width: 1280px;
              font-family: 'PingFang SC';
              font-size: 24px;
              text-align: center;
              color: rgba(0,0,0,1);
              line-height: 33.60px;
              font-weight: 400;
            }
            .wrap {
              width: 1280px;
              height: 723px;
              margin-top: 28px;
              position: relative;
              .container {
                width: 630px;
                height: 217px;
                border-radius: 8px 20px 8px 20px;
                border-color: rgba(255,255,255,1);
                border-style: solid;
                border-width: 2px;
                box-sizing: border-box;
                box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
                background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .content {
                  width: 108px;
                  height: 34px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 40px;
                  margin-left: 40px;
                  .image1 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-right: 12px;
                  }
                  .text1 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
                .text2 {
                  margin-top: 16px;
                  margin-left: 40px;
                  width: 540px;
                  height: 87px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  color: rgba(102,102,102,1);
                  line-height: 22px;
                  font-weight: 400;
                }
              }
              .container1 {
                width: 630px;
                height: 217px;
                border-radius: 8px 20px 8px 20px;
                border-color: rgba(255,255,255,1);
                border-style: solid;
                border-width: 2px;
                box-sizing: border-box;
                box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
                background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
                position: absolute;
                top: 237px;
                left: 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .content1 {
                  width: 155px;
                  height: 34px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 40px;
                  margin-left: 40px;
                  .image2 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-right: 12px;
                  }
                  .text3 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
                .text4 {
                  margin-top: 16px;
                  margin-left: 40px;
                  width: 540px;
                  height: 87px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  color: rgba(102,102,102,1);
                  line-height: 22px;
                  font-weight: 400;
                }
              }
              .container2 {
                width: 630px;
                height: 217px;
                border-radius: 8px 20px 8px 20px;
                border-color: rgba(255,255,255,1);
                border-style: solid;
                border-width: 2px;
                box-sizing: border-box;
                box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
                background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
                position: absolute;
                top: 474px;
                left: 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .content2 {
                  width: 155px;
                  height: 34px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 40px;
                  margin-left: 40px;
                  .image3 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-right: 12px;
                  }
                  .text5 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
                .text6 {
                  margin-top: 16px;
                  margin-left: 40px;
                  width: 540px;
                  height: 87px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  color: rgba(102,102,102,1);
                  line-height: 22px;
                  font-weight: 400;
                }
              }
              .container3 {
                width: 630px;
                height: 217px;
                overflow: hidden;
                border-radius: 8px 20px 8px 20px;
                border-width: 2px;
                padding: 2px;
                box-sizing: border-box;
                box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.15);
                background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
                position: absolute;
                top: 474px;
                left: 650px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                border-radius: 8px 20px 8px 20px;

                .image4 {
                  // margin-top: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 630px;
                  height: 217px;
                  background-image: url('./../assets/aigcImg/kc1-Image21.png');
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  .main {
                    width: 630px;
                    opacity: 0.800000011920929;
                    box-sizing: border-box;
                    background: linear-gradient(270deg, rgba(136,25,246,1) 0%,rgba(40,25,246,1) 100%);
                    min-height: 217px;
                  }
                }
              }
              .container4 {
                width: 630px;
                height: 217px;
                border-radius: 8px 20px 8px 20px;
                border-color: rgba(255,255,255,1);
                border-style: solid;
                border-width: 2px;
                box-sizing: border-box;
                box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
                background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
                position: absolute;
                top: 0px;
                left: 650px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .content3 {
                  width: 108px;
                  height: 34px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 40px;
                  margin-left: 40px;
                  .image5 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-right: 12px;
                  }
                  .text8 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
                .text9 {
                  margin-top: 16px;
                  margin-left: 40px;
                  width: 540px;
                  height: 87px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  color: rgba(102,102,102,1);
                  line-height: 22px;
                  font-weight: 400;
                }
                .content4 {
                  width: 630px;
                  height: 217px;
                  border-radius: 8px 20px 8px 20px;
                  border-color: rgba(255,255,255,1);
                  border-style: solid;
                  border-width: 2px;
                  box-sizing: border-box;
                  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05);
                  background: linear-gradient(180deg, rgba(230,239,252,1) 0%,rgba(255,255,255,1) 100%);
                  margin-top: 60px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .main1 {
                    width: 155px;
                    height: 34px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 40px;
                    margin-left: 40px;
                    .image6 {
                      width: 24px;
                      height: 24px;
                      overflow: hidden;
                      box-sizing: border-box;
                      margin-right: 12px;
                    }
                    .text10 {
                      font-family: 'PingFang SC';
                      font-size: 24px;
                      white-space: nowrap;
                      color: rgba(0,0,0,1);
                      line-height: 33.60px;
                      font-weight: 500;
                    }
                  }
                  .text11 {
                    margin-top: 16px;
                    margin-left: 40px;
                    width: 540px;
                    height: 87px;
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    color: rgba(102,102,102,1);
                    line-height: 22px;
                    font-weight: 400;
                  }
                }
                .text7 {
                  margin-top: 80px;
                  margin-left: 80px;
                  width: 480px;
                  height: 87px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  text-align: center;
                  color: rgba(255,255,255,1);
                  line-height: 22px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .kecheng2-page {
            width: 100%;
            height: 630px;
            overflow: hidden;
            box-sizing: border-box;
            margin-top: 80px;

            .image20 {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 630px;
              background-image: url('./../assets/images/Image118.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;

              .image21 {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                height: 630px;
                background-image: url('./../assets/images/Image119.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                .image21_1{
                  margin: 0 auto;
                }
                .text41 {
                  margin-top: 80px;
                  margin-left: 888px;
                  font-family: 'PingFang SC';
                  font-size: 36px;
                  white-space: nowrap;
                  text-align: center;
                  color: rgba(255, 255, 255, 1);
                  line-height: 50.40px;
                  font-weight: 500;
                }

                .section13 {
                  width: 1280px;
                  height: 424px;
                  margin-top: 16px;
                  
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;

                  .main14 {
                    width: 447px;
                    height: 360px;
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    .subSection6 {
                      width: 447px;
                      height: 56px;
                      border-radius: 12px;
                      border-width: 1px;
                      box-sizing: border-box;
                      display: flex;
                      justify-content: flex-start;
                      align-items: flex-start;

                      .block {
                        width: 118px;
                        height: 34px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 8px;
                        margin-left: 40px;

                        .text38 {
                          font-family: 'PingFang SC';
                          font-size: 24px;
                          white-space: nowrap;
                          color: rgba(255, 255, 255, 1);
                          line-height: 33.60px;
                          font-weight: 400;
                        }
                      }
                    }

                    .subSection7 {
                      width: 447px;
                      height: 56px;
                      margin-top: 16px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;

                      .block1 {
                        width: 196px;
                        height: 40px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        margin-left: 40px;

                        .image22 {
                          width: 40px;
                          height: 40px;
                          box-sizing: border-box;
                          margin-right: 12px;
                        }

                        .text39 {
                          font-family: 'PingFang SC';
                          font-size: 24px;
                          white-space: nowrap;
                          color: rgba(255, 255, 255, 1);
                          line-height: 33.60px;
                          font-weight: 500;
                        }
                      }
                    }

                    .subSection8 {
                      width: 447px;
                      height: 56px;
                      border-radius: 12px;
                      border-width: 1px;
                      box-sizing: border-box;
                      margin-top: 16px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: flex-start;

                      .block2 {
                        width: 264px;
                        height: 34px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 8px;
                        margin-left: 40px;

                        .text40 {
                          font-family: 'PingFang SC';
                          font-size: 24px;
                          white-space: nowrap;
                          color: rgba(255, 255, 255, 1);
                          line-height: 33.60px;
                          font-weight: 400;
                        }
                      }
                    }
                    .subSectionNum{
                      border-radius: 12px;
                      border-color: rgba(31, 106, 251, 1);
                      border-style: solid;
                      border-width: 2px;
                      box-sizing: border-box;
                      background: rgba(31, 106, 251, 0.05);
                    }
                    .image23 {
                      width: 447px;
                      height: 132px;
                      margin-top: 28px;
                    }
                  }

                  .main15 {
                    // width: 753px;
                    // height: 360px;
                    // overflow: hidden;
                    padding: 8px;
                    border-radius: 8px;
                    border-width: 4px;
                    box-sizing: border-box;
                    box-shadow: 0px 8px 32px 0px rgba(0, 18, 53, 0.25);
                    background: rgba(55, 55, 55, 1);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    .subSection9 {
                      width: 753px;
                      height: 20px;
                      box-sizing: border-box;
                      background: rgba(82, 82, 82, 1);
                      margin-top: 24px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;

                      .image24 {
                        width: 40px;
                        height: 8px;
                        box-sizing: border-box;
                        margin-left: 20px;
                      }
                    }

                    .image25 {
                      width: 740px;
                      height: 327px;
                      overflow: hidden;
                      border-radius: 8px;
                      box-sizing: border-box;
                      background: rgba(255, 255, 255, 1);
                      margin-top: 6.50px;
                    }
                  }
                }
              }
            }
          
        }
        .kecheng3-page {
          width: 100%;
          height: 522px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          .image {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 522px;
            box-sizing: border-box;
            background-image: url('./../assets/aigcImg/kc3-Image2.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .text8 {
              margin-top: 80px;
              font-family: 'PingFang SC';
              font-size: 36px;
              white-space: nowrap;
              text-align: center;
              color: rgba(0,0,0,1);
              line-height: 50.40px;
              font-weight: 500;
            }
            .wrap {
              width: 1280px;
              height: 116px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 60px;
              .container {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(255,251,242,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-right: 20px;
                .content {
                  width: 192px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image1 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                  }
                  .text {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .container1 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(237,247,255,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-right: 20px;
                .content1 {
                  width: 96px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image2 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                  }
                  .text1 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .container2 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(245,244,255,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-right: 20px;
                .content2 {
                  width: 72px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .main {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .image3 {
                      width: 21.21px;
                      height: 24px;
                    }
                  }
                  .text2 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .container3 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(236,243,255,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                .content3 {
                  width: 120px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image4 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                  }
                  .text3 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
            }
            .wrap1 {
              width: 1280px;
              height: 116px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: 20px;
              .container4 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(244,250,254,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-right: 20px;
                .content4 {
                  width: 96px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image5 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                  }
                  .text4 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .container5 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(255,250,241,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-right: 20px;
                .content5 {
                  width: 96px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image6 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                  }
                  .text5 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .container6 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(241,242,255,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-right: 20px;
                .content6 {
                  width: 48px;
                  height: 68px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .image7 {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                  }
                  .text6 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(0,0,0,1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }
              .container7 {
                width: 305px;
                height: 116px;
                overflow: hidden;
                border-radius: 16px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                .text7 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(0,0,0,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }
            }
          }
        }
        


        .kecheng4 {
          // width: 1280px;
          height: 632px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .kecheng4-title {
            font-family: 'PingFang SC';
            font-size: 36px;
            white-space: nowrap;
            text-align: center;
            margin: 42px 0 0;
            font-weight: 500;
          }
          .kecheng4-wrap {
            margin-top: 40.24px;
            width: 1280px;
            height: 400px;
            .kecheng4-container {
              width: 1280px;
              height: 400px;
              // overflow: hidden;
              box-sizing: border-box;
              // background: rgba(0,0,0,1);
              display: flex;
              justify-content: center;
              align-items: center;
              .kecheng4-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1280px;
                height: 400px;
                border-radius: 16px;
                // background-image: url('./../assets/aigcImg/kecheng4-Image1.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right center;
                .kecheng4-content {
                  width: 1280px;
                  height: 400px;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .kecheng4-main {
                    width: 800px;
                    height: 220px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: 40px;
                    margin-left: 40px;
                    .kecheng4-section {
                      width: 376px;
                      height: 48px;
                      box-sizing: border-box;
                      display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                      justify-content: flex-start;
                      align-items: center;
                      margin-bottom: 32px;
                      .kecheng4-image1 {
                        width: 48px;
                        height: 48px;
                        overflow: hidden;
                        border-radius: 38px;
                        box-sizing: border-box;
                        background: rgba(255,255,255,1);
                        margin-right: 20px;
                      }
                      .kecheng4-text1 {
                        font-family: 'PingFang SC';
                        font-size: 24px;
                        white-space: nowrap;
                        color: rgba(255,255,255,1);
                        line-height: 33.60px;
                        font-weight: 500;
                      }
                    }
                    .kecheng4-section1 {
                      width: 800px;
                      height: 140px;
                      box-sizing: border-box;
                      display: flex;
                      flex-direction: column;
                      flex-wrap: nowrap;
                      justify-content: flex-start;
                      align-items: flex-start;
                      .kecheng4-text2 {
                        margin-bottom: 20px;
                        width: 800px;
                        opacity: 0.8500000238418579;
                        font-family: 'PingFang SC';
                        font-size: 14px;
                        background-color: #ffffff;
                        line-height: 19.6px;
                        font-weight: 400;
                        padding: 16px;
                        border-radius: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
          .wrap1 {
            width: 248px;
            height: 4px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: 40.24px;
            .container1 {
              width: 32px;
              border-radius: 27px;
              box-sizing: border-box;
              background: rgba(0,0,0,0.15);
              min-height: 4px;
              margin-right: 8px;
            }
            .container2 {
              width: 48px;
              border-radius: 27px;
              box-sizing: border-box;
              background: rgba(31,106,251,1);
              min-height: 4px;
              margin-right: 8px;
            }
            .container3 {
              width: 32px;
              border-radius: 27px;
              box-sizing: border-box;
              background: rgba(0,0,0,0.15);
              min-height: 4px;
              margin-right: 8px;
            }
            .container4 {
              width: 32px;
              border-radius: 27px;
              box-sizing: border-box;
              background: rgba(0,0,0,0.15);
              min-height: 4px;
              margin-right: 8px;
            }
            .container5 {
              width: 32px;
              border-radius: 27px;
              box-sizing: border-box;
              background: rgba(0,0,0,0.15);
              min-height: 4px;
              margin-right: 8px;
            }
            .container6 {
              width: 32px;
              border-radius: 27px;
              box-sizing: border-box;
              background: rgba(0,0,0,0.15);
              min-height: 4px;
            }
          }
        }
  
  
      }
  
    }
    
  }
  </style>
  
  <style lang="less">
  .kecheng4-wrap{
  
    .el-carousel__indicators--horizontal{
      font-size: 16px;
      .el-carousel__indicator{
        .el-carousel__button{
        background-color: rgba(0, 0, 0, 0.15);
        width: 32px;
        }
      }
      .el-carousel__button{
        height: 4px;
        border-radius: 3px;
      }
      .is-active{
        .el-carousel__button{
        background-color: #1F6AFB;
        width: 48px;
        }
      }
    }
  }
  </style>
  