<template>
  <div class="page">
    <navpage class="nav"></navpage>
    <el-carousel :interval="5000" arrow="never" height="680px">
      <el-carousel-item>
        <div class="subBlock">
          <div class="carouselimage01">
            <img :src="require('./assets/images/Image309.png')" class="image87" alt="Image Asset 309" />
            <img :src="require('./assets/images/Image310.png')" class="image88" alt="Image Asset 310" @click="yuyueDialogVisible = true"/>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="subBlock">
          <div class="carouselimage02">
            <img
              :src="require('./assets/images/carouselImage3.png')"
              class="image1"
              alt="Image Asset 3"
            /><img
              :src="require('./assets/images/carouselImage4.png')"
              class="image2"
              alt="Image Asset 4"
              @click="yuyueDialogVisible = true"
            />
          </div>
        </div>
      </el-carousel-item>
      
      <el-carousel-item>
        <div class="subBlock">
          <div class="carouselimage03">
            <img
              :src="require('./assets/images/carouselImage3_3.png')"
              class="image1"
              alt="Image Asset 3"
            /><img
              :src="require('./assets/images/carouselImage3_4.png')"
              class="image2"
              alt="Image Asset 4"
              @click="yuyueDialogVisible = true"
            />
          </div>
        </div>
      </el-carousel-item>
      
      <el-carousel-item>
        <div class="subBlock">
          <div class="carouselimage04">
            <img
              :src="require('./assets/images/carouselImage4_3.png')"
              class="image1"
              alt="Image Asset 3"
            /><img
              :src="require('./assets/images/carouselImage4_4.png')"
              class="image2"
              alt="Image Asset 4"
              @click="yuyueDialogVisible = true"
            />
          </div>
        </div>
      </el-carousel-item>
      
    </el-carousel>

    <div class="container">

    <div class="image">
      
      <div class="subBlock1">
        <div class="image99">
          <p class="text78">AIGC蓝海，<span class="blue">高薪</span>就业机会等你把握</p>
          <div class="container17">
            <img :src="require('./assets/images/Image320.png')" class="image102" alt="Image Asset 320" />
            <p class="text81">行业前景广</p>
            <img :src="require('./assets/images/Image322.png')" class="image100" alt="Image Asset 322" />
            <p class="text79">人才需求大</p>
            <img :src="require('./assets/images/Image324.png')" class="image101" alt="Image Asset 324" />
            <p class="text80">薪资水平高</p>
          </div>
          <div class="container18">
            <p class="text82">
              《中国AIGC应用全景报告》预测，到2026年，中国AI市场规模将达千亿级别。2030年，我国AIGC应用将成为万亿规模市场。
            </p>
            <p class="text83">
              到2030年，中国的人工智能（AI）人才供应只有市场需求的三分之一，人才缺口将达500万。
            </p>
            <p class="text84">
              根据猎聘发布报告显示AIGC领域的职位招聘年薪平均超40万，AI行业的薪资随着经验的积累增长非常快。
            </p>
          </div>
        </div>
      </div>
      <div class="wrap">
        <p class="text"><span class="blue">灵活</span>授课安排，满足不同人群<span class="blue">多元化</span>学习需求</p>
        <div class="container">
          <div class="content" @mouseover="subSection2 = 1" :style="subSection2 == 1?'width:496px':''" @mouseout="subSection2 = 0">
            <div class="image1" v-show="subSection2 != 1">
              <div class="main">
                <p class="text1">0基础转行</p>
              </div>
            </div>
            <div class="image1_1" v-show="subSection2 == 1">
              <div class="image1_wrap">
                <div class="image1_container">
                  <p class="text">0基础转行</p>
                  <div class="image1_content"></div>
                  <p class="text1">
                    从零开始，帮助学员快速上手，打下坚实基础，掌握AI领域新知识。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="content1" @mouseover="subSection2 = 2" :style="subSection2 == 2?'width:496px':''" @mouseout="subSection2 = 0">
            <div class="image2" v-show="subSection2 != 2">
              <div class="main1">
                <p class="text2">应届毕业生</p>
              </div>
            </div>
            <div class="image2_2" v-show="subSection2 == 2">
              <div class="image2_wrap">
                <div class="image2_container">
                  <p class="text">应届毕业生</p>
                  <div class="image2_content"></div>
                  <p class="text1">
                    为即将步入职场的你量身定制，助力快速适应职场需求。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="content2" @mouseover="subSection2 = 3" :style="subSection2 == 3?'width:496px':''" @mouseout="subSection2 = 0">
            <div class="image3" v-show="subSection2 != 3">
              <div class="main2">
                <p class="text3">在职升职涨薪</p>
              </div>
            </div>
            <div class="image3_3" v-show="subSection2 == 3">
              <div class="image3_wrap">
                <div class="image3_container">
                  <p class="text">在职升职涨薪</p>
                  <div class="image3_content"></div>
                  <p class="text1">助力职场转型，掌握新技能，实现薪资跃升。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap1">
        <p class="text4">三大AIGC大模型<span class="blue">系列</span>培训课程</p>
        <div class="container1">
          <div class="image4">
            <div class="section"></div>
            <p class="text5">市场验证，不玩理论，培养实战人才</p>
            <p class="text6">
              AIGC大模型系列课程由在多模态大模型算法领域具有杰出成就的硕博导师以及近屿智能AI大模型实验室的资深工程师共同研发支持。
              <br />授课教师均是来自多伦多大学、哈尔滨工业大学、南京大学、墨尔本大学等国内985院校以及世界知名高等学府的博士和硕士。
            </p>
          </div>
        </div>
        <div class="container2">
          <div class="content3">
            <div class="section1">
              <div class="main3">
                <img :src="require('./assets/images/Image29.png')" class="image5" alt="Image Asset 29" />
                <div class="subSection">
                  <p class="text7">A系列</p>
                </div>
                <p class="text8">AIGC大模型应用工程师<br />课程</p>
              </div>
              <div class="main4" @click="$router.push('/aigc')">
                <div class="subSection1">
                  <p class="text9">立即获取课程详情</p>
                  <img :src="require('./assets/images/Image36.png')" class="image6" alt="Image Asset 36" />
                </div>
              </div>
              <div class="main5"></div>
            </div>
            <p class="text10">
              培养扎实的人工智能理论基础，专注于大模型集成、应用开发和指令训练，教授如何调用API、构建专业领域AI
              Agent，以及对大模型进行精准微调，以提升特定任务的商业应用性能。
            </p>
          </div>
          <div class="content4">
            <div class="section2">
              <div class="main6">
                <img :src="require('./assets/images/Image42.png')" class="image7" alt="Image Asset 42" />
                <div class="subSection2">
                  <p class="text11">B系列</p>
                </div>
                <p class="text12">AIGC多模态大模型应用工程师课程</p>
              </div>
              <div class="main7" @click="$router.push('/aigc1')">
                <div class="subSection3">
                  <p class="text13">立即获取课程详情</p>
                  <img :src="require('./assets/images/Image49.png')" class="image8" alt="Image Asset 49" />
                </div>
              </div>
              <div class="main8"></div>
            </div>
            <p class="text14">
              深入探索MLLM工具的使用、API调用、工具开发与增强，涵盖AI创作、视觉艺术、音乐生成及多模态技术，旨在打造精通AI技术应用与创新的多模态大模型工程师。
            </p>
          </div>
          <div class="content5">
            <div class="section3">
              <div class="main9">
                <img :src="require('./assets/images/Image55.png')" class="image9" alt="Image Asset 55" />
                <div class="subSection4">
                  <p class="text15">C系列</p>
                </div>
                <p class="text16">AIGC多模态大模型产品经理课程</p>
              </div>
              <div class="main10" @click="$router.push('/aigc2')">
                <div class="subSection5">
                  <p class="text17">立即获取课程详情</p>
                  <img :src="require('./assets/images/Image62.png')" class="image10" alt="Image Asset 62" />
                </div>
              </div>
              <div class="main11"></div>
            </div>
            <p class="text18">
              培养一个新兴的职业角色AI产品经理，专注于利用人工智能生成内容（AI
              Generated
              Content）的技术，涵盖从基础入门到实战项目，包括产品管理、竞品分析、AI工具应用、大模型技术原理及其在多模态领域的创新应用，旨在培养具备AI时代产品管理能力的专家。
            </p>
          </div>
        </div>
      </div>
      <div class="wrap2">
        <p class="text37">核心<span class="blue">亮点</span>详解，助力学员跃上新台阶</p>
        <div class="container3">
          <div class="content6">
            <div class="section4">
              <img :src="require('./assets/images/Image70.png')" class="image11" alt="Image Asset 70" />
              <p class="text19">双模式课堂</p>
            </div>
            <p class="text20">
              结合直播与录播课程，提供实时互动和答疑，以及随时随地的学习体验
            </p>
          </div>
          <div class="content7">
            <div class="section5">
              <img :src="require('./assets/images/Image75.png')" class="image12" alt="Image Asset 75" />
              <p class="text21">PBL地点</p>
            </div>
            <p class="text22">线上远程/线下建议到上海总部学习</p>
          </div>
          <div class="content8">
            <div class="section6">
              <img :src="require('./assets/images/Image80.png')" class="image13" alt="Image Asset 80" />
              <p class="text23">线上/线下自习室</p>
            </div>
            <p class="text24">
              提供线上腾讯会议自习室及线下上海总部自习室，均有老师督学和答疑
            </p>
          </div>
        </div>
        <div class="container4">
          <div class="content9">
            <div class="section7">
              <div class="main12">
                <img :src="require('./assets/images/Image87.png')" class="image14" alt="Image Asset 87" />
              </div>
              <p class="text25">实战检测机制</p>
            </div>
            <p class="text26">通过实际案例作业检测学习效果</p>
          </div>
          <div class="content10">
            <div class="section8">
              <div class="main13">
                <img :src="require('./assets/images/Image93.png')" class="image15" alt="Image Asset 93" />
              </div>
              <p class="text27">全程伴学服务</p>
            </div>
            <p class="text28">从入学到学籍期结束提供全程学习辅助</p>
          </div>
          <div class="content11">
            <div class="section9">
              <img :src="require('./assets/images/Image98.png')" class="image16" alt="Image Asset 98" />
              <p class="text29">1V1技术答疑</p>
            </div>
            <p class="text30">
              专属学管老师长期跟进学习薄弱点，学习小助手答疑7x24小时AI技术支持
            </p>
          </div>
        </div>
        <div class="container5">
          <div class="content12">
            <div class="section10">
              <img :src="require('./assets/images/Image104.png')" class="image17" alt="Image Asset 104" />
              <p class="text31">积分奖励机制</p>
            </div>
            <p class="text32">通过学习和积极性行为获取积分，可在积分商城兑换</p>
          </div>
          <div class="content13">
            <div class="section11">
              <img :src="require('./assets/images/Image109.png')" class="image18" alt="Image Asset 109" />
              <p class="text33">就业面试保障</p>
            </div>
            <p class="text34">
              制定个性化就业方案，匹配简历优化及面试指导，至少5次岗位面试机会
            </p>
          </div>
          <div class="content14">
            <div class="section12">
              <img :src="require('./assets/images/Image114.png')" class="image19" alt="Image Asset 114" />
              <p class="text35">课程升级策略</p>
            </div>
            <p class="text36">
              学员可联系学管老师选择课程升级服务，补缴额外费用即可转入更高级别课程
            </p>
          </div>
        </div>
      </div>
      <div class="wrap3">
        <div class="image20">
          <div class="image21">
            <div class="image21_1">
            <p class="text41">实战项目</p>
            <div class="section13">
              <div class="main14">
                <div class="subSection6" :class="{ subSectionNum: subSectionNum == 1 }" @mouseover="subSectionNum = 1">
                  <div class="block">
                    <img v-show="subSectionNum == 1" :src="require('./assets/images/Image128_1.png')" class="image22" style="height: 31px;width: 36px;margin-right: 12px;" alt="Image Asset 128" />
                    <p class="text38">AI中医问答</p>
                  </div>
                </div>
                <div class="subSection7" :class="{ subSectionNum: subSectionNum == 2 }" @mouseover="subSectionNum = 2">
                  <div class="block1">
                    <img v-show="subSectionNum == 2" :src="require('./assets/images/Image128.png')" class="image22" alt="Image Asset 128" />
                    <p class="text39">声音克隆项目</p>
                  </div>
                </div>
                <div class="subSection8" :class="{ subSectionNum: subSectionNum == 3 }" @mouseover="subSectionNum = 3">
                  <div class="block2">
                    <img v-show="subSectionNum == 3" :src="require('./assets/images/Image128_2.png')" class="image22" style="height: 28px;width: 32px;margin-right: 12px;" alt="Image Asset 128" />
                    <p class="text40">多模态大模型本地化部署</p>
                  </div>
                </div>
                <img v-show="subSectionNum == 1" :src="require('./assets/images/Image133_1.png')" class="image23" alt="Image Asset 133" />
                <img v-show="subSectionNum == 2" :src="require('./assets/images/Image133.png')" class="image23" alt="Image Asset 133" />
                <img v-show="subSectionNum == 3" :src="require('./assets/images/Image133_3.png')" class="image23" alt="Image Asset 133" />
              </div>
              <div class="main15">
                <div class="subSection9">
                  <img :src="require('./assets/images/Image136.png')" class="image24" alt="Image Asset 136" />
                </div>

                <img v-show="subSectionNum == 1" :src="require('./assets/images/Image137_1.png')" class="image25" alt="Image Asset 137" />
                <img v-show="subSectionNum == 2" :src="require('./assets/images/Image137.png')" class="image25" alt="Image Asset 137" />
                <img v-show="subSectionNum == 3" :src="require('./assets/images/Image137_3.png')" class="image25" alt="Image Asset 137" />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="wrap4">
        <p class="text45">证书</p>
        <div class="container6">
          <div class="content15">
            <div class="section14">
              <div class="main16" :class="{ main16Active: subSection1 == 1 }" @mouseover="subSection1 = 1">
                <p class="text42">微软证书</p>
              </div>
              <div class="main17" :class="{ main16Active: subSection1 == 2 }" @mouseover="subSection1 = 2">
                <p class="text43">结业证书</p>
              </div>
            </div>
            <p class="text44" v-show="subSection1 == 1">
              提供免费的考试辅导服务：全面的微软认证考试准备，包括技术考试流程指导、讲师视频教程、考试题库等，帮助考生全面掌握考试所需技能和知识点。
            </p>
            <p class="text44" v-show="subSection1 == 2">
              完成阶段课程后颁发，由近屿智能JYI颁发，行业认可。涵盖AIGC大模型实战项目，在近屿智能JYI的学习旅程中，学员将经历从基础到进阶，再到实战的全面训练，每一步都为学员的专业成长和实际应用能力的提升打下坚实的基础。
            </p>
          </div>
          <img v-show="subSection1 == 1" :src="require('./assets/images/Image148.png')" class="image26" alt="Image Asset 148" />
          <img v-show="subSection1 == 2" :src="require('./assets/images/Image148_1.png')" class="image26" alt="Image Asset 148" />
        </div>
      </div>
      <div class="wrap5">
        <p class="text55">卓越<span class="blue">师资</span>阵容，共享独步秘籍</p>
        <div class="container7 scroll-container" ref="scrollContainer" @mouseenter="pauseScroll()" @mouseleave="startScroll()">
          <div class="content16 scroll-content" ref="scrollContent">
            <!-- <div class="section15">
              <div class="main18">
                <div class="subSection10">
                  <img :src="require('./assets/images/Image156.png')" class="image27" alt="Image Asset 156" />
                  <div class="block3">
                    <p class="text46">Aiden</p>
                    <p class="text47">AIGC大模型实验室主任</p>
                  </div>
                </div>
                <p class="text48">
                  哈尔滨工业大学人工智能博士8年以上NLP研究经验，主要研究方向包括自然语言生成、文本摘要、机器阅读理解；对开放问答评估、对话系统构建、口音识别等具有丰富经验。在AIGC大模型领域有着丰富的研发经验和成果。负责公司自研AIGC
                  HR行业大模型——近屿超脑的设计、增量预训练和微调等工作。能熟练运用各种技术手段构建性能卓越的AI大规模产品。
                </p>
              </div>
            </div>
            <div class="section16" v-for="(item, index) in teacherItem" :key="index">
              <div class="main19">
                <div class="subSection11">
                  <img :src="require('./assets/images/Image164.png')" class="image28" alt="Image Asset 164" />
                  <div class="block4">
                    <p class="text49">张立赛 Leon</p>
                    <p class="text50">近屿智能 AIGC大模型讲师</p>
                  </div>
                </div>
                <p class="text51">
                  哈工大人工智能博士，曾供职于国内顶级AI实验室-之江实验室，研究方向为文生图、跨模态生成等。他在CVPR、ACL、MMCIKM等顶级会议上发表过多篇文章。
                </p>
              </div>
            </div> -->
            <div class="section16" v-for="(item, index) in [...teacherItem,...teacherItem,...teacherItem,...teacherItem,...teacherItem,...teacherItem,...teacherItem,...teacherItem]" :key="index">
              <div class="main19">
                <div class="subSection11">
                  <img :src="item.img" class="image28" alt="Image Asset 164" />
                  <div class="block4">
                    <p class="text49">{{ item.name }}</p>
                    <p class="text50">{{ item.title }}</p>
                  </div>
                </div>
                <p class="text51">
                  {{ item.desc }}
                </p>
              </div>
            
            </div>

          </div>
        </div>
      </div>
      <div class="wrap6">
        <p class="text66">为什么<span class="blue">选择</span>我们？</p>
        <div class="container8">
          <div class="content17">
            <img :src="require('./assets/images/Image181.png')" class="image30" alt="Image Asset 181" />
            <p class="text56">专业的<br/>导师团队</p>
            <p class="text57">
              行业专家将贴身指导，帮助您攻克项目开发中的难题。
            </p>
          </div>
          <div class="content18">
            <img :src="require('./assets/images/Image185.png')" class="image31" alt="Image Asset 185" />
            <p class="text58">顶尖的<br/>A800算力支持</p>
            <p class="text59">
              为您的项目提供必要的技术支撑，让模型训练更加高效。
            </p>
          </div>
          <div class="content19">
            <img :src="require('./assets/images/Image189.png')" class="image32" alt="Image Asset 189" />
            <p class="text60">丰富的<br/>实践机会</p>
            <p class="text61">
              真实的业务场景让您的项目不再是空中楼阁，而是接地气的解决方案。
            </p>
          </div>
          <div class="content20">
            <img :src="require('./assets/images/Image193.png')" class="image33" alt="Image Asset 193" />
            <p class="text62">OJAC<br/>标准会员权益</p>
            <p class="text63">
              我们每个月都会举办Al领域的前沿讲座，分享最新的技术洞见，并提供Al职场的导航与未来的机遇速递。
            </p>
          </div>
          <div class="content21">
            <img :src="require('./assets/images/Image197.png')" class="image34" alt="Image Asset 197" />
            <p class="text64">OJAC<br/>高级会员权益</p>
            <p class="text65">
              参与我们的Al大模型探索者计划，我们将共同探索Al的边界，并通过实践案例塑造您的职业星辉路。
            </p>
          </div>
        </div>
      </div>
      <div class="wrap8">
        <p class="text69">AI得贤招聘官</p>
        <div class="container9">
          <img :src="require('./assets/images/Image203.png')" class="image41" alt="Image Asset 203" />
          <div class="content23">
            <div class="section19"></div>
            <div class="section20">
              <p class="text70">国际一流的AI团队，让HR的智能化成为现实</p>
              <p class="text71">
                AI得贤招聘官是上海近屿智能科技有限公司的核心产品，一款智慧招聘管理系统，区别于传统的智能招聘ATS，AI得贤招聘官具有AI多模态算法核心，能够替代人类面试官对候选人的核心价值观，通用素质，潜在领导力和专业素质进行基于视频面试的判断，是一款L5级别AIGC面试系统。该系统已经被包括三星中国、招商银行、西门子等众多大客户验证可以完全替代人类面试官进行招聘面试场景的最终决策。
              </p>
              <p class="text72">
                AI得贤招聘官已上架至SAP APP
                Center，在200个国家和地区，向SAP的全球客户提供AI招聘和面试科技解决方案。AI得贤招聘官也已登录钉钉开放平台，为3000多万家中小型客户提供千企千面的招聘自动化、AI视频面试、模拟编程、认知能力测试、职业性格测试、心理测评等服务。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap9">
        <p class="text73">500强<span class="blue">客户</span>的共同选择</p>
        <div style="min-width: 100%;overflow-y: hidden;">
          <customer :img-list="imgList"></customer>
        </div>
        <!-- <div class="container10">
          <img :src="require('./assets/images/Image213.png')" class="image42" alt="Image Asset 213" />
          <div class="content24">
            <img :src="require('./assets/images/Image215.png')" class="image43" alt="Image Asset 215" /><img
              :src="require('./assets/images/Image216.png')" class="image44" alt="Image Asset 216" /><img
              :src="require('./assets/images/Image217.png')" class="image45" alt="Image Asset 217" /><img
              :src="require('./assets/images/Image218.png')" class="image46" alt="Image Asset 218" /><img
              :src="require('./assets/images/Image219.png')" class="image47" alt="Image Asset 219" /><img
              :src="require('./assets/images/Image220.png')" class="image48" alt="Image Asset 220" /><img
              :src="require('./assets/images/Image221.png')" class="image49" alt="Image Asset 221" /><img
              :src="require('./assets/images/Image222.png')" class="image50" alt="Image Asset 222" />
          </div>
        </div>
        <div class="container11">
          <img :src="require('./assets/images/Image224.png')" class="image51" alt="Image Asset 224" /><img
            :src="require('./assets/images/Image225.png')" class="image52" alt="Image Asset 225" /><img
            :src="require('./assets/images/Image226.png')" class="image53" alt="Image Asset 226" /><img
            :src="require('./assets/images/Image227.png')" class="image54" alt="Image Asset 227" /><img
            :src="require('./assets/images/Image228.png')" class="image55" alt="Image Asset 228" /><img
            :src="require('./assets/images/Image229.png')" class="image56" alt="Image Asset 229" /><img
            :src="require('./assets/images/Image230.png')" class="image57" alt="Image Asset 230" /><img
            :src="require('./assets/images/Image231.png')" class="image58" alt="Image Asset 231" /><img
            :src="require('./assets/images/Image232.png')" class="image59" alt="Image Asset 232" /><img
            :src="require('./assets/images/Image233.png')" class="image60" alt="Image Asset 233" />
        </div>
        <div class="container12">
          <img :src="require('./assets/images/Image235.png')" class="image61" alt="Image Asset 235" />
          <div class="content25">
            <img :src="require('./assets/images/Image237.png')" class="image62" alt="Image Asset 237" /><img
              :src="require('./assets/images/Image238.png')" class="image63" alt="Image Asset 238" /><img
              :src="require('./assets/images/Image239.png')" class="image64" alt="Image Asset 239" /><img
              :src="require('./assets/images/Image240.png')" class="image65" alt="Image Asset 240" /><img
              :src="require('./assets/images/Image241.png')" class="image66" alt="Image Asset 241" /><img
              :src="require('./assets/images/Image242.png')" class="image67" alt="Image Asset 242" /><img
              :src="require('./assets/images/Image243.png')" class="image68" alt="Image Asset 243" /><img
              :src="require('./assets/images/Image244.png')" class="image69" alt="Image Asset 244" />
          </div>
          <img :src="require('./assets/images/Image245.png')" class="image70" alt="Image Asset 245" />
        </div>
        <div class="container13">
          <img :src="require('./assets/images/Image247.png')" class="image71" alt="Image Asset 247" /><img
            :src="require('./assets/images/Image248.png')" class="image72" alt="Image Asset 248" /><img
            :src="require('./assets/images/Image249.png')" class="image73" alt="Image Asset 249" /><img
            :src="require('./assets/images/Image250.png')" class="image74" alt="Image Asset 250" /><img
            :src="require('./assets/images/Image251.png')" class="image75" alt="Image Asset 251" /><img
            :src="require('./assets/images/Image252.png')" class="image76" alt="Image Asset 252" /><img
            :src="require('./assets/images/Image253.png')" class="image77" alt="Image Asset 253" />
          <div class="content26"></div>
          <div class="content27"></div>
          <div class="content28"></div>
        </div> -->
      </div>
      <div class="wrap10">
        <p class="text77">客户<span class="blue">案例</span></p>
        <div class="container14">
          <!-- <img :src="require('./assets/images/Image260.png')" class="image85" alt="Image Asset 260" /> -->
          <div class="content29">

            <el-carousel :interval="3000" arrow="always" indicator-position="none" type="card" height="600px">
              <el-carousel-item>
                <div class="section21">
                  <div class="main21">
                    <img :src="require('./assets/images/Image264.png')" class="image78" alt="Image Asset 264" /><img
                      :src="require('./assets/images/Image265.png')" class="image79" alt="Image Asset 265" />
                    <p class="text74">
                      西门子集团每年校招都会收到海量的候选人简历。AI得贤招聘官根据西门子集团的独特要求，与西门子集团的HR团队共同开展了双盲人机对比实验，最终设计出了定制化的AI简历筛选服务与多元全面的AI面试系统，使简历量精简至原来的53%，面试量下降至原来的30%-50%，面试的精确度提高至93%，最终招聘的成功率提升了15%-30%。
                    </p>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="section22">
                  <div class="main22">
                    <img :src="require('./assets/images/Image269.png')" class="image80" alt="Image Asset 269" /><img
                      :src="require('./assets/images/Image270.png')" class="image81" alt="Image Asset 270" />
                    <p class="text75">
                      为解决三星中国招聘量巨大、面试受时间空间局限大的难题，AI得贤招聘官引入了AI简历解析的功能，用AI算法代替HR向企业推荐优秀和合格的候选人，精简了面试流程，将面试量下降到5%，从而提升了候选人的应聘体验和雇主品牌形象，并大幅减少了面试官的工作量，缩短了招聘项目周期，提高了整个招聘效率。
                    </p>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="section23">
                  <div class="main23">
                    <img :src="require('./assets/images/Image274.png')" class="image82" alt="Image Asset 274" />
                    <div class="subSection13">
                      <img :src="require('./assets/images/Image276.png')" class="image83" alt="Image Asset 276" />
                    </div>
                    <p class="text76">
                      每逢招聘季美团都需要招聘大量的岗位，如何在减轻HR与业务领导面试量的同时保证面试的有效性及精准度一直是美团的一个焦点。在美团与AI得贤招聘官共同严密的人机对比实验等测试之中，AI得贤招聘官的打分准确率达到了95%，同时AI得贤招聘官通过专业的咨询团队对美团进行了组织诊断，业务梳理，并协同美团HR团队进行了深入的访谈沟通，为搭建了更为契合美团岗位需求的胜任力模型，有效地提高了美团的招聘人效，控制了美团的招聘成本。
                    </p>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="section23">
                  <div class="main23">
                    <img :src="require('./assets/images/Image274_1.png')" class="image82" alt="Image Asset 274" />
                    <div class="subSection13">
                      <img :src="require('./assets/images/Image274_2.png')" style="height:32px" class="image83" alt="Image Asset 276" />
                    </div>
                    <p class="text76">
                      中骏是中国排名前20的地产集团，中骏非常重视ATS系统的创新技术及产品研发团队的响应速度和咨询培训能力。AI得贤招聘官的服务标准和实施标准完全按照SAP的流程设计，完全符合中骏地产的需求，为中骏地产将招聘业务的流程数据打通至多个系统，满足了中骏地产不同系统间的业务流程设计无缝对接，获得了中骏团队从上至下的一致赞赏和认同。
                    </p>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="section23">
                  <div class="main23">
                    <img :src="require('./assets/images/Image275_1.png')" class="image82" alt="Image Asset 274" />
                    <div class="subSection13">
                      <img :src="require('./assets/images/Image275_2.png')" style="width:92px" class="image83" alt="Image Asset 276" />
                    </div>
                    <p class="text76">
                      中原银行由于面试流程长，处理速度比较慢，且技术面试官的水平层次不齐，对人才质量评估的标准不一，导致中原银行在面试上需要花费大量的时间经历，AI得贤招聘官针对中原银行的需求痛点，与中原银行一起建立了程序员的模型，并不断优化和调整算法技术，降低错杀率，最终将人机对比实验的精确度达到了95%以上，有效地节约了面试成本，实现了精准收割人才。
                    </p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- <img :src="require('./assets/images/Image278.png')" class="image84" alt="Image Asset 278" /> -->
        </div>
      </div>
      <div class="wrap7">
        <div class="image35">
          <div class="content22">
            <div class="section18">
              <p class="text67" @click="yuyueDialogVisible = true">0元领取试听课</p>
              <img :src="require('./assets/images/Image284.png')" class="image37" alt="Image Asset 284" />
            </div>
            <p class="text68">
              现在领取我们的<span class="blue">免费试听课程</span>，先人一步，拥抱AIGC大模型！
            </p>
          </div>
          <img :src="require('./assets/images/Image286.png')" class="image36" alt="Image Asset 286" />
        </div>
        <img :src="require('./assets/images/Image287.png')" class="image38" alt="Image Asset 287" />
        <div class="image39">
          <img src="https://file.aidexianzhaopinguan.com/resume/102146763614944468.png" class="image40" alt="Image Asset 289" />
        </div>
      </div>
    </div>

  </div>

 
  <el-dialog title="" width="480px" top="30vh" custom-class="yuyueDialog" :visible.sync="yuyueDialogVisible">
    <div class="yuyue-page">
      <div class="yuyue-wrap">
        <img
          :src="require('./assets/images/yuyueImage3.png')"
          class="yuyue-image"
          alt="Image Asset 3"
          @click="yuyueDialogVisible = false"
        />
        <p class="yuyue-text"><span>立即扫码</span>预约今晚讲座</p>
      </div>
      <img
        :src="require('./assets/images/yuyueImage5.png')"
        class="yuyue-image1"
        alt="Image Asset 5"
      />
    </div>
  </el-dialog>

</div>
</template>

<script>
import customer from './components/customer.vue'
import navpage from './components/nav.vue'

export default {
  components: { customer,navpage },
  name: "GeneratedComponent",
  data() {
    return {
      yuyueDialogVisible: false,
      subSectionNum: 1,
      subSection1: 1,
      subSection2: 0,
      scrollInterval: null,
      scrollPosition: 0,
      scrollSpeed: 1, // 滚动速度
      teacherItem: [
        {
          name: "Aiden",
          title: "AIGC大模型实验室主任",
          img: require('./assets/teacherImg/Image6.png'),
          desc: "哈尔滨工业大学人工智能博士\n8年以上NLP研究经验，主要研究方向包括自然语言生成、文本摘要、机器阅读理解；对开放问答评估、对话系统构建、口音识别等具有丰富经验。\n在AIGC大模型领域有着丰富的研发经验和成果。负责公司自研AIGC HR行业大模型——近屿超脑的设计、增量预训练和微调等工作。能熟练运用各种技术手段构建性能卓越的AI大规模产品。",
        },
        {
          name: "张立赛 Leon",
          title: "近屿智能 AIGC大模型讲师",
          img: require('./assets/teacherImg/Image14.png'),
          desc: "哈工大人工智能博士，曾供职于国内顶级AI实验室-之江实验室，研究方向为文生图、跨模态生成等。他在CVPR、ACL、MMCIKM等顶级会议上发表过多篇文章。",
        },
        {
          name: "王巍达 Vida",
          title: "近屿智能 AIGC大模型讲师",
          img: require('./assets/teacherImg/Image22.png'),
          desc: "哈工大人工智能硕士，有多年的语言模型、智能对话、语义理解、知识图谱、量化策略等领域的研究和实践经验。曾负责过智能问答机器人、百度日文输入法、百度商情、小度智能音箱等产品的算法研发工作。",
        },
        {
          name: "丁晋杰 Dino",
          title: "",
          img: require('./assets/teacherImg/Image31.png'),
          desc: "墨尔本大学（QS14）人工智能专业硕士，资深大模型研发工程师，主要研究方向为基于大模型的智能体构建，具备丰富的大模型训练、微调、集成、量化经验。目前主要负责包括销售智能体、游戏智能体、AI虚拟人在内的项目研发和大模型相关的理论、应用和开发培训。",
        },
        {
          name: "曹永凯",
          title: "近屿智能 AIGC大模型讲师",
          img: require('./assets/teacherImg/Image38.png'),
          desc: "毕业于南京邮电大学，具有多年基于深度学习的自然语言处理算法的实战和开发经验。目前主要从事人力资源领域相关的大语言模型应用开发工作，负责完成了基于大模型的简历解析、简历评价、人岗匹配和工作能力评估、基于知识库的海聊和销售助手等重要业务模型训练和搭建。深知业务痛点，对人力资源领域和大模型应用有独到见解。",
        },
        {
          name: "朱文韬 Andy",
          title: "近屿智能 AIGC大模型讲师",
          img: require('./assets/teacherImg/Image46.png'),
          desc: "985高校研究生主要研究以大语言模型为基础的类人智能技术和健康医疗大数据挖掘，具备深厚的大模型应用开发经验。参与并完成了包括基于私域大模型的金融对话系统、基于多模态大模型的图像修复与要素抽取系统等重要课题的研发工作。",
        },
        {
          name: "吴蒙 Mark",
          title: "近屿智能 AIGC大模型讲师",
          img: require('./assets/teacherImg/Image55.png'),
          desc: "美国人工智能top10高校研究生机器学习专业，曾在多家大厂做算法研究实习，主要研究多模态生成模型，扩散模型等。",
        },
        {
          name: "方小雷 Lucas",
          title: "多伦多大学硕士，近屿智能创始人",
          img: require('./assets/teacherImg/Image63.png'),
          desc: "负责产品的设计思维相关课程的研发，AI得贤招聘官SaaS系统从L2级别到L5级别的产品设计师。南京大学工商管理学士，多伦多大学Rotman商学院MBA。11年跨国地区(中国、德国、西班牙、英国和加拿大)人力资源管理经验。",
        },
        {
          name: "代妍 Mia",
          title: "",
          img: require('./assets/teacherImg/Image71.png'),
          desc: "资深猎头顾问，负责A1职场导航暨机遇速递项目的研发，作为职业生涯规划导师为学员推荐国央企和民营企业的工作机会。",
        },
        {
          name: "Vivian",
          title: "世界500强企业HR高管",
          img: require('./assets/teacherImg/Image79.png'),
          desc: "世界500强企业HR高管 国内知名大学英语系毕业，从事人力资源管理工作已将近18年。在FMCG、贸易、投资领域的TOP500强公司任职管理职务。领导并参与过多个大型人力资源组织发展改革项目（集团顶层职级体系迭代更新、OKR绩效考核体系导入后先固化再优化、多样化薪酬激励方案、大型建厂前期人员筹备项目等）。积累了丰富的变革管理经验，对于行业发展和人员职业生涯发展有着深刻的认识和见解。",
        },
        

      ],
      imgList: [
        [
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/H3C%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/LOGITECH%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/SAMSUNG%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/SIEMENS%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/TCL%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/TOTO%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/kao%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/nott%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%89%E5%8F%B6%E5%84%BF%E7%AB%A5%E5%8F%A3%E8%85%94%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%8A%E6%B5%B7%E4%BA%A4%E9%80%9A%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%8A%E6%B5%B7%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%8A%E6%B5%B7%E8%B4%A2%E7%BB%8F%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%96%E7%BA%AA%E5%8D%8E%E9%80%9A%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%8E%9F%E9%93%B6%E8%A1%8C%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%9B%BD%E5%A4%AA%E5%B9%B3%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%9B%BD%E9%93%B6%E8%A1%8C%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E5%A4%AE%E8%B4%A2%E7%BB%8F%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%B8%AD%E9%AA%8F%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E4%BA%A4%E6%8E%A7%E7%A7%91%E6%8A%80%402x.png",
        ],

        [
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%85%B4%E4%B8%9A%E9%93%B6%E8%A1%8C%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%8A%A0%E5%A4%9A%E5%AE%9D%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%8C%97%E4%BA%AC%E5%A4%96%E5%9B%BD%E8%AF%AD%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E5%AF%B9%E5%A4%96%E7%BB%8F%E6%B5%8E%E8%B4%B8%E6%98%93%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%8B%9B%E5%95%86%E9%93%B6%E8%A1%8C%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%90%BA%E7%A8%8B%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B5%99%E6%B1%9F%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B5%99%E6%B1%9F%E5%B7%A5%E5%95%86%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B7%B1%E5%9C%B3%E9%AB%98%E7%BA%A7%E4%B8%AD%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E6%B8%A9%E5%B7%9E%E7%A7%91%E6%8A%80%E8%81%8C%E4%B8%9A%E5%AD%A6%E9%99%A2%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E7%89%B9%E5%8F%98%E7%94%B5%E5%B7%A5%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E7%99%BD%E8%B1%A1%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E7%BE%8E%E5%9B%A2%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%87%8D%E5%BA%86%E5%A4%A7%E5%AD%A6%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%94%A6%E6%B1%9F%E9%85%92%E5%BA%97%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%98%BF%E5%8D%A1%E7%B4%A2%402x.png",
          "https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/coustom/coustom/%E9%9B%85%E5%B1%85%E4%B9%90%402x.png",
        ],
      ],
      animationFrameId: null,
      isAnimating: false,
      currentPos: 20,
    }
  },
  mounted() {
    this.startScroll();

  },
  beforeDestroy() {
    this.pauseScroll();
  },
  methods: {
    startScroll(num = 1) {
      if (num !== 1 || this.isAnimating) return;

      this.isAnimating = true;
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        const content = this.$refs.scrollContent;
        const containerWidth = container.offsetWidth;
        const contentWidth = content.offsetWidth;

        const scroll = () => {
          if (!this.isAnimating) return;

          if (this.currentPos <= -(100000+contentWidth + containerWidth + containerWidth)) {
            this.currentPos = 20;
          } else {
            this.currentPos -= 0.7; // 调整步长以控制滚动速度
          }

          content.style.transform = `translateX(${this.currentPos}px)`;

          this.animationFrameId = requestAnimationFrame(scroll);
        };

        scroll();
      });
    },

    pauseScroll() {
      if (!this.isAnimating) return;

      cancelAnimationFrame(this.animationFrameId);
      this.isAnimating = false;
    },

    resumeScroll() {
      if (this.isAnimating) return;

      this.isAnimating = true;
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        const content = this.$refs.scrollContent;
        const containerWidth = container.offsetWidth;
        const contentWidth = content.offsetWidth;

        const scroll = () => {
          if (!this.isAnimating) return;

          if (this.currentPos <= -(contentWidth + containerWidth + containerWidth)) {
            this.currentPos = 20;
          } else {
            this.currentPos -= 0.7; // 调整步长以控制滚动速度
          }

          content.style.transform = `translateX(${this.currentPos}px)`;

          this.animationFrameId = requestAnimationFrame(scroll);
        };

        scroll();
      });
    },

    stopScroll() {
      this.pauseScroll();
      this.currentPos = 20; // 重置位置
      const content = this.$refs.scrollContent;
      content.style.transform = `translateX(${this.currentPos}px)`;
    },
  }

};
</script>

<style lang="less" scoped>
p{
  margin: 0;
}
.blue{
  color: #1F6AFB;
}
.page {
  min-width: 1280px;
  // height: 100%;
  // overflow: hidden;
  box-sizing: border-box;
  // background: rgba(255, 255, 255, 1);
  .container{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }



  .image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    // height: 6708px;
    box-sizing: border-box;
    background-image: url('./assets/images/Image2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    >div{
      margin: 0 auto;
    }
    .wrap {
      width: 1280px;
      height: 550px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container {
        width: 1280px;
        height: 460px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .content {
          width: 416px;
          height: 460px;
          overflow: hidden;
          border-radius: 24px;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 1);
          display: flex;
          justify-content: center;
          align-items: center;

          .image1 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 460px;
            background-image: url('./assets/images/Image7.png');
            background-size: cover;
            background-repeat: no-repeat;

            .main {
              width: 100%;
              height: 460px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(40, 116, 232, 0) 49.5%, rgba(40, 116, 232, 1) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;

              .text1 {
                margin-bottom: 59px;
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                color: rgba(255, 255, 255, 1);
                line-height: 33.60px;
                font-weight: 400;
              }
            }
          }
          .image1_1 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 496px;
            height: 460px;
            background-image: url('./assets/images/Image7.png');
            background-size: cover;
            background-repeat: no-repeat;
            .image1_wrap {
              width: 496px;
              height: 460px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(40,116,232,1) 0%,rgba(40,116,232,0) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .image1_container {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 416px;
                height: 123px;
                .text {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(255,255,255,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
                .image1_content {
                  width: 416px;
                  box-sizing: border-box;
                  background: rgba(255,255,255,1);
                  min-height: 1px;
                  margin-top: 20px;
                }
                .text1 {
                  margin-top: 20px;
                  width: 416px;
                  font-family: 'PingFang SC';
                  font-size: 16px;
                  color: rgba(255,255,255,1);
                  line-height: 24px;
                  font-weight: 400;
                }
              }
            }
          }



        }

        .content1 {
          width: 416px;
          height: 460px;
          overflow: hidden;
          border-radius: 24px;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 1);
          margin-left: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .image2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 460px;
            background-image: url('./assets/images/Image11.png');
            background-size: cover;
            background-repeat: no-repeat;

            .main1 {
              width: 100%;
              height: 460px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(0, 163, 254, 0) 50.5%, rgba(0, 163, 254, 1) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;

              .text2 {
                margin-left: 147.50px;
                margin-bottom: 59px;
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                color: rgba(255, 255, 255, 1);
                line-height: 33.60px;
                font-weight: 400;
              }
            }
          }
          .image2_2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 496px;
            height: 460px;
            background-image: url('./assets/images/Image11.png');
            background-size: cover;
            background-repeat: no-repeat;
            .image2_wrap {
              width: 496px;
              height: 460px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(0,163,254,1) 0%,rgba(0,163,254,0) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .image2_container {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 416px;
                height: 99px;
                .text {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(255,255,255,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
                .image2_content {
                  width: 416px;
                  box-sizing: border-box;
                  background: rgba(255,255,255,1);
                  min-height: 1px;
                  margin-top: 20px;
                }
                .text1 {
                  margin-top: 20px;
                  width: 416px;
                  font-family: 'PingFang SC';
                  font-size: 16px;
                  white-space: nowrap;
                  color: rgba(255,255,255,1);
                  line-height: 24px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .content2 {
          width: 416px;
          height: 460px;
          overflow: hidden;
          border-radius: 24px;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 1);
          margin-left: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .image3 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 460px;
            background-image: url('./assets/images/Image15.png');
            background-size: cover;
            background-repeat: no-repeat;

            .main2 {
              width: 100%;
              height: 460px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(48, 76, 149, 0) 50%, rgba(48, 76, 149, 1) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;

              .text3 {
                margin-left: 135.50px;
                margin-bottom: 59px;
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                text-align: center;
                color: rgba(255, 255, 255, 1);
                line-height: 33.60px;
                font-weight: 400;
              }
            }
          }
          .image3_3 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 496px;
            height: 460px;
            background-image: url('./assets/images/Image15.png');
            background-size: cover;
            background-repeat: no-repeat;
            .image3_wrap {
              width: 496px;
              height: 460px;
              box-sizing: border-box;
              background: linear-gradient(180deg, rgba(48,76,149,1) 0%,rgba(48,76,149,0) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              .image3_container {
                margin-top: 40px;
                margin-right: 39.50px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 416px;
                height: 99px;
                .text {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(255,255,255,1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
                .image3_content {
                  width: 416px;
                  box-sizing: border-box;
                  background: rgba(255,255,255,1);
                  min-height: 1px;
                  margin-top: 20px;
                }
                .text1 {
                  margin-top: 20px;
                  width: 416px;
                  font-family: 'PingFang SC';
                  font-size: 16px;
                  white-space: nowrap;
                  color: rgba(255,255,255,1);
                  line-height: 24px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .wrap1 {
      width: 1280px;
      height: 738px;
      box-sizing: border-box;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .text4 {
        margin-left: 407px;
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container1 {
        width: 1280px;
        height: 176px;
        overflow: hidden;
        border-radius: 16px;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px 0px rgba(31, 106, 251, 0.25);
        background: rgba(255, 255, 255, 1);
        margin-top: 40px;
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .image4 {
          position: relative;
          width: 1280px;
          height: 176px;
          background-image: url('./assets/images/Image21.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .section {
            width: 1280px;
            opacity: 0.8999999761581421;
            box-sizing: border-box;
            background: rgba(31, 106, 251, 1);
            min-height: 176px;
            position: absolute;
            top: 0px;
            left: 0px;
          }

          .text5 {
            position: absolute;
            top: 40px;
            left: 448px;
            font-family: 'PingFang SC';
            font-size: 24px;
            white-space: nowrap;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            line-height: 32px;
            font-weight: 500;
          }

          .text6 {
            position: absolute;
            top: 88px;
            left: 40px;
            width: 1200px;
            font-family: 'PingFang SC';
            font-size: 16px;
            text-align: center;
            color: rgba(255, 255, 255, 0.65);
            font-weight: 400;
            white-space: pre-line;
          }
        }
      }

      .container2 {
        width: 1280px;
        height: 440px;
        margin-top: 32px;
        margin-left: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .content3 {
          width: 416px;
          height: 440px;
          overflow: hidden;
          border-radius: 24px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          position: relative;

          .section1 {
            width: 416px;
            height: 320px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(231, 228, 249, 1) 0%, rgba(231, 228, 249, 0) 100%);
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .main3 {
              width: 416px;
              height: 150px;
              position: relative;

              .image5 {
                width: 90px;
                height: 120px;
                overflow: hidden;
                box-sizing: border-box;
                position: absolute;
                top: 30px;
                left: 326px;
              }

              .subSection {
                width: 97px;
                height: 50px;
                border-radius: 0 12px 8px 12px;
                box-sizing: border-box;
                background: linear-gradient(91deg, rgba(92, 71, 162, 1) 2.58%, rgba(109, 83, 195, 1) 99.51%);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0px;
                left: 0px;

                .text7 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(255, 255, 255, 1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }

              .text8 {
                position: absolute;
                top: 74px;
                left: 40px;
                width: 336px;
                font-family: 'PingFang SC';
                font-size: 24px;
                color: rgba(92, 71, 162, 1);
                font-weight: 500;
                white-space: pre-line;
              }
            }

            .main4 {
              width: 164px;
              height: 36px;
              border-radius: 42px;
              border-color: rgba(92, 71, 162, 1);
              border-style: solid;
              border-width: 1px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-top: 16px;
              margin-left: 40px;
              cursor: pointer;

              .subSection1 {
                width: 132px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .text9 {
                  margin-right: 4px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  white-space: nowrap;
                  color: rgba(92, 71, 162, 1);
                  line-height: 19.60px;
                  font-weight: 400;
                }

                .image6 {
                  width: 16px;
                  height: 16px;
                  overflow: hidden;
                  box-sizing: border-box;
                }
              }
            }

            .main5 {
              width: 336px;
              box-sizing: border-box;
              background: rgba(92, 71, 162, 0.25);
              min-height: 1px;
              margin-top: 32px;
              margin-left: 40px;
            }
          }

          .text10 {
            position: absolute;
            top: 267px;
            left: 40px;
            width: 336px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content4 {
          width: 416px;
          height: 440px;
          overflow: hidden;
          border-radius: 24px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-left: 16px;
          position: relative;

          .section2 {
            width: 416px;
            height: 320px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(204, 240, 248, 1) 0%, rgba(204, 240, 248, 0) 100%);
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .main6 {
              width: 416px;
              height: 150px;
              position: relative;

              .image7 {
                width: 90px;
                height: 120px;
                overflow: hidden;
                box-sizing: border-box;
                position: absolute;
                top: 30px;
                left: 326px;
              }

              .subSection2 {
                width: 97px;
                height: 50px;
                border-radius: 0 12px 8px 12px;
                box-sizing: border-box;
                background: linear-gradient(270deg, rgba(29, 132, 192, 1) 0%, rgba(24, 115, 168, 1) 100%);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0px;
                left: 0px;

                .text11 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(255, 255, 255, 1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }

              .text12 {
                position: absolute;
                top: 74px;
                left: 40px;
                width: 336px;
                font-family: 'PingFang SC';
                font-size: 24px;
                color: rgba(24, 115, 168, 1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }

            .main7 {
              width: 164px;
              height: 36px;
              border-radius: 42px;
              border-color: rgba(24, 115, 168, 1);
              border-style: solid;
              border-width: 1px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-top: 16px;
              margin-left: 40px;
              cursor: pointer;
              .subSection3 {
                width: 132px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .text13 {
                  margin-right: 4px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  white-space: nowrap;
                  color: rgba(24, 115, 168, 1);
                  line-height: 19.60px;
                  font-weight: 400;
                }

                .image8 {
                  width: 16px;
                  height: 16px;
                  overflow: hidden;
                  box-sizing: border-box;
                }
              }
            }

            .main8 {
              width: 336px;
              box-sizing: border-box;
              background: rgba(24, 115, 168, 0.25);
              min-height: 1px;
              margin-top: 32px;
              margin-left: 40px;
            }
          }

          .text14 {
            position: absolute;
            top: 267px;
            left: 30.84px;
            width: 354.33px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content5 {
          width: 416px;
          height: 440px;
          overflow: hidden;
          border-radius: 24px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-left: 16px;
          position: relative;

          .section3 {
            width: 416px;
            height: 320px;
            box-sizing: border-box;
            background: linear-gradient(180deg, rgba(210, 229, 254, 1) 0%, rgba(210, 229, 254, 0) 100%);
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .main9 {
              width: 416px;
              height: 150px;
              position: relative;

              .image9 {
                width: 90px;
                height: 120px;
                overflow: hidden;
                box-sizing: border-box;
                position: absolute;
                top: 30px;
                left: 326px;
              }

              .subSection4 {
                width: 98px;
                height: 50px;
                border-radius: 0 12px 8px 12px;
                box-sizing: border-box;
                background: linear-gradient(270deg, rgba(30, 102, 210, 1) 0%, rgba(26, 90, 186, 1) 100%);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0px;
                left: 0px;

                .text15 {
                  font-family: 'PingFang SC';
                  font-size: 24px;
                  white-space: nowrap;
                  color: rgba(255, 255, 255, 1);
                  line-height: 33.60px;
                  font-weight: 500;
                }
              }

              .text16 {
                position: absolute;
                top: 74px;
                left: 40px;
                width: 336px;
                font-family: 'PingFang SC';
                font-size: 24px;
                color: rgba(26, 90, 186, 1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }

            .main10 {
              width: 164px;
              height: 36px;
              border-radius: 42px;
              border-color: rgba(26, 90, 186, 1);
              border-style: solid;
              border-width: 1px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-top: 16px;
              margin-left: 40px;
              cursor: pointer;

              .subSection5 {
                width: 132px;
                height: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .text17 {
                  margin-right: 4px;
                  font-family: 'PingFang SC';
                  font-size: 14px;
                  white-space: nowrap;
                  color: rgba(26, 90, 186, 1);
                  line-height: 19.60px;
                  font-weight: 400;
                }

                .image10 {
                  width: 16px;
                  height: 16px;
                  overflow: hidden;
                  box-sizing: border-box;
                }
              }
            }

            .main11 {
              width: 336px;
              box-sizing: border-box;
              background: rgba(26, 90, 186, 0.25);
              min-height: 1px;
              margin-top: 32px;
              margin-left: 40px;
            }
          }

          .text18 {
            position: absolute;
            top: 267px;
            left: 40px;
            width: 336px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }
      }
    }

    .wrap2 {
      width: 1280px;
      box-sizing: border-box;
      margin-top: 80px;
      
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .text37 {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container3 {
        width: 1280px;
        height: 94px;
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .content6 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .section4 {
            width: 156px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image11 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text19 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text20 {
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content7 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .section5 {
            width: 131px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image12 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text21 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text22 {
            margin-top: 16px;
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            white-space: nowrap;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content8 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .section6 {
            width: 216px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image13 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text23 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text24 {
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }
      }

      .container4 {
        width: 1280px;
        height: 94px;
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .content9 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .section7 {
            width: 180px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .main12 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .image14 {
                width: 24px;
                height: 23.91px;
              }
            }

            .text25 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text26 {
            margin-top: 16px;
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            white-space: nowrap;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content10 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .section8 {
            width: 180px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .main13 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .image15 {
                width: 24px;
                height: 20.38px;
              }
            }

            .text27 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text28 {
            margin-top: 16px;
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            white-space: nowrap;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content11 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .section9 {
            width: 168px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image16 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text29 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text30 {
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }
      }

      .container5 {
        width: 1280px;
        height: 94px;
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .content12 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .section10 {
            width: 180px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image17 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text31 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text32 {
            margin-top: 16px;
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            white-space: nowrap;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content13 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .section11 {
            width: 180px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image18 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text33 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text34 {
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }

        .content14 {
          width: 416px;
          height: 94px;
          overflow: hidden;
          box-sizing: border-box;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .section12 {
            width: 180px;
            height: 34px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            .image19 {
              width: 24px;
              height: 24px;
              overflow: hidden;
              box-sizing: border-box;
              margin-right: 12px;
            }

            .text35 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 1);
              line-height: 33.60px;
              font-weight: 500;
            }
          }

          .text36 {
            width: 368px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
          }
        }
      }
    }

    .wrap3 {
      width: 100%;
      height: 630px;
      overflow: hidden;
      box-sizing: border-box;
      background: rgba(255, 15, 15, 1);
      margin-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      .image20 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 630px;
        background-image: url('./assets/images/Image118.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .image21 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          height: 630px;
          background-image: url('./assets/images/Image119.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .image21_1{
            margin: 0 auto;
          }
          .text41 {
            margin-top: 80px;
            margin-left: 888px;
            font-family: 'PingFang SC';
            font-size: 36px;
            white-space: nowrap;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            line-height: 50.40px;
            font-weight: 500;
          }

          .section13 {
            width: 1280px;
            height: 424px;
            margin-top: 16px;
            
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .main14 {
              width: 447px;
              height: 360px;
              margin-top: 24px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;

              .subSection6 {
                width: 447px;
                height: 56px;
                border-radius: 12px;
                border-width: 1px;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .block {
                  width: 118px;
                  height: 34px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 8px;
                  margin-left: 40px;

                  .text38 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(255, 255, 255, 1);
                    line-height: 33.60px;
                    font-weight: 400;
                  }
                }
              }

              .subSection7 {
                width: 447px;
                height: 56px;
                margin-top: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .block1 {
                  width: 196px;
                  height: 40px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-left: 40px;

                  .image22 {
                    width: 40px;
                    height: 40px;
                    box-sizing: border-box;
                    margin-right: 12px;
                  }

                  .text39 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(255, 255, 255, 1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }
                }
              }

              .subSection8 {
                width: 447px;
                height: 56px;
                border-radius: 12px;
                border-width: 1px;
                box-sizing: border-box;
                margin-top: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .block2 {
                  width: 264px;
                  height: 34px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 8px;
                  margin-left: 40px;

                  .text40 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    color: rgba(255, 255, 255, 1);
                    line-height: 33.60px;
                    font-weight: 400;
                  }
                }
              }
              .subSectionNum{
                border-radius: 12px;
                border-color: rgba(31, 106, 251, 1);
                border-style: solid;
                border-width: 2px;
                box-sizing: border-box;
                background: rgba(31, 106, 251, 0.05);
                .text38,.text39,.text40{
                  font-weight: 600 !important;
                }
              }
              .image23 {
                width: 447px;
                height: 132px;
                margin-top: 28px;
              }
            }

            .main15 {
              // width: 753px;
              // height: 360px;
              // overflow: hidden;
              padding: 8px;
              border-radius: 8px;
              border-width: 4px;
              box-sizing: border-box;
              box-shadow: 0px 8px 32px 0px rgba(0, 18, 53, 0.25);
              background: rgba(55, 55, 55, 1);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;

              .subSection9 {
                width: 753px;
                height: 20px;
                box-sizing: border-box;
                background: rgba(82, 82, 82, 1);
                margin-top: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .image24 {
                  width: 40px;
                  height: 8px;
                  box-sizing: border-box;
                  margin-left: 20px;
                }
              }

              .image25 {
                width: 740px;
                height: 327px;
                overflow: hidden;
                border-radius: 8px;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 1);
                margin-top: 6.50px;
              }
            }
          }
        }
      }
    }

    .wrap4 {
      width: 1280px;
      height: 448px;
      border-radius: NaNpx;
      box-sizing: border-box;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(235, 252, 255, 1) 100%);
      margin-top: 80px;
      
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .text45 {
        margin-left: 604px;
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container6 {
        width: 1090px;
        height: 278.14px;
        margin-top: 40px;
        margin-left: 150px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .content15 {
          margin-top: 61.07px;
          width: 580px;
          height: 142px;

          .section14 {
            width: 328px;
            height: 50px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-left: 106px;

            .main16 {
              width: 144px;
              height: 50px;
              border-radius: 37px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              margin-right: 40px;

              .text42 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                color: #000;
                line-height: 33.60px;
                font-weight: 500;
              }
            }

            .main17 {
              width: 144px;
              height: 50px;
              border-radius: 53px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;

              .text43 {
                font-family: 'PingFang SC';
                font-size: 24px;
                white-space: nowrap;
                color: rgba(0, 0, 0, 1);
                line-height: 33.60px;
                font-weight: 500;
              }
            }
            .main16Active{
              background: rgba(31, 106, 251, 1);
              .text42,.text43{
                color: #fff;
              }
            }
          }

          .text44 {
            width: 580px;
            font-family: 'PingFang SC';
            font-size: 14px;
            text-align: center;
            color: rgba(102, 102, 102, 1);
            line-height: 22px;
            font-weight: 400;
            margin-top: 32px;
          }
        }

        .image26 {
          width: 360px;
          height: 278.14px;
          overflow: hidden;
          box-sizing: border-box;
        }
      }
    }

    .wrap5 {
      width: 1280px;
      height: 430px;
      box-sizing: border-box;
      margin-top: 80px;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text55 {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container7 {
        width: 1280px;
        height: 340px;
        padding-top: 20px;
        overflow: hidden;
        border-radius: 16px;
        box-sizing: border-box;
        background: rgba(31, 106, 251, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        .content16 {
          width: 1240px;
          height: 260px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 35px;
          .section15 {
            width: 480px;
            height: 260px;
            overflow: hidden;
            border-radius: 16px;
            box-sizing: border-box;
            background: linear-gradient(-41deg, rgba(255, 255, 255, 1) 48.97%, rgba(237, 255, 252, 1) 98.34%);
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            .main18 {
              width: 440px;
              height: 220px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;

              .subSection10 {
                width: 242px;
                height: 80px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;

                .image27 {
                  width: 80px;
                  height: 80px;
                  overflow: hidden;
                  border-radius: NaNpx;
                  box-sizing: border-box;
                  background: linear-gradient(180deg, rgba(37, 48, 255, 1) 0%, rgba(169, 165, 255, 1) 100%);
                  margin-right: 16px;
                }

                .block3 {
                  width: 146px;
                  height: 56px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: flex-start;

                  .text46 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }

                  .text47 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
                    line-height: 22px;
                    font-weight: 400;
                  }
                }
              }

              .text48 {
                width: 440px;
                font-family: 'PingFang SC';
                font-size: 12px;
                color: rgba(102, 102, 102, 1);
                line-height: 20px;
                font-weight: 400;
              }
            }
          }

          .section16 {
            width: 480px;
            height: 260px;
            overflow: hidden;
            border-radius: 16px;
            box-sizing: border-box;
            background: linear-gradient(-41deg, rgba(255, 255, 255, 1) 48.97%, rgba(237, 255, 252, 1) 98.34%);
            margin-right: 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .main19 {
              width: 440px;
              height: 140px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 20px;

              .subSection11 {
                width: 260px;
                height: 80px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;

                .image28 {
                  width: 80px;
                  height: 80px;
                  overflow: hidden;
                  border-radius: NaNpx;
                  box-sizing: border-box;
                  // background: linear-gradient(180deg, rgba(37, 48, 255, 1) 0%, rgba(169, 165, 255, 1) 100%);
                  margin-right: 16px;
                }

                .block4 {
                  width: 164px;
                  height: 56px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: flex-start;

                  .text49 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }

                  .text50 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
                    line-height: 22px;
                    font-weight: 400;
                  }
                }
              }

              .text51 {
                width: 440px;
                font-family: 'PingFang SC';
                font-size: 12px;
                color: rgba(102, 102, 102, 1);
                line-height: 20px;
                font-weight: 400;
              }
            }
          }

          .section17 {
            width: 248px;
            height: 260px;
            overflow: hidden;
            border-radius: 16px;
            box-sizing: border-box;
            background: linear-gradient(-41deg, rgba(255, 255, 255, 1) 48.97%, rgba(237, 255, 252, 1) 98.34%);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;

            .main20 {
              width: 228px;
              height: 160px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 20px;

              .subSection12 {
                width: 228px;
                height: 80px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;

                .image29 {
                  width: 80px;
                  height: 80px;
                  overflow: hidden;
                  border-radius: NaNpx;
                  box-sizing: border-box;
                  background: linear-gradient(180deg, rgba(37, 48, 255, 1) 0%, rgba(169, 165, 255, 1) 100%);
                  margin-right: 16px;
                }

                .block5 {
                  width: 132px;
                  height: 56px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: flex-start;

                  .text52 {
                    font-family: 'PingFang SC';
                    font-size: 24px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
                    line-height: 33.60px;
                    font-weight: 500;
                  }

                  .text53 {
                    font-family: 'PingFang SC';
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
                    line-height: 22px;
                    font-weight: 400;
                  }
                }
              }

              .text54 {
                width: 440px;
                font-family: 'PingFang SC';
                font-size: 12px;
                color: rgba(102, 102, 102, 1);
                line-height: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }


      .scroll-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .wrap6 {
      width: 1280px;
      height: 354px;
      box-sizing: border-box;
      margin-top: 80px;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text66 {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container8 {
        width: 1280px;
        height: 264px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .content17 {
          width: 243px;
          height: 264px;
          overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(180deg, rgba(240, 246, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .image30 {
            width: 40px;
            height: 40px;
            border-radius: 10.67px;
            box-sizing: border-box;
            background: rgba(101, 179, 248, 1);
            margin-top: 32px;
            margin-left: 20px;
          }

          .text56 {
            margin-top: 20px;
            margin-left: 20px;
            width: 203.2px;
            font-family: 'PingFang SC';
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            white-space: pre-line;
          }

          .text57 {
            margin-top: 10px;
            margin-left: 20px;
            width: 203.20px;
            font-family: 'PingFang SC';
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
            font-weight: 400;
          }
        }

        .content18 {
          width: 243px;
          height: 264px;
          overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(180deg, rgba(244, 250, 254, 1) 0%, rgba(255, 255, 255, 0) 100%);
          margin-left: 16.25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .image31 {
            width: 40px;
            height: 40px;
            border-radius: 10.67px;
            box-sizing: border-box;
            background: rgba(66, 199, 232, 1);
            margin-top: 32px;
            margin-left: 20px;
          }

          .text58 {
            margin-top: 20px;
            margin-left: 20px;
            width: 203.2px;
            font-family: 'PingFang SC';
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            white-space: pre-line;
          }

          .text59 {
            margin-top: 10px;
            margin-left: 20px;
            width: 203.20px;
            font-family: 'PingFang SC';
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
            font-weight: 400;
          }
        }

        .content19 {
          width: 243px;
          height: 264px;
          overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(180deg, rgba(255, 250, 240, 1) 0%, rgba(255, 255, 255, 0) 100%);
          margin-left: 16.25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .image32 {
            width: 40px;
            height: 40px;
            border-radius: 10.67px;
            box-sizing: border-box;
            background: rgba(254, 203, 47, 1);
            margin-top: 32px;
            margin-left: 20px;
          }

          .text60 {
            margin-top: 20px;
            margin-left: 20px;
            width: 203.2px;
            font-family: 'PingFang SC';
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            white-space: pre-line;
          }

          .text61 {
            margin-top: 10px;
            margin-left: 20px;
            width: 203.20px;
            font-family: 'PingFang SC';
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
            font-weight: 400;
          }
        }

        .content20 {
          width: 243px;
          height: 264px;
          overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(180deg, rgba(255, 249, 249, 1) 0%, rgba(255, 255, 255, 0) 100%);
          margin-left: 16.25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .image33 {
            width: 40px;
            height: 40px;
            border-radius: 10.67px;
            box-sizing: border-box;
            background: rgba(253, 99, 76, 1);
            margin-top: 32px;
            margin-left: 20px;
          }

          .text62 {
            margin-top: 20px;
            margin-left: 20px;
            width: 203.2px;
            font-family: 'PingFang SC';
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            white-space: pre-line;
          }

          .text63 {
            margin-top: 10px;
            margin-left: 20px;
            width: 203.20px;
            font-family: 'PingFang SC';
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
            font-weight: 400;
          }
        }

        .content21 {
          width: 243px;
          height: 264px;
          overflow: hidden;
          border-radius: 16px;
          box-sizing: border-box;
          background: linear-gradient(180deg, rgba(245, 243, 254, 1) 0%, rgba(255, 255, 255, 0) 100%);
          margin-left: 16.25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .image34 {
            width: 40px;
            height: 40px;
            border-radius: 10.67px;
            box-sizing: border-box;
            background: rgba(154, 154, 241, 1);
            margin-top: 32px;
            margin-left: 20px;
          }

          .text64 {
            margin-top: 20px;
            margin-left: 20px;
            width: 203.2px;
            font-family: 'PingFang SC';
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            white-space: pre-line;
          }

          .text65 {
            margin-top: 10px;
            margin-left: 20px;
            width: 203.20px;
            font-family: 'PingFang SC';
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
            font-weight: 400;
          }
        }
      }
    }

    .wrap8 {
      width: 100%;
      height: 570px;
      box-sizing: border-box;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text69 {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container9 {
        width: 100%;
        height: 480px;
        position: relative;

        .image41 {
          width: 100%;
          height: 480px;
          overflow: hidden;
          box-sizing: border-box;
          background: rgba(151, 181, 254, 1);
          position: absolute;
          top: 0px;
          left: 0px;
        }

        .content23 {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 480px;

          .section19 {
            width: 50%;
            opacity: 0.8500000238418579;
            box-sizing: border-box;
            background: rgba(31, 82, 251, 1);
            min-height: 480px;
            position: absolute;
            top: 0px;
            left: 0px;
          }

          .section20 {
            position: absolute;
            top: 75px;
            right: 52%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 600px;
            height: 250px;

            .text70 {
              font-family: 'PingFang SC';
              font-size: 24px;
              white-space: nowrap;
              text-align: center;
              color: rgba(255, 255, 255, 1);
              line-height: 33.60px;
              font-weight: 500;
            }

            .text71 {
              margin-top: 20px;
              width: 600px;
              font-family: 'PingFang SC';
              font-size: 14px;
              color: rgba(255, 255, 255, 0.85);
              line-height: 22px;
              font-weight: 400;
            }

            .text72 {
              margin-top: 20px;
              width: 600px;
              font-family: 'PingFang SC';
              font-size: 14px;
              color: rgba(255, 255, 255, 0.85);
              line-height: 22px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .wrap9 {
      width: 100%;
      height: 458px;
      box-sizing: border-box;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      >div{
        margin: 0 auto;

      }
      .text73 {
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container10 {
        height: 106px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 28px;

        .image42 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .content24 {
          width: 1592px;
          height: 106px;
          position: relative;

          .image43 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 0px;
          }

          .image44 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 198px;
          }

          .image45 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 396px;
          }

          .image46 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 594px;
          }

          .image47 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 792px;
          }

          .image48 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 990px;
          }

          .image49 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 1188px;
          }

          .image50 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 1386px;
          }
        }
      }

      .container11 {
        height: 74px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4px;

        .image51 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image52 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image53 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image54 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image55 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image56 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image57 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image58 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image59 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image60 {
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
        }
      }

      .container12 {
        height: 106px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 12px;

        .image61 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .content25 {
          width: 1592px;
          height: 106px;
          margin-right: 24px;
          position: relative;

          .image62 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 0px;
          }

          .image63 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 198px;
          }

          .image64 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 396px;
          }

          .image65 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 594px;
          }

          .image66 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 792px;
          }

          .image67 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 990px;
          }

          .image68 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 1188px;
          }

          .image69 {
            width: 206px;
            height: 106px;
            overflow: hidden;
            border-radius: 12px;
            box-sizing: border-box;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0px;
            left: 1386px;
          }
        }

        .image70 {
          width: 174px;
          height: 106px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
          background: rgba(255, 255, 255, 1);
        }
      }

      .container13 {
        width: 1280px;
        height: 74px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4px;

        .image71 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image72 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image73 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image74 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image75 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image76 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .image77 {
          width: 174px;
          height: 74px;
          overflow: hidden;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          margin-right: 24px;
        }

        .content26 {
          width: 174px;
          overflow: hidden;
          border-radius: 12px;
          border-color: rgba(244, 244, 244, 1);
          border-style: solid;
          border-width: 1px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          min-height: 74px;
          margin-right: 24px;
        }

        .content27 {
          width: 174px;
          overflow: hidden;
          border-radius: 12px;
          border-color: rgba(244, 244, 244, 1);
          border-style: solid;
          border-width: 1px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          min-height: 74px;
          margin-right: 24px;
        }

        .content28 {
          width: 174px;
          overflow: hidden;
          border-radius: 12px;
          border-color: rgba(244, 244, 244, 1);
          border-style: solid;
          border-width: 1px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          min-height: 74px;
        }
      }
    }

    .wrap10 {
      width: 100%;
      height: 645px;
      box-sizing: border-box;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text77 {
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container14 {
        height: 567px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .image85 {
          width: 31.81px;
          height: 31.90px;
          box-sizing: border-box;
          margin-top: 267.50px;
        }

        .content29 {
          width: 1280px;
          height: 567px;
          margin-left: 20.10px;
          position: relative;

          .section21 {
            width: 445px;
            height: 567px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            .main21 {
              width: 445px;
              height: 535px;
              overflow: hidden;
              border-radius: 16px;
              border-width: 2px;
              box-sizing: border-box;
              box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
              background: linear-gradient(180deg, rgba(244, 249, 255, 1) -0.06999999999999999%, rgba(255, 255, 255, 1) 100.07%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              .image78 {
                width: 413px;
                height: 240px;
                overflow: hidden;
                border-radius: NaNpx;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 1);
                margin-top: 12px;
                margin-left: 16px;
              }

              .image79 {
                width: 120px;
                height: 24px;
                overflow: hidden;
                box-sizing: border-box;
                margin-top: 40px;
                margin-left: 36px;
              }

              .text74 {
                margin-top: 20px;
                margin-left: 36px;
                width: 373px;
                font-family: 'PingFang SC';
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
                line-height: 22px;
                font-weight: 400;
              }
            }
          }

          .section22 {
            width: 445px;
            height: 567px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            .main22 {
              width: 445px;
              height: 535px;
              overflow: hidden;
              border-radius: 16px;
              border-width: 2px;
              box-sizing: border-box;
              box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
              background: linear-gradient(180deg, rgba(244, 249, 255, 1) -0.06999999999999999%, rgba(255, 255, 255, 1) 100.07%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              .image80 {
                width: 413px;
                height: 240px;
                overflow: hidden;
                border-radius: NaNpx;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 1);
                margin-top: 12px;
                margin-left: 16px;
              }

              .image81 {
                width: 120px;
                height: 24px;
                overflow: hidden;
                box-sizing: border-box;
                margin-top: 40px;
                margin-left: 36px;
              }

              .text75 {
                margin-top: 20px;
                margin-left: 36px;
                width: 373px;
                font-family: 'PingFang SC';
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
                line-height: 22px;
                font-weight: 400;
              }
            }
          }

          .section23 {
            width: 445px;
            height: 567px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            .main23 {
              width: 445px;
              height: 535px;
              overflow: hidden;
              border-radius: 16px;
              border-width: 2px;
              box-sizing: border-box;
              box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
              background: linear-gradient(180deg, rgba(244, 249, 255, 1) -0.06999999999999999%, rgba(255, 255, 255, 1) 100.07%);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              .image82 {
                width: 413px;
                height: 240px;
                overflow: hidden;
                border-radius: NaNpx;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 1);
                margin-top: 12px;
                margin-left: 16px;
              }

              .subSection13 {
                width: 120px;
                height: 24px;
                overflow: hidden;
                box-sizing: border-box;
                margin-top: 40px;
                margin-left: 36px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .image83 {
                  width: 66.28px;
                  height: 24px;
                }
              }

              .text76 {
                margin-top: 20px;
                margin-left: 36px;
                width: 373px;
                font-family: 'PingFang SC';
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
                line-height: 22px;
                font-weight: 400;
              }
            }
          }
        }

        .image84 {
          width: 31.81px;
          height: 31.90px;
          margin-top: 267.50px;
          margin-left: 20.10px;
        }
      }
    }

    .wrap7 {
      width: 1280px;
      height: 269px;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;
      background: rgba(22, 32, 50, 1);
      margin-top: 60px;
      
      position: relative;

      .image35 {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 1123.21px;
        height: 269px;
        background-image: url('./assets/images/Image280.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .content22 {
          width: 658px;
          height: 140px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 64.50px;
          margin-left: 80px;

          .section18 {
            width: 354px;
            height: 82px;
            border-radius: 78px;
            box-sizing: border-box;
            background: rgba(31, 106, 251, 1);
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;

            .text67 {
              margin-right: 12px;
              font-family: 'PingFang SC';
              font-size: 36px;
              white-space: nowrap;
              text-align: center;
              color: rgba(255, 255, 255, 1);
              line-height: 50.40px;
              font-weight: 500;
              cursor: pointer;
            }

            .image37 {
              width: 40px;
              height: 40px;
              overflow: hidden;
              box-sizing: border-box;
            }
          }

          .text68 {
            width: 658px;
            font-family: 'PingFang SC';
            font-size: 24px;
            white-space: nowrap;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            line-height: 33.60px;
            font-weight: 400;
          }
        }

        .image36 {
          width: 134.17px;
          height: 8.90px;
          margin-top: 3px;
          margin-left: 255.60px;
        }
      }

      .image38 {
        width: 480.73px;
        height: 269px;
        position: absolute;
        top: 0px;
        left: 799.27px;
      }

      .image39 {
        position: absolute;
        top: 0px;
        left: 684.69px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 595.31px;
        height: 269px;
        background-image: url('./assets/images/Image288.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .image40 {
          width: 160px;
          height: 160px;
          box-sizing: border-box;
          margin-right: 80px;
        }
      }
    }
  }

  .subBlock {
    width: 100%;
    height: 600px;
    // padding-top: 80px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);

    display: flex;
    justify-content: center;
    align-items: center;

    .carouselimage01 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1920px;
      height: 600px;
      box-sizing: border-box;
      background-image: url('./assets/images/Image291.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;


      .image87 {
        width: 774.45px;
        height: 137.80px;
        margin-top: 166.80px;
        margin-left: 573.23px;
      }

      .image88 {
        width: 179px;
        height: 48px;
        margin-top: 49.40px;
        margin-left: 870.50px;
        cursor: pointer;
      }

      .container15 {
        width: 194px;
        height: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 102px;
        margin-left: 863px;

        .content30 {
          width: 80px;
          border-radius: 17px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          min-height: 8px;
          margin-right: 8px;
        }

        .content31 {
          width: 30px;
          border-radius: 17px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.45);
          min-height: 8px;
          margin-right: 8px;
        }

        .content32 {
          width: 30px;
          border-radius: 17px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.45);
          min-height: 8px;
          margin-right: 8px;
        }

        .content33 {
          width: 30px;
          border-radius: 17px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.45);
          min-height: 8px;
        }
      }
    }

    .carouselimage02 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1920px;
      height: 600px;
      box-sizing: border-box;
      background-image: url('./assets/images/carouselImage2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .image1 {
        width: 1132.17px;
        height: 185.46px;
        margin-top: 165.20px;
        margin-left: 376.08px;
      }
      .image2 {
        width: 179px;
        height: 48px;
        margin-top: 50.34px;
        margin-left: 870.50px;
        cursor: pointer;
      }
    }
    .carouselimage03 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1920px;
      height: 600px;
      box-sizing: border-box;
      background-image: url('./assets/images/carouselImage3_2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .image1 {
        width: 1195.79px;
        height: 184.96px;
        margin-top: 165.20px;
        margin-left: 361.29px;
      }
      .image2 {
        width: 179px;
        height: 48px;
        margin-top: 50.84px;
        margin-left: 870.50px;
        cursor: pointer;
      }
    }
    .carouselimage04 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1919.53px;
      height: 600px;
      box-sizing: border-box;
      background-image: url('./assets/images/carouselImage4_2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .image1 {
        width: 1068.97px;
        height: 118.63px;
        margin-top: 165.20px;
        margin-left: 425.85px;
      }
      .image2 {
        width: 196px;
        height: 48px;
        margin-top: 56.17px;
        margin-left: 862px;
        cursor: pointer;
      }
    }
  }

  .subBlock1 {
    width: 100%;
    height: 288px;
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
    z-index: 9;
    .image99 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1280px;
      height: 308px;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
      background: rgba(255, 255, 255, 1);
      // background-image: url('./assets/images/Image317.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;

      .text78 {
        margin-top: 28px;
        margin-left: 371px;
        font-family: 'PingFang SC';
        font-size: 36px;
        white-space: nowrap;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        line-height: 50.40px;
        font-weight: 500;
      }

      .container17 {
        width: 1029.33px;
        height: 40px;
        margin-top: 40px;
        margin-left: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .image102 {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
        }

        .text81 {
          margin-top: 2.50px;
          margin-left: 16px;
          font-family: 'PingFang SC';
          font-size: 24px;
          white-space: nowrap;
          color: rgba(0, 0, 0, 1);
          line-height: 33.60px;
          font-weight: 500;
        }

        .image100 {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          margin-left: 250.67px;
        }

        .text79 {
          margin-top: 2.50px;
          margin-left: 16px;
          font-family: 'PingFang SC';
          font-size: 24px;
          white-space: nowrap;
          color: rgba(0, 0, 0, 1);
          line-height: 33.60px;
          font-weight: 500;
        }

        .image101 {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          margin-left: 250.67px;
        }

        .text80 {
          margin-top: 2.50px;
          margin-left: 16px;
          font-family: 'PingFang SC';
          font-size: 24px;
          white-space: nowrap;
          color: rgba(0, 0, 0, 1);
          line-height: 33.60px;
          font-weight: 500;
        }
      }

      .container18 {
        width: 1200px;
        height: 66px;
        margin-top: 12px;
        margin-left: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .text82 {
          width: 346.67px;
          font-family: 'PingFang SC';
          font-size: 14px;
          color: rgba(102, 102, 102, 1);
          line-height: 22px;
          font-weight: 400;
        }

        .text83 {
          margin-left: 80px;
          width: 346.67px;
          font-family: 'PingFang SC';
          font-size: 14px;
          color: rgba(102, 102, 102, 1);
          line-height: 22px;
          font-weight: 400;
        }

        .text84 {
          margin-left: 80px;
          width: 346.67px;
          font-family: 'PingFang SC';
          font-size: 14px;
          color: rgba(102, 102, 102, 1);
          line-height: 22px;
          font-weight: 400;
        }
      }
    }
  }
}


</style>
<style lang="less">
.page{

  .el-carousel__indicators--horizontal{
    bottom: 140px!important;
    font-size: 16px;
    .el-carousel__button{
      height: 6px;
      border-radius: 3px;
    }
    .is-active{
      .el-carousel__button{
        width: 60px;
      }
    }
  }
}

.yuyueDialog{
  background: none!important;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
}
.yuyue-page {
	width: 480px;
	height: 320px;
	overflow: hidden;
	border-radius: 16px;
	box-sizing: border-box;
	box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
	background: rgba(255,255,255,1);
	position: relative;
	.yuyue-wrap {
		width: 480px;
		height: 160px;
		box-sizing: border-box;
		background: linear-gradient(180deg, rgba(31,106,251,0.25) 0%,rgba(255,255,255,0) 100%);
		position: absolute;
		top: 0px;
		left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.yuyue-image {
			margin-left: 440px;
      padding: 24px;
      position: absolute;
      right: 0;
      box-sizing: border-box;
      cursor: pointer;
		}
		.yuyue-text {
			margin-top: 40px;
			margin-left: 120px;
			font-family: 'PingFang SC';
			font-size: 24px;
			white-space: nowrap;
			color: rgba(0,0,0,1);
			line-height: 33.60px;
			font-weight: 500;
      span{
        color: #1F6AFB;
      }
		}
	}
	.yuyue-image1 {
		width: 140px;
		height: 140px;
		overflow: hidden;
		box-sizing: border-box;
		background: rgba(255,255,255,1);
		position: absolute;
		top: 124px;
		left: 170px;
	}
}

// ::-webkit-scrollbar {
//   width: 8px;
//   background-color: #f1f1f1;
//   border-radius: 5px;
// }
 
// ::-webkit-scrollbar-thumb {
//   background-color: #ccc;
//   border-radius: 5px;
// }
 
// ::-webkit-scrollbar-thumb:hover {
//   background-color: #999;
// }
</style>