<template>
  <div>
    <div :class="`${isAtTop?'container16 container16_1':'container16'}` ">
      <div class="image89">
        <div class="section24">
          <div class="main24">
            <div class="subSection14">
              <img :src="require(`./../assets/images/logoGroup${isAtTop?'_1':''}.png`)" class="image93" alt="Image Asset 301" />
            </div>
            <div class="subSection14 subSection14_1">
              <img :src="require(`./../assets/images/logoGroup1${isAtTop?'_1':''}.png`)" class="image93" alt="Image Asset 301" />
            </div>
            <div class="subSection14 subSection14_2">
              <img :src="require(`./../assets/images/logoGroup2${isAtTop?'_1':''}.png`)" class="image93" alt="Image Asset 301" />
            </div>
          </div>
          <div class="main25">
            <div style="display:flex;padding: 28px 0;cursor: pointer;" @click="$router.push('/')">
              <span class="image94">
                首页
              </span>
            </div>
            <div class="subSection15" @mouseenter="navpage = true">
              <span class="image95">
                AIGC大模型培训
              </span>
              <i v-show="!navpage" :style="{color:isAtTop?'#fff':'#333'}" class="el-icon-arrow-down"></i>
              <i v-show="navpage" :style="{color:isAtTop?'#fff':'#333'}" class="el-icon-arrow-up"></i>

              <!-- <img v-show="!navpage" :src="require('./../assets/images/Image306.png')" class="image96" alt="Image Asset 306" />
                <img v-show="navpage" :src="require('./../assets/images/Image306_1.png')" class="image96_1" alt="Image Asset 306" />
                <img :src="require('./../assets/images/Image305.png')" class="image95" alt="Image Asset 305" /> -->
            </div>
            <span class="image97" @click="$router.push('/studentServices')">学员服务</span>
            <span class="image97" @click="goWebsite">AI得贤招聘官</span>
            <span class="image98" @click="$router.push('/aboutus')">关于我们</span>
            <!-- <img :src="require('./../assets/images/Image307.png')" class="image97" alt="Image Asset 307" />
            <img :src="require('./../assets/images/Image308.png')" class="image98" alt="Image Asset 308" /> -->
          </div>
        </div>
      </div>
      <div class="nav-page" v-show="navpage" @mouseleave="navpage = false">
        <!--  -->
        <div class="nav-wrap" @click="$router.push('/aigc')">
          <img
            :src="require('./../assets/images/nav-Image3.png')"
            class="nav-image"
            alt="Image Asset 3"
          />
          <p class="nav-text">
            <span class="blue">AIGC</span><br/>
            大模型应用工程师课程</p>
        </div>
        <!--  -->
        <div class="nav-wrap1" @click="$router.push('/aigc1')">
          <img
            :src="require('./../assets/images/nav-Image6.png')"
            class="nav-image1"
            alt="Image Asset 6"
          />
          <p class="nav-text1">
            <span class="blue">AIGC</span><br/>
            多模态大模型应用工程师课程</p>
        </div>
        <div class="nav-wrap2" @click="$router.push('/aigc2')">
          <img
            :src="require('./../assets/images/nav-Image9.png')"
            class="nav-image2"
            alt="Image Asset 9"
          />
          <p class="nav-text2">
            <span class="blue">AIGC</span><br/>
            多模态大模型产品经理课程</p>
        </div>
      </div>


    </div>
  </div>

</template>

<script>
export default {
  name: "navpage",
  props:{
    isdefault: {
      // type: ,
      required: false,
      default: false
    }
  },
  data() {
    return {
      navpage: false,
      isAtTop: true
    };
  },
  mounted() {
    if(!this.isdefault) window.addEventListener('scroll', this.handleScroll);
    else this.isAtTop = false
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    goWebsite(){
      window.open('https://www.airecruitas.com/')
    },
    handleScroll() {
      this.isAtTop = window.scrollY === 0;
      // console.log(this.isAtTop)
    }
  }
}
</script>

<style lang="less">
/* 禁用图片拖动 */
img {
  user-select: none;
  -webkit-user-drag: none;
}
p{
  margin: 0;
}
.blue{
  color: #1F6AFB;
}
.container16 {
    width: 100%;
    height: 80px;
    // overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 13;
    background: #fff;
    color: #333;
    font-family: "Alibaba PuHuiTi";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px /* 150% */;
    border-bottom: 1px solid rgba(223, 224, 240, 0.45);
    .image89 {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 1280px;
      height: 80px;


      .section24 {
        width: 1280px;
        height: 38px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .main24 {
          // width: 138.05px;
          height: 38px;
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 16px;

          .subSection14 {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // width: 137.70px;
            height: 38px;
            .image93 {
              width: 116px;
              height: 32px;
            }
          }
          .subSection14_1{
            margin-left: 32px;
            .image93 {
              width: 105.5px;
              height: 32px;
            }
          }
          .subSection14_2{
            margin-left: 32px;
            .image93 {
              width: 120.5px;
              height: 32px;
            }
          }
        }

        .main25 {
          // width: 437px;
          height: 24px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;

          .image94 {
            // width: 29.32px;
            height: 14.72px;
            margin-left: 48px;
            cursor: pointer;
          }

          .subSection15 {
            width: 135px;
            height: 24px;
            padding: 28px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-left: 48px;
            cursor: pointer;
            .image95 {
              // width: 112.15px;
              height: 14.70px;
              margin-right: 4px;
            }
            i,.image96,.image96_1{
              width: 16px;
              height: 16px;
              color: #333;
              margin-top: 8px;
            }
          }

          .image97 {
            // width: 61.44px;
            height: 14.78px;
            margin-left: 48px;
            cursor: pointer;
          }

          .image98 {
            // width: 60.64px;
            height: 14.56px;
            margin-left: 48px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
    }
    .nav-page {
      width: 100%;
      height: 188px;
      overflow: hidden;
      box-sizing: border-box;
      background: rgba(255,255,255,0.95);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 80px;
      z-index: 10;
      .nav-wrap {
        width: 373.33px;
        height: 108px;
        overflow: hidden;
        border-radius: 8px;
        box-sizing: border-box;
        background: rgba(231,228,249,1);
        position: relative;
        cursor: pointer;
        .nav-image {
          width: 160px;
          height: 108px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .nav-text {
          line-height: 32px;
          position: absolute;
          top: 20px;
          left: 40px;
          font-family: 'PingFang SC';
          font-size: 24px;
          color: rgba(0,0,0,1);
          font-weight: 500;
          white-space: pre-line;
        }
      }
      .nav-wrap1 {
        width: 373.33px;
        height: 108px;
        overflow: hidden;
        border-radius: 8px;
        box-sizing: border-box;
        background: rgba(204,240,248,1);
        margin-left: 40px;
        position: relative;
        cursor: pointer;
        .nav-image1 {
          width: 160px;
          height: 108px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .nav-text1 {
          line-height: 32px;
          position: absolute;
          top: 20px;
          left: 40px;
          font-family: 'PingFang SC';
          font-size: 24px;
          color: rgba(0,0,0,1);
          font-weight: 500;
          white-space: pre-line;
        }
      }
      .nav-wrap2 {
        width: 373.33px;
        height: 108px;
        overflow: hidden;
        border-radius: 8px;
        box-sizing: border-box;
        background: rgba(210,229,254,1);
        margin-left: 40px;
        position: relative;
        cursor: pointer;
        .nav-image2 {
          width: 160px;
          height: 108px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .nav-text2 {
          line-height: 32px;
          position: absolute;
          top: 20px;
          left: 40px;
          font-family: 'PingFang SC';
          font-size: 24px;
          color: rgba(0,0,0,1);
          font-weight: 500;
          white-space: pre-line;
        }
      }
    }
    &.container16_1{
      background: rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(8px);
      color: #fff !important;
    }
  }


</style>