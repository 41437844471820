import Vue from 'vue'
import VueRouter from 'vue-router'
import Lanhai from '../views/home.vue'
import Aigc from '../views/lanhai/aigc/index.vue'
import Aigc1 from '../views/lanhai/aigc/index1.vue'
import Aigc2 from '../views/lanhai/aigc/index2.vue'
import StudentServices from '../views/lanhai/studentServices/index.vue'
import Aboutus from '../views/lanhai/aboutus/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Lanhai
  },
  {
    path: '/aigc',
    name: 'Aigc',
    component: Aigc
  },
  {
    path: '/aigc1',
    name: 'Aigc1',
    component: Aigc1
  },
  {
    path: '/aigc2',
    name: 'Aigc2',
    component: Aigc2
  },
  {
    path: '/studentServices',
    name: 'StudentServices',
    component: StudentServices
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: Aboutus
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的滚动位置，则返回到该位置
    if (savedPosition) {
      return savedPosition
    } else {
      // 否则滚动到顶部
      return { x: 0, y: 0 }
    }
  }
})

export default router
