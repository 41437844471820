<template>
  <div>

    <div class="slide-page">
      <el-popover
        placement="right"
        trigger="hover">
        <img style="height: 180px;width: 180px;margin: -10px;"
          src="https://file.aidexianzhaopinguan.com/resume/102146763614944468.png" />
        <div class="wrap" slot="reference">
          <img
            :src=" require('./../assets/slideImg/Image1.png')"
            class="image"
            alt="Image Asset 1"
          />
          <p class="text">领取资料</p>
        </div>
      </el-popover>
      <el-popover
        placement="right"
        trigger="hover">
        <img style="height: 180px;width: 180px;margin: -10px;"
          src="https://file.aidexianzhaopinguan.com/resume/102146763614944468.png" />
        <div class="wrap2" slot="reference">
          <img
            :src=" require('./../assets/slideImg/Image2.png')"
            class="image2"
            alt="Image Asset 2"
          />
          <p class="text2">试听课程</p>
        </div>
      </el-popover>
      <el-popover
        placement="right"
        trigger="hover">
        <img style="height: 180px;width: 180px;margin: -10px;"
          src="https://file.aidexianzhaopinguan.com/resume/102146763614944468.png" />
        <div class="wrap1" slot="reference">
          <img
            :src=" require('./../assets/slideImg/Image3.png')"
            class="image1"
            alt="Image Asset 3"
          />
          <p class="text1">限时优惠</p>
        </div>
      </el-popover>
      <el-popover
        placement="right"
        trigger="hover">
        <img style="height: 180px;width: 180px;margin: -10px;"
          src="https://file.aidexianzhaopinguan.com/resume/102146763614944468.png" />
        <div class="wrap3" slot="reference">
          <img
            :src=" require('./../assets/slideImg/Image4.png')"
            class="image3"
            alt="Image Asset 4"
          />
          <p class="text3">微信(联系我们)</p>
        </div>
      </el-popover>

    </div>


  </div>
</template>

<script>
export default {
  name: 'GeneratedComponent',
};
</script>

<style lang="less" scoped>
.slide-page {
  position: fixed;
	right: 20px;
	top: 20%;
  z-index: 100;
	width: 100px;
	overflow: hidden;
	border-radius: 16px;
	box-sizing: border-box;
	box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.05);
	background: rgba(255,255,255,1);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
  >div{
    cursor: pointer;

  }
  .wrapActive{
		background: linear-gradient(180deg, rgba(94,149,255,1) 0%,rgba(31,106,251,1) 100%);
		color: rgba(255,255,255,1)!important;
  }
	.wrap {
		width: 100px;
		height: 100px;
		overflow: hidden;
		box-sizing: border-box;
		background: linear-gradient(180deg, rgba(94,149,255,1) 0%,rgba(31,106,251,1) 100%);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.image {
			width: 32px;
			height: 32px;
			overflow: hidden;
			box-sizing: border-box;
			margin-top: 20px;
		}
		.text {
			margin-top: 8px;
			font-family: 'PingFang SC';
			font-size: 16px;
			white-space: nowrap;
			text-align: center;
			color: rgba(255,255,255,1);
			line-height: 26px;
			font-weight: 400;
		}
	}
	.wrap2 {
		width: 100px;
		height: 100px;
		overflow: hidden;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.image2 {
			width: 32px;
			height: 32px;
			overflow: hidden;
			box-sizing: border-box;
			margin-top: 20px;
		}
		.text2 {
			margin-top: 8px;
			font-family: 'PingFang SC';
			font-size: 16px;
			white-space: nowrap;
			text-align: center;
			color: rgba(0,0,0,1);
			line-height: 26px;
			font-weight: 400;
		}
	}
	.wrap1 {
		width: 100px;
		height: 100px;
		overflow: hidden;
		box-sizing: border-box;
		background: rgba(31,106,251,0.05);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.image1 {
			width: 32px;
			height: 32px;
			overflow: hidden;
			box-sizing: border-box;
			margin-top: 20px;
		}
		.text1 {
			margin-top: 8px;
			font-family: 'PingFang SC';
			font-size: 16px;
			white-space: nowrap;
			text-align: center;
			color: rgba(0,0,0,1);
			line-height: 26px;
			font-weight: 400;
		}
	}
	.wrap3 {
		width: 100px;
		height: 100px;
		overflow: hidden;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.image3 {
			width: 32px;
			height: 32px;
			overflow: hidden;
			box-sizing: border-box;
			margin-top: 20px;
		}
		.text3 {
			margin-top: 8px;
			font-family: 'PingFang SC';
			font-size: 14px;
			white-space: nowrap;
			text-align: center;
			color: rgba(0,0,0,1);
			line-height: 26px;
			font-weight: 400;
		}
	}
}
</style>
